import { Badge, Tooltip, OverlayTrigger } from "react-bootstrap";
import React from "react";

import "./InfoBadge.scss";

const InfoBadge = ({ info, place = "bottom" }) => {
  return (
    <OverlayTrigger
      key="info-badge"
      placement={place}
      overlay={<Tooltip id={`tooltip-top`}>{info}</Tooltip>}
      style={{ marginTop: -200 }}
    >
      <Badge pill className="info-badge">
        ?
      </Badge>
    </OverlayTrigger>
  );
};
export default InfoBadge;
