import { Form } from "react-bootstrap";
import React from "react";

import "./SwitchComponent.scss";

const SwitchComponent = ({
  label,
  startLabel,
  endLabel,
  value,
  setValue,
  setSave,
}) => {
  return (
    <div className="switch">
      <p className="switch-label">{label}</p>
      <div className="switch-wrap">
        <span className="start-label">{startLabel}</span>
        <Form.Check
          type="switch"
          checked={value === "Dark"}
          onChange={(e) => {
            setValue(e.target.checked ? "Dark" : "Light");
            setSave(true);
          }}
        />
        <span className="end-label">{endLabel}</span>
      </div>
    </div>
  );
};

export default SwitchComponent;
