import axios from "axios";
import { Button, Form, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Userfront from "@userfront/react";
import { v4 as uuidv4 } from "uuid";

import CloseIcon from "../../assets/close.png";
import Select from "../Select/Select";

import "./AddWidgetModal.scss";

const AddWidgetModal = ({ toggleModal, show, widgets }) => {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [bot, setBot] = useState("");
  const [bots, setBots] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          const filteredAgents = response.data.filter(
            (agent) => agent.objective === "Support Guide (External)"
          );
          setBots(filteredAgents);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewWidget = async () => {
    setLoading(true);
    const widget_id = uuidv4();
    let data = {
      widget_title: name,
      bot_id: bot.bot_id,
      widget_id: widget_id,
      body_embed_code: `<div id='rightpage-widget' widget-id='${widget_id}'
      data-type="floating" data-start-state="expanded" data-font="Inter"
      data-font-url="Inter:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,500;0,900;1,100;1,500;1,900&display=swap"
      data-height="900px" data-position="right" data-offsetX="20px" data-offsetY="20px" data-input-bg=""
      data-question-panel-bg="" data-answer-panel-bg="#F7F8FA" data-answer-text-color="#000" data-suggested-questions="yes"
      data-input-placeholder="Question? Get an instant answer..." data-trigger-btn-color="#6649fc"
      data-trigger-btn-icon="message_light" data-info-text="This assistant uses AI to auto generate answers. There’s a chance it may not be fully correct."
      data-header-text="NewMouth Assistant" data-header-text-color="#F7F8FA" data-header-color="#6649fc" data-link-btn-color="#6649fc"
      data-link-text-color="#fff" data-placeholder-text-color="" data-input-text-color="" data-question-text-color="#000"
      data-footer-text='Instant answers auto generated by RightPage. Questions not contained in our public docs can result in suboptimal answers.'
      data-header-icon-color="light" data-input-icon-color="dark" data-only-links="yes" data-suggested-bg-color="#fff" data-suggested-text-color="#000" data-use-banner="yes" data-banner-text="Hey there! I instantly answer your support questions (via GPT-4)." data-custom-css="" data-avatar-url="" data-use-ticket="no"/>`,
      head_embed_code: `<link href='https://www.widget.rightpage.com/widget/index.css' rel='stylesheet' />
<script async src='https://www.widget.rightpage.com/widget/index.js'></script>`,
      settings: {
        widget_type: "Floating",
        start_state: "Expanded",
        font: "Inter",
        font_url:
          "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,500;0,900;1,100;1,500;1,900&display=swap",
        input_bg_color: "",
        message_panel_bg_color: "#fff",
        answer_bg_color: "#F7F8FA",
        answer_text_color: "#000",
        suggested_questions_on: true,
        input_placeholder_text: "Question? Get an instant answer...",
        custom_css_classes: "",
        position: "right",
        offset_x: "20px",
        offset_y: "20px",
        expanded_height: "900px",
        trigger_button_color: "#6649fc",
        button_icon: "Message Light",
        footer_text:
          'Instant answers auto generated by <a target="_blank" href="https://www.rightpage.com" rel="noreferrer">RightPage</a>.',
        info_text:
          "This assistant uses AI to auto generate answers. There’s a chance it may not be fully correct.",
        header_text: "Support Assistant",
        header_text_color: "#F7F8FA",
        header_color: "#6649fc",
        link_button_color: "#6649fc",
        link_text_color: "#fff",
        placeholder_text_color: "",
        input_text_color: "",
        question_text_color: "#000",
        header_icon_color: "light",
        input_icon_color: "Dark",
        suggested_bg_color: "#6649fc",
        suggested_text_color: "#fff",
        show_snippets: false,
        use_banner: "yes",
        banner_text: "Hello there! &#x1F44B; Have questions? I’m here to help.",
        avatar_url: "",
        use_ticket: "no",
      },
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_API_URL}/widgets/`,
      headers: {
        Authorization: `Bearer ${Userfront.tokens.accessToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const widgetId = response.data.widget_id;

        const currWidget = {
          widget_id: widgetId,
          widget_title: name,
        };
        toggleModal();
        widgets.push(currWidget);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {show ? (
        <div className="add-widget-modal" onClick={() => toggleModal()}>
          <div className="modal-widget" onClick={(e) => e.stopPropagation()}>
            <div className="widget-modal-head">
              <h6 className="widget-modal-heading">Create New Widget</h6>
              <img
                onClick={() => toggleModal()}
                src={CloseIcon}
                alt="close"
                className="widget-modal-close-icon"
              />
            </div>
            <div className="widget-modal-body">
              <Select
                label={"Select Bot to link to Widget"}
                value={bot}
                options={bots}
                setValue={setBot}
              />
              <div className="configuration-item">
                <p className="configuration-item-label">Widget Title</p>
                <Form.Control
                  className="form-input"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <Button
                disabled={!bot || name === ""}
                onClick={addNewWidget}
                className="create-widget-btn primary-button"
              >
                {loading ? <Spinner animation="border" /> : "Create New Widget"}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AddWidgetModal;
