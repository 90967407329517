import { Link } from "react-router-dom";
import UserFront from "@userfront/react";

import "./Login.scss";

UserFront.init("7n87xwyb");

const Login = UserFront.build({
  toolId: "mllnblr",
});

export default function LoginForm() {
  return (
    <div className="Centered-Form">
      <img
        src={require("../../assets/RightPage-Logo-Dark.png")}
        alt="RightPage Logo"
        className="center-logo"
      />
      <Login className="login-form" />
      <br />
      <Link to="/signup" className="create-account-link">
        Create an account
      </Link>
    </div>
  );
}
