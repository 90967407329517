import React, { useState, useEffect } from "react";
import { Form, FormControl, Button, FormCheck } from "react-bootstrap";
import InfoBadge from "../../InfoBadge/InfoBadge";
import Userfront from "@userfront/react";
import toast from "react-hot-toast";
import axios from "axios";

import "./TemplateSettings.scss";

function TemplateSettings({ bot, bases, handleUpdate }) {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [summaryTemplateShow, setSummaryTemplateShow] = useState(false);
  const [summaryTemplate, setSummaryTemplate] = useState("");
  const [summaryTitleField, setSummaryTitleField] = useState("");

  const handleSummaryTemplateClose = () => setSummaryTemplateShow(false);

  const handleSummaryTemplateChange = (e) => {
    setSummaryTemplate(e.target.value);
    setSummaryTemplateShow(true);
  };

  const [summary_template_id, setSummaryTemplateId] = useState(null);
  const [draft_template_id, setDraftTemplateId] = useState(null);
  const [draftTemplate, setDraftTemplate] = useState("");
  const [draftTitleField, setDraftTitleField] = useState("");
  const [draftTemplateShow, setDraftTemplateShow] = useState(false);

  const handleDraftTemplateChange = (e) => {
    setDraftTemplate(e.target.value);
    setDraftTemplateShow(true);
  };

  const handleDraftTemplateClose = () => setDraftTemplateShow(false);

  const handleSummaryTemplateSave = async () => {
    handleSummaryTemplateClose();
    // console.log("Saving summary template");
    if (summary_template_id) {
      updateTemplate(
        summary_template_id,
        summaryTemplate,
        summaryTitleField,
        "summary"
      );
    } else {
      newTemplate(summaryTemplate, summaryTitleField, "summary");
    }
  };

  const handleDraftTemplateSave = async () => {
    handleDraftTemplateClose();
    // console.log("Saving draft template");
    if (draft_template_id) {
      updateTemplate(
        draft_template_id,
        draftTemplate,
        draftTitleField,
        "draft"
      );
    } else {
      newTemplate(draftTemplate, draftTitleField, "draft");
    }
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/templates/${bot.bot_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          // console.log(response.data);
          const templates = response.data;
          if (templates.draft_templates.length > 0) {
            setDraftTemplate(templates.draft_templates[0].content);
            setDraftTitleField(templates.draft_templates[0].title);
            setDraftTemplateId(templates.draft_templates[0].id);
          }
          if (templates.summary_templates.length > 0) {
            setSummaryTemplate(templates.summary_templates[0].content);
            setSummaryTitleField(templates.summary_templates[0].title);
            setSummaryTemplateId(templates.summary_templates[0].id);
          }
        });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTemplate = async (template_id, content, title, type) => {
    await axios
      .put(
        BASE_API_URL + `/bots/templates/${bot.bot_id}/${template_id}`,
        {
          title: title,
          content: content,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Template saved successfully");
        } else {
          toast.error("Error saving template");
        }
      });
  };

  const newTemplate = async (content, title, type) => {
    await axios
      .post(
        BASE_API_URL + `/bots/templates/${bot.bot_id}`,
        {
          title: title,
          content: content,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Template saved successfully");
        } else {
          toast.error("Error saving template");
        }
      });
  };

  const toggleField = (name) => {
    handleUpdate(name, !bot[name]);
  };

  const handleOnChange = (base_id) => {
    // If the base is already active, then remove it from the active bases.
    // Otherwise add it to active bases
    const updatedBot = { ...bot };

    if (bot.macro_kbs.includes(base_id)) {
      updatedBot.macro_kbs = bot.macro_kbs.filter((item) => item !== base_id);
    } else {
      updatedBot.macro_kbs.push(base_id);
    }
    handleUpdate("macro_kbs", updatedBot.macro_kbs);
  };

  return (
    <div>
      <div className="template-form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Default Response Templates
          </Form.Label>
          <Form.Text className="section-explanation">
            RightPage uses a set of default response templates to answer user
            questions. You can customize these templates to fit your style and
            requirements. You can use free text or the following variables to
            customize your responses: {"{{include_links}}"},{" "}
            {"{{customer_name}}"}, {"{{agent_name}}"}
          </Form.Text>
        </div>
        <div className="section-two-col-row">
          <Form.Group controlId="summary_template" className="section-two-col">
            <div className="form-context">
              <Form.Label>Draft Response Template</Form.Label>
              <InfoBadge info="Specify the format and structure you want draft ticket responses to adhere to"></InfoBadge>
            </div>
            <FormControl
              as="textarea"
              value={draftTemplate}
              className="template-textarea"
              onChange={(e) => handleDraftTemplateChange(e)}
            />
            {draftTemplateShow ? (
              <Button
                className="open-button template-save-button"
                onClick={handleDraftTemplateSave}
              >
                Save Template
              </Button>
            ) : null}
          </Form.Group>
          <Form.Group controlId="summary" className="section-two-col">
            <div className="form-context">
              <Form.Label>Summary Template:</Form.Label>
              <InfoBadge info="Specify the format and structure you want tickets summarized into"></InfoBadge>
            </div>
            <FormControl
              as="textarea"
              value={summaryTemplate}
              className="template-textarea"
              onChange={(e) => handleSummaryTemplateChange(e)}
            />
            {summaryTemplateShow ? (
              <Button
                className="open-button template-save-button"
                onClick={handleSummaryTemplateSave}
              >
                Save Template
              </Button>
            ) : null}
          </Form.Group>
        </div>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Draft Response Settings
          </Form.Label>
          <Form.Text className="section-explantion">
            In addition, to the default response templates, you can customize
            the way RightPage drafts responses to user questions. You can
            specify whether you want to include links in the response, and
            whether you want to sign the response with the agent's name.
          </Form.Text>
        </div>
        <div className="section-two-col-row">
          <Form.Group controlId="sign_drafts" className="access-option">
            <div className="form-context">
              <FormCheck
                type="switch"
                className="access-switch"
                label="Sign Drafts w/Agent Name"
                checked={
                  bot.sign_drafts === "True" || bot.sign_drafts === true
                    ? true
                    : false
                }
                onChange={() => toggleField("sign_drafts")}
              />
              <InfoBadge info="Turn on if you would like the assistant to include a signature with the agent's name at the end of drafts." />
            </div>
          </Form.Group>
        </div>
        <div className="section-two-col-row">
          <Form.Group
            controlId="include_links_in_answer"
            className="access-option"
          >
            <div className="form-context">
              <FormCheck
                type="switch"
                className="access-switch"
                label="Include Relevant Links in Drafts"
                checked={
                  bot.include_links_in_answer === "True" ||
                  bot.include_links_in_answer === true
                    ? true
                    : false
                }
                onChange={() => toggleField("include_links_in_answer")}
              />
              <InfoBadge info="Turn on if you would like the assistant to include links to relevant help center articles in the body of the ticket response." />
            </div>
          </Form.Group>
        </div>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Macro-based Templates
          </Form.Label>
          <Form.Text className="section-explantion">
            RightPage can also use macros to draft responses to user questions.
            If enabled, RightPage will use the macro with the highest relevance
            to the user question to draft a response. This enables providing
            high quality drafts that are based on your existing macros but
            personalized to specifically address the user's question.
          </Form.Text>
        </div>
        <div className="form-context">
          <Form.Label>Knowledge Bases w/Macros:</Form.Label>
          <InfoBadge info="A template knowledge base will be scanned when a question is received and if a question is closely related to one of the items in a template knowledge base, that item will be used as a template for drafting a response. This option is ideal for knowledge bases full of Macros." />
        </div>
        <div className="sources-mb-3">
          {bases.map((base) => (
            <FormCheck key={`${base.base_id}`}>
              <FormCheck.Input
                type="checkbox"
                id={base.base_id}
                className="base-switch"
                checked={bot.macro_kbs.includes(base.base_id)}
                onChange={() => handleOnChange(base.base_id)}
              />
              <FormCheck.Label>{base.base_name}</FormCheck.Label>
            </FormCheck>
          ))}
        </div>

        <Form.Group className="macro-threshold-field">
          <div className="form-context">
            <Form.Label>Relevance Threshold to use Macro Template:</Form.Label>
            <InfoBadge info="Set the threshold for how closely a template should match a given question in order to over-ride the default answer template."></InfoBadge>
          </div>
          <Form.Control
            type="number"
            min="0"
            max="1"
            step="0.1"
            value={bot.macro_threshold}
            onChange={(e) => {
              const newThreshold = parseFloat(e.target.value);
              handleUpdate("macro_threshold", newThreshold);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid decimal between 0 and 1.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
}

export default TemplateSettings;
