import axios from "axios";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import Tooltip from "react-bootstrap/Tooltip";
import AgentTable from "../../components/AgentTable/AgentTable";
import "./SlackWorkspaces.scss";

import EmptyState from "../../components/EmptyState/EmptyState";

export default function SlackWorkspaces() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;

  const [loading, setLoading] = useState(true);
  const [slack, setSlack] = useState([]);
  const [slackLink, setSlackLink] = useState("");
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/slack/config`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setSlack(response.data);
          setSlackLink(
            `<a href="https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=app_mentions:read,chat:write,channels:read,groups:read&state=${Userfront.user.userUuid}&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>`
          );
          // console.log("slackLink", slackLink);
          // console.log(response.data);
        });
    })();
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setAgents(response.data);
          setLoading(false);
          // console.log(response.data);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="title-row">
            <h2>Slack Assist</h2>
            {/* <Link to="/collections/new"> */}
            <OverlayTrigger
              key="info-badge"
              placement="auto"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Click the button to install the RightPage Slack App in a Slack
                  Workspace
                </Tooltip>
              }
              style={{ marginTop: -200 }}
            >
              <div dangerouslySetInnerHTML={{ __html: slackLink }} />
            </OverlayTrigger>
            {/* <Button className="new-button" onClick={handleShow}>
              Create Knowledge Base
            </Button> */}
            {/* </Link> */}
          </div>
          <div className="slack-content">
            <div>
              <h3>Connected Slack Workspaces</h3>
              {slack.workspaces && slack.workspaces.length === 0 ? (
                <EmptyState type={"Connected Slack Workspaces"} />
              ) : (
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>Workspace Name</th>
                      <th>Workspace ID</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slack["workspaces"] &&
                      slack["workspaces"].map((workspace, index) => (
                        <tr key={workspace.team_name} className="base-row">
                          <td>{workspace.team_name}</td>
                          <td>{workspace.team_id}</td>
                          <td>
                            <div className="action-panel">
                              <Link
                                to={`/slack/workspace/${workspace.team_id}`}
                              >
                                <Button className="open-button">
                                  Configure
                                </Button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
          {/* {agents && agents.length > 0 ? ( */}
          <AgentTable
            agents={agents}
            objective={"Knowledge Discovery Assistant (Internal)"}
            title={"Slack Assist Bots"}
          />
          {/* ) : (
            <></>
          )} */}
        </div>
      )}
    </div>
  );
}
