import React, { useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import InfoBadge from "../../InfoBadge/InfoBadge";

import "./DetailedOptions.scss";

function DetailedOptions({ bot, handleUpdate }) {
  const [errors, setErrors] = useState({
    confidence_warning: false,
    confidence_cutoff: false,
    model: false,
    speed: false,
    categories: false,
  });

  const validate = (name, value) => {
    let errorMessage = "";

    if (name === "confidence_warning" || name === "confidence_cutoff") {
      if (value < 0 || value > 1) {
        errorMessage = "Should be a two digit decimal between 0 and 1";
      }
    }

    if (name === "model") {
      if (!value) {
        errorMessage = "Model cannot be blank";
      }
    }

    if (name === "speed") {
      if (!value) {
        errorMessage = "Speed cannot be blank";
      }
    }

    if (name === "categories" && !value.length) {
      errorMessage = "At least one category needed";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    return errorMessage === "";
  };

  const handleChange = (name, value) => {
    if (validate(name, value)) {
      handleUpdate(name, value);
    }
  };

  return (
    <Form>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Answer Confidence Settings
          </Form.Label>
          <Form.Text className="section-explantion">
            Before a bot responses to a request, a confidence score is
            calculated which estimates how accurate the bot is in its answer.
            The below settings allow for customizing the thresholds at which the
            bot will avoid responding.
          </Form.Text>
        </div>
        <div className="section-two-col-row">
          <Form.Group
            controlId="confidence_warning"
            className="section-two-col"
          >
            <div className="form-context">
              <Form.Label>Low Confidence Warning (Decimal):</Form.Label>
              <InfoBadge info="Enter a two digit decimal value representing the confidence threshold at which you want the agent to display a low confidence badge alongside an answer."></InfoBadge>
            </div>
            <FormControl
              type="number"
              placeholder="0.75"
              step="0.1"
              value={bot.confidence_warning}
              onChange={(e) =>
                handleChange("confidence_warning", parseFloat(e.target.value))
              }
              isInvalid={!!errors.confidence_warning}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confidence_warning}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="confidence_cutoff" className="section-two-col">
            <div className="form-context">
              <Form.Label>Confidence Cutoff (Decimal):</Form.Label>
              <InfoBadge info="Enter a two digit decimal value representing the confidence threshold below which you do not want the assistant to provide an answer."></InfoBadge>
            </div>
            <FormControl
              type="number"
              step="0.1"
              value={bot.confidence_cutoff}
              placeholder="0.6"
              onChange={(e) =>
                handleChange("confidence_cutoff", parseFloat(e.target.value))
              }
              isInvalid={!!errors.confidence_cutoff}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confidence_cutoff}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <Form.Group controlId="cutoff_message">
          <div className="form-context">
            <Form.Label>Confidence Cutoff Explanation:</Form.Label>
            <InfoBadge info="Write the response you would like the agent to respond with when its confidence in an answer is below the threshold."></InfoBadge>
          </div>
          <FormControl
            as="textarea"
            value={bot.cutoff_message}
            className="md-textarea"
            onChange={(e) => handleUpdate("cutoff_message", e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Text Generation Model Settings
          </Form.Label>
          <Form.Text className="section-explantion">
            After a bot has retrieved the most relevant information to answer a
            user's request, it will generate a response using a large language
            model. The below settings allow for customizing the model and the
            speed of the response.
          </Form.Text>
        </div>
        <div className="section-two-col-row">
          <Form.Group controlId="model" className="section-two-col">
            <div className="form-context">
              <Form.Label>Model:</Form.Label>
              <InfoBadge info="Select which underlying large language model you would like to generate responses for this agent."></InfoBadge>
            </div>
            <Form.Select
              value={bot.model}
              onChange={(e) => handleUpdate("model", e.target.value)}
            >
              <option key="1" value="gpt-3.5-turbo">
                gpt-3.5-turbo
              </option>
              <option key="2" value="gpt-4">
                gpt-4
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="model" className="section-two-col">
            <div className="form-context">
              <Form.Label>Max Response Length:</Form.Label>
              <InfoBadge info="Specify the maximum response length that can be generated."></InfoBadge>
            </div>
            <FormControl
              type="number"
              value={bot.answer_length}
              onChange={(e) => handleUpdate("answer_length", e.target.value)}
            />
          </Form.Group>
          {/* <Form.Group controlId="speed" className="section-two-col">
            <div className="form-context">
              <Form.Label>Answer Speed:</Form.Label>
              <InfoBadge info="Adjust how quickly your agent generates responses. A slower response will result in more robust answers, but may be too slow for some objectives."></InfoBadge>
            </div>
            <Form.Select
              value={bot.speed}
              onChange={(e) => handleUpdate("speed", e.target.value)}
            >
              <option key="1" value="Fast">
                Fastest
              </option>
              <option key="2" value="Medium">
                Fast
              </option>
              <option key="3" value="Slow">
                Moderate
              </option>
            </Form.Select>
          </Form.Group> */}
        </div>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Post-Interaction Classification
          </Form.Label>
          <Form.Text className="section-explantion">
            RightPage automatically classifies each interaction a user has with
            a bot into one of the categories you specify below. This allows you
            to track the performance of your bot and identify areas for
            improvement.
          </Form.Text>
        </div>
        <Form.Group controlId="categories">
          <div className="form-context">
            <Form.Label>Categories:</Form.Label>
            <InfoBadge info="List the topic categories that you want our models to classify user interactions into. Please separate each category with a comma"></InfoBadge>
          </div>
          <FormControl
            as="textarea"
            value={bot.categories.join(", ")}
            className="md-textarea"
            onChange={(e) =>
              handleUpdate(
                "categories",
                e.target.value.split(", ").map((item) => item.trim())
              )
            }
            isInvalid={!!errors.categories}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a list of categories you would like to have our models
            classify each user interaction into. Each category must be wrapped
            in quotes and separated by a comma.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Usage Reporting Tag
          </Form.Label>
          <Form.Text className="section-explantion">
            RightPage can automatically append a tag to every ticket that the
            bot is used on. This allows you to track the impact of your bot on
            your operations.
          </Form.Text>
        </div>
        <div className="section-two-col-row">
          <Form.Group controlId="categories" className="section-two-col">
            <div className="form-context">
              <Form.Label>Enable Usage Tagging:</Form.Label>
              <InfoBadge info="If turned on, RightPage will append a tag to each ticket this bot is used on."></InfoBadge>
            </div>
            <Form.Select
              value={bot.analytics && bot.analytics.use === true ? "yes" : "no"}
              onChange={(e) =>
                handleUpdate("analytics", {
                  use: e.target.value === "yes",
                  tag: bot.analytics.tag,
                })
              }
            >
              <option key="1" value="yes">
                Yes
              </option>
              <option key="2" value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="section-two-col">
            <div className="form-context">
              <Form.Label>Analytics Tag Text:</Form.Label>
              <InfoBadge info="If the usage reporting feature is turned on, you can specify the contents of the tag that will be appended here."></InfoBadge>
            </div>
            <Form.Control
              type="text"
              value={
                bot.analytics && bot.analytics.tag
                  ? bot.analytics.tag
                  : "RightPage"
              }
              onChange={(e) =>
                handleUpdate("analytics", {
                  use: bot.analytics.use,
                  tag: e.target.value,
                })
              }
            />
          </Form.Group>
        </div>
      </div>
    </Form>
  );
}

export default DetailedOptions;
