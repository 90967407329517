import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Userfront from "@userfront/react";

export default function DeleteModal({ bot_id, show, onHide }) {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const handleDelete = async () => {
    await axios
      .delete(BASE_API_URL + `/bots/${bot_id}`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      });
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to permanently delete this agent?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="secondary-button" onClick={onHide}>
          Close
        </Button>
        <Button className="primary-button" onClick={handleDelete}>
          Delete Agent
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
