import React from "react";

import "./MobileOverlay.scss";

const SmallScreenOverlay = () => {
  return (
    <div className="small-screen-overlay">
      <h2>The RightPage console is not optimized for small screens.</h2>
      <p>Please enlarge your window or switch devices.</p>
    </div>
  );
};

export default SmallScreenOverlay;
