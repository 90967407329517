import React from "react";
import { Form } from "react-bootstrap";
import moment from "moment-timezone";

const TimeZoneSelector = ({ selectedTimeZone, handleTimeZoneChange }) => {
  const timeZones = moment.tz.names();

  return (
    <Form.Select
      aria-label="Time Zone"
      onChange={handleTimeZoneChange}
      value={selectedTimeZone}
    >
      {timeZones.map((zone, index) => (
        <option key={index} value={zone}>
          {zone}
        </option>
      ))}
    </Form.Select>
  );
};
export default TimeZoneSelector;
