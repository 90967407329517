import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import Agents from "./pages/Agents/Agents";
import AssistAgents from "./pages/AssistAgents/AssistAgents";
import AgentChat from "./pages/AgentChat/AgentChat";
import Articles from "./pages/Articles/Articles";
import Cache from "./pages/Cache/Cache";
import CacheVariant from "./pages/CacheVariant/CacheVariant";
import Conversations from "./pages/Conversations/Conversations";
import Crawl from "./pages/Crawl/Crawl";
import DocDetail from "./pages/DocDetail/DocDetail";
import DocPage from "./pages/DocPage/DocPage";
import FAQ from "./pages/FAQ/FAQ";
import Integrations from "./pages/Integrations/Integrations";
import KnowledgeBase from "./pages/KnowledgeBase/KnowledgeBase";
import KnowledgeBases from "./pages/KnowledgeBases/KnowledgeBases";
import Insights from "./pages/Insights/Insights";
import Login from "./pages/Login/Login";
import Macros from "./pages/Macros/Macros";
import NewCrawl from "./pages/NewCrawl/NewCrawl";
import NewDoc from "./pages/NewDoc/NewDoc";
import NotionImport from "./pages/NotionImport/NotionImport";
import PasswordResetForm from "./pages/PasswordReset/PasswordReset";
// import Pricing from "./pages/Pricing/Pricing";
import Relevance from "./pages/Relevance/Relevance";
import RoutePage from "./pages/Route/Route";
import SignUp from "./pages/SignUp/SignUp";
import SlackMessages from "./pages/SlackMessages/SlackMessages";
import SlackWorkspace from "./pages/SlackWorkspace/SlackWorkspace";
import SlackWorkspaces from "./pages/SlackWorkspaces/SlackWorkspaces";
import Tags from "./pages/Tags/Tags";
import Tag from "./pages/Tag/Tag";
import Tickets from "./pages/Tickets/Tickets";
import Userfront from "@userfront/react";
import UserProfile from "./pages/UserProfile/UserProfile";
import WidgetConfiguration from "./pages/WidgetConfiguration/WidgetConfiguration";
import YouTubeImport from "./pages/YouTubeImport/YouTubeImport";
import SidebarMenu from "./components/Sidebar/Sidebar";
import BotSettings from "./components/BotSettings/BotSettings";
import SmallScreenOverlay from "./components/MobileOverlay/MobileOverlay";
import ConfluenceImport from "./pages/ConfluenceImport/ConfluenceImport";
import NotionSync from "./pages/NotionSync/NotionSync";
function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    // Return function to clean up listener on component unmount
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <BrowserRouter>
      {windowWidth <= 900 && <SmallScreenOverlay />}
      <SidebarMenu />
      <div className="main-content">
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<PasswordResetForm />} />
          <Route path="/faq" element={<FAQ />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/crawls/new" element={<NewCrawl />} />
          <Route
            path="/insights/:agent_id"
            element={
              <RequireAuth>
                <Insights />
              </RequireAuth>
            }
          />

          <Route
            path="/insights"
            element={
              <RequireAuth>
                <Insights />
              </RequireAuth>
            }
          />
          <Route
            path="/confluence/new"
            element={
              <RequireAuth>
                <ConfluenceImport />
              </RequireAuth>
            }
          />
          <Route
            path="/notion/sync"
            element={
              <RequireAuth>
                <NotionSync />
              </RequireAuth>
            }
          />
          <Route
            path="/bases/doc/new"
            element={
              <RequireAuth>
                <NewDoc />
              </RequireAuth>
            }
          />
          <Route
            path="/bases/:base_id"
            element={
              <RequireAuth>
                <KnowledgeBase />
              </RequireAuth>
            }
          />
          <Route
            path="/bases"
            element={
              <RequireAuth>
                <KnowledgeBases />
              </RequireAuth>
            }
          />
          <Route
            path="/tags/view/:tag_id"
            element={
              <RequireAuth>
                <Tag />
              </RequireAuth>
            }
          />
          <Route
            path="/tags/"
            element={
              <RequireAuth>
                <Tags />
              </RequireAuth>
            }
          />
          <Route
            path="/crawls/:crawl_id"
            element={
              <RequireAuth>
                <Crawl />
              </RequireAuth>
            }
          />
          <Route
            path="agents/relevance/:bot_id"
            element={
              <RequireAuth>
                <Relevance />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/cache/:bot_id"
            element={
              <RequireAuth>
                <Cache />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/cache/:bot_id/item/:cache_id"
            element={
              <RequireAuth>
                <CacheVariant />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/chat/:bot_id"
            element={
              <RequireAuth>
                <AgentChat />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/:bot_id"
            element={
              <RequireAuth>
                <BotSettings />
              </RequireAuth>
            }
          />
          <Route
            path="bots/:bot_id"
            element={
              <RequireAuth>
                <BotSettings />
              </RequireAuth>
            }
          />
          <Route
            path="/assist"
            element={
              <RequireAuth>
                <AssistAgents />
              </RequireAuth>
            }
          />
          <Route
            path="/route/:route_id"
            element={
              <RequireAuth>
                <RoutePage />
              </RequireAuth>
            }
          />
          <Route
            path="/webwidgets"
            element={
              <RequireAuth>
                <Agents />
              </RequireAuth>
            }
          />
          <Route
            path="/conversations"
            element={
              <RequireAuth>
                <Conversations />
              </RequireAuth>
            }
          />
          <Route
            path="/conversations/:agent_id"
            element={
              <RequireAuth>
                <Conversations />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <UserProfile />
              </RequireAuth>
            }
          />
          <Route
            path="doc/view/:doc_id"
            element={
              <RequireAuth>
                <DocPage />
              </RequireAuth>
            }
          />
          <Route
            path="/slack/workspace/:team_id"
            element={
              <RequireAuth>
                <SlackWorkspace />
              </RequireAuth>
            }
          />
          <Route
            path="/slack/import"
            element={
              <RequireAuth>
                <SlackMessages />
              </RequireAuth>
            }
          />
          <Route
            path="/slack"
            element={
              <RequireAuth>
                <SlackWorkspaces />
              </RequireAuth>
            }
          />
          <Route
            path="/notion/import"
            element={
              <RequireAuth>
                <NotionImport />
              </RequireAuth>
            }
          />
          <Route
            path="/macros/import"
            element={
              <RequireAuth>
                <Macros />
              </RequireAuth>
            }
          />
          <Route
            path="/youtube/import"
            element={
              <RequireAuth>
                <YouTubeImport />
              </RequireAuth>
            }
          />
          <Route
            path="/doc/:doc_id"
            element={
              <RequireAuth>
                <DocDetail />
              </RequireAuth>
            }
          />
          <Route
            path="widget/:widget_id"
            element={
              <RequireAuth>
                <WidgetConfiguration />
              </RequireAuth>
            }
          />
          <Route
            path="widget"
            element={
              <RequireAuth>
                <WidgetConfiguration />
              </RequireAuth>
            }
          />
          <Route
            path="integrations"
            element={
              <RequireAuth>
                <Integrations />
              </RequireAuth>
            }
          />
          <Route
            path="/tickets/new"
            element={
              <RequireAuth>
                <Tickets />
              </RequireAuth>
            }
          />
          <Route
            path="/articles/new"
            element={
              <RequireAuth>
                <Articles />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Navigate to="/bases" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  let location = useLocation();
  if (!Userfront.tokens.accessToken) {
    // Redirect to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
