import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import EmptyState from "../../components/EmptyState/EmptyState";
import toast from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import SaveBar from "../../components/SaveBar/SaveBar";

import "./Route.scss";

export default function RoutePage() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const { route_id } = useParams();
  const [route, setRoute] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [bots, setBots] = useState([]);
  const [showModal, setShowModal] = useState(false);
  //   const [botIdToAgentName, setBotIdToAgentName] = useState(new Map());
  const [currentRule, setCurrentRule] = useState({
    type: "Brand",
    field: "Brand",
    value: "",
  });
  const [ruleType, setRuleType] = useState("Brand");
  const [ruleInfoLoaded, setRuleInfoLoaded] = useState(true);
  const [brands, setBrands] = useState([]);
  //   const [brand, setBrand] = useState();
  const [standardFields, setStandardFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [locales, setLocales] = useState(["en-us"]);
  //   const [locale, setLocale] = useState("en-us");

  const rule_types = ["Brand", "Language", "Standard Field", "Custom Field"];
  useEffect(() => {
    let route_type = "";
    (async () => {
      await axios
        .get(BASE_API_URL + `/integrations/routes/${route_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          let tempRoute = response.data;
          console.log("route", tempRoute);
          let tempRules =
            Object.keys(tempRoute.rules).length === 0
              ? {}
              : JSON.parse(tempRoute.rules);
          tempRoute.rules = tempRules;
          setRoute(tempRoute);
          route_type = response.data.route_type;
        });
      console.log("route type", route_type);
      if (route_type === "agent_assist") {
        await axios
          .get(BASE_API_URL + `/bots/`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            let filterType = "Support Representative (Internal)";
            if (route_type === "web_widget") {
              filterType = "Support Guide (External)";
            }
            const filteredAgents = response.data.filter(
              (agent) => agent.objective === filterType
            );
            setBots(filteredAgents);
            //   setBots(response.data);
            let temp = new Map();
            filteredAgents.forEach((bot) =>
              temp.set(bot.bot_id, bot.agent_name)
            );
            // setBotIdToAgentName(temp);
            setLoading(false);
            // Set default api key to first bot when bots is available
          });
      } else if (route_type === "web_widget") {
        await axios
          .get(BASE_API_URL + `/widgets/`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            let tempWidgets = response.data;
            tempWidgets.forEach((widget) => {
              widget.agent_name = widget.widget_title;
              widget.bot_id = widget.widget_id;
            });
            setBots(tempWidgets);

            let temp = new Map();
            response.data.forEach((bot) =>
              temp.set(bot.widget_id, bot.widget_title)
            );
            console.log(temp);
            // setBotIdToAgentName(temp);
            setLoading(false);
          });
      }
      await axios
        .get(BASE_API_URL + `/integrations/integrations_zendesk/brands`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          const temp_brands = response.data;
          // console.log("brands:", temp_brands);
          if (temp_brands.error) {
            toast.error("An error occurred while fetching brands.");
            toast.error(temp_brands.error);
            setBrands([]);
            // setBrand({ brand: "none" });
            setLoading(false);
          } else {
            setBrands(temp_brands.brands);
            // setBrand(temp_brands.brands[0]);
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRuleInfoLoaded(false);
    if (ruleType === "Brand") {
      console.log("Fetch Brand info");
    } else if (ruleType === "Language") {
      console.log("Fetch Language info");
      (async () => {
        await axios
          .get(
            BASE_API_URL + `/integrations/integrations_zendesk/article/locales`,
            {
              headers: {
                authorization: `Bearer ${Userfront.tokens.accessToken}`,
              },
            }
          )
          .then((response) => {
            let locale_obj = response.data;
            const index = locale_obj.locales.indexOf(locale_obj.default_locale);
            if (index > -1) {
              locale_obj.locales.splice(index, 1);
              locale_obj.locales.unshift(locale_obj.default_locale);
            }
            setLocales(locale_obj.locales);
            setLoading(false);
          });
      })();
    } else if (ruleType === "Custom Field" || ruleType === "Standard Field") {
      console.log("Fetch Custom Field info");
      (async () => {
        await axios
          .get(BASE_API_URL + `/integrations/integrations_zendesk/fields`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            let ticket_fields = response.data;
            let custom_fields = [];
            let standard_fields = [];
            ticket_fields.forEach((field) => {
              if (field.removable) {
                custom_fields.push(field);
              } else {
                standard_fields.push(field);
              }
            });
            setStandardFields(standard_fields);
            setCustomFields(custom_fields);
            console.log(custom_fields);
            setLoading(false);
          });
      })();
    }
    setRuleInfoLoaded(true);
  }, [ruleType, BASE_API_URL]);

  const handleDiscard = () => {
    window.location.reload();
  };

  const handleUpdate = async () => {
    let data = new FormData();
    data.append("title", route.title);
    data.append("identifier", route.identifier);
    data.append("bot_id", route.bot_id);
    data.append("route_type", route.route_type);
    data.append("rules", JSON.stringify(route.rules));
    axios
      .put(BASE_API_URL + `/integrations/routes/${route["route_id"]}`, data, {
        headers: {
          authorization: `Bearer ${Userfront.tokens.accessToken}`,
        },
      })
      .then((response) => {
        // window.location.reload();
        setShowSaveBar(false);
      });
    console.log("update");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTypeChange = (type) => {
    setCurrentRule({ ...currentRule, type: type });
    setRuleType(type);
  };

  const handleChange = (e) => {
    setRoute({ ...route, [e.target.name]: e.target.value });
  };

  const handleTitleChange = (event) => {
    let newRoute = { ...route, title: event.target.value };
    setRoute(newRoute);
    setShowSaveBar(true);
  };

  const handleDeleteRule = (key) => {
    let newRoute = { ...route };
    delete newRoute.rules[key];
    setRoute(newRoute);
    setShowSaveBar(true);
  };

  const handleShowModal = (key) => {
    console.log("show modal");
    setShowModal(true);
  };

  const handleUpdateRoute = () => {
    console.log("update route");
    console.log(currentRule);
    let tempRules = route.rules;
    tempRules[currentRule.type] = currentRule;
    setRoute({ ...route, rules: tempRules });
    console.log(route);
    setShowModal(false);
    setShowSaveBar(true);
  };

  const handleBrandChange = (index) => {
    // setBrand(brands[index]);
    setCurrentRule({ ...currentRule, value: brands[index].subdomain });
  };

  const handleLanguageChange = (value) => {
    // setLocale(value);
    console.log("locale", value);
    setCurrentRule({ ...currentRule, value: value });
  };

  const handleStandardFieldChange = (value) => {
    setCurrentRule({ ...currentRule, field: value });
  };

  const handleStandardFieldValueChange = (value) => {
    setCurrentRule({ ...currentRule, value: value });
  };

  const handleCustomFieldChange = (e) => {
    const { options, selectedIndex, value } = e.target;

    if (options && options[selectedIndex]) {
      const fieldTitle = options[selectedIndex].getAttribute("data-title");

      setCurrentRule({
        ...currentRule,
        field: fieldTitle,
        fieldId: value,
      });
    } else {
      console.error("Invalid option selected");
    }
  };

  const renderFormSelect = () => {
    switch (ruleType) {
      case "Brand":
        return (
          <Form.Group>
            <Form.Label>Brand</Form.Label>
            <Form.Select
              type="text"
              placeholder="Enter title"
              name="title"
              value={currentRule.value}
              onChange={(e) => handleBrandChange(e.target.value)}
            >
              {brands.map((brand, index) => (
                <option key={index} value={index}>
                  {brand.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        );
      case "Language":
        return (
          <Form.Group>
            {locales && locales.length > 0 ? (
              <>
                <Form.Label>Active Languages in Zendesk Instance</Form.Label>

                <Form.Select
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={currentRule.value}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                >
                  {locales.map((locale, index) => (
                    <option key={index} value={locale}>
                      {locale}
                    </option>
                  ))}
                </Form.Select>
              </>
            ) : null}
            <Form.Label>Locale Code</Form.Label>
            <Form.Control
              type="input"
              value={currentRule.value}
              onChange={(e) => handleLanguageChange(e.target.value)}
            />
          </Form.Group>
        );
      case "Standard Field":
        return (
          <Form.Group>
            {standardFields && standardFields.length > 0 ? (
              <>
                <Form.Label>Available Standard Ticket Fields</Form.Label>

                <Form.Select
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={currentRule.field}
                  onChange={(e) => handleStandardFieldChange(e.target.value)}
                >
                  {standardFields.map((field, index) => (
                    <option key={index} value={field.raw_title}>
                      {field.title}
                    </option>
                  ))}
                </Form.Select>
              </>
            ) : null}
            <Form.Label>Field Value</Form.Label>
            <Form.Control
              type="input"
              value={currentRule.value}
              onChange={(e) => handleStandardFieldValueChange(e.target.value)}
            />
          </Form.Group>
        );
      case "Custom Field":
        return (
          <Form.Group>
            {customFields && customFields.length > 0 ? (
              <>
                <Form.Label>Available Custom Ticket Fields</Form.Label>

                <Form.Select
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={currentRule.field}
                  onChange={(e) => handleCustomFieldChange(e)}
                >
                  {customFields.map((field, index) => (
                    <option
                      key={index}
                      value={field.id}
                      data-title={field.title}
                    >
                      {field.title}
                    </option>
                  ))}
                </Form.Select>
              </>
            ) : null}
            <Form.Label>Field Value</Form.Label>
            <Form.Control
              type="input"
              value={currentRule.value}
              onChange={(e) => handleStandardFieldValueChange(e.target.value)}
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <div className="cache">
      {showSaveBar ? (
        <SaveBar onDiscard={handleDiscard} onSave={handleUpdate} />
      ) : null}
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div className="relevance-view">
          <Breadcrumb>
            <Breadcrumb.Item href="/assist">All Routes</Breadcrumb.Item>
          </Breadcrumb>
          <div className="title-row">
            <h2>Route Settings</h2>
          </div>
          <div className="tag-fields-container">
            <div className="tags-left-column">
              <div className="tag-item-fields">
                <div>
                  <div className="form-context">
                    <h5>Route Title</h5>
                    <InfoBadge info="A short title that summarizes this route (e.g., French Route)."></InfoBadge>
                  </div>
                  <Form.Control
                    type="input"
                    value={route.title}
                    onChange={handleTitleChange}
                  />
                </div>
              </div>
            </div>
            <div className="tags-left-column">
              <div>
                <div className="form-context">
                  <h5>
                    {route.route_type === "agent_assist"
                      ? "Route to Bot"
                      : "Route to Web Widget"}{" "}
                  </h5>
                  <InfoBadge info="Select the bot or web widget that should handle inquiries that match this route's unique identifier."></InfoBadge>
                </div>
                <Form.Group
                  controlId="formRouteBotId"
                  className="route-form-field"
                >
                  <Form.Select
                    defaultValue={route.bot_id}
                    onChange={handleChange}
                    name="bot_id"
                  >
                    {bots.map((bot) => (
                      <option key={bot.bot_id} value={bot.bot_id}>
                        {bot.agent_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
          <div>
            <div className="route-title-row">
              <h5>Rules</h5>
              <Button className="primary-button" onClick={handleShowModal}>
                Add Rule
              </Button>
            </div>
            {route && Object.keys(route.rules) !== 0 ? (
              <>
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Field</th>
                      <th>Value</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(route.rules).map((key, index) => (
                      <tr key={index}>
                        {console.log("key", key)}
                        <td>{route.rules[key].type}</td>
                        <td>{route.rules[key].field}</td>
                        <td>{route.rules[key].value}</td>
                        <td>
                          <div className="action-panel">
                            <Button
                              className="open-button"
                              onClick={() => handleDeleteRule(key)}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <EmptyState type="rules" onClick={() => handleShowModal(null)} />
            )}
          </div>
          <Modal show={showModal} centered onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Rule Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                controlId="formRouteTitle"
                className="route-form-field"
              >
                <Form.Label>Rule Type</Form.Label>
                <InfoBadge info="Select the type of rule to be created." />
                <Form.Select
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={currentRule.type}
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  {rule_types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {console.log("rule type", ruleType)}
              {ruleInfoLoaded ? renderFormSelect() : null}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleUpdateRoute}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}
