import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Column from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { OverlayTrigger } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import toast, { Toaster } from "react-hot-toast";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import RefreshIconLight from "../../assets/ActionIcons/RefreshIconLight.png";
import "./SlackWorkspace.scss";

export default function SlackWorkspaces() {
  let { team_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [activeName, setActiveName] = useState("");
  const [agent, setAgent] = useState("");
  const [bots, setBots] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [workspace, setWorkspace] = useState([]);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/slack/config/${team_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const sorted_data = response.data.conversations.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setWorkspace(response.data);
          setConversations(sorted_data);
          // console.log(response.data);
          setLoading(false);
        });
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const filteredAgents = response.data.filter(
            (agent) =>
              agent.objective === "Knowledge Discovery Assistant (Internal)"
          );
          setBots(filteredAgents);
          if (response.data.length > 0) setAgent(response.data[0].bot_id);
          // console.log(response.data);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => setShow(false);

  const onSelectChange = (choice) => {
    // console.log(choice.target.value);
    setAgent(choice.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      conversation_id: conversationId,
      bot_id: agent,
    };
    await axios
      .put(BASE_API_URL + `/slack/config/${team_id}`, data, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        window.location.reload();
      });
  };

  const handleSetShow = (conversation_id, conversation_name) => {
    setConversationId(conversation_id);
    setActiveName(conversation_name);
    setShow(true);
  };

  const getBotName = (bot_id) => {
    let bot_name = "";
    bots.forEach((bot) => {
      if (bot.bot_id === bot_id) {
        bot_name = bot.agent_name;
      }
    });
    return bot_name;
  };

  const handleRefresh = async () => {
    await axios
      .get(BASE_API_URL + `/slack/refresh/${team_id}`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        toast.success(
          "Successfully triggered channel list refresh; may take a minute to fully populate"
        );
        window.location.reload();
      });
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/slack">Slack Workspaces</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {workspace.team_name}'s Workspace
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="title-row">
            <h2>{workspace.team_name}'s Workspace</h2>
            <OverlayTrigger
              key="info-badge"
              placement="auto"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Click the button to refresh the list of channels in this
                  workspace
                </Tooltip>
              }
              style={{ marginTop: -200 }}
            >
              <Button className="primary-button" onClick={handleRefresh}>
                <img src={RefreshIconLight} alt="Refresh Icon" />
                <span>Refresh Channel List</span>
              </Button>
            </OverlayTrigger>
          </div>
          <div className="workspace-content">
            {workspace["conversations"].length === 0 ? (
              <p className="no-collections">
                The RightPage app has not been added to any channels in this
                worksapce.
              </p>
            ) : (
              <Container>
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>
                        <Row>
                          <Column md={1}>Name</Column>
                          <Column md={4}>Purpose</Column>
                          <Column md={2}>Number of Members</Column>
                          <Column md={1}>Channel Type</Column>
                          <Column md={2}>Current Agent</Column>
                          <Column md={2}>Set Agent</Column>
                        </Row>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {conversations.map((conversation, index) => (
                      <tr
                        key={conversation.conversation_id}
                        className="base-row"
                      >
                        <td>
                          <Row>
                            <Column md={1}>{conversation.name}</Column>
                            <Column md={4}>{conversation.purpose}</Column>
                            <Column md={2}>{conversation.num_members}</Column>
                            <Column md={1}>
                              {conversation.is_private ? "Private" : "Public"}
                            </Column>
                            <Column md={2}>
                              {conversation.bot_id
                                ? getBotName(conversation.bot_id)
                                : "None"}
                            </Column>
                            <Column md={2}>
                              <div className="action-panel">
                                <Button
                                  className="secondary-button"
                                  onClick={() =>
                                    handleSetShow(
                                      conversation.conversation_id,
                                      conversation.name
                                    )
                                  }
                                >
                                  Set Agent
                                </Button>
                              </div>
                            </Column>
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
            )}
          </div>
        </div>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Set Agent for {activeName} channel</Form.Label>
                <InfoBadge info="The agent you specify will respond to any @RightPage mentions from this channel."></InfoBadge>
              </div>
              <Form.Select
                size="md"
                label="Agent"
                name="privacy"
                onChange={(choice) => onSelectChange(choice)}
              >
                {bots.map((bot) => (
                  <option key={bot.bot_id} value={bot.bot_id}>
                    {bot.agent_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleClose}>
            Close
          </Button>
          <Button className="primary-button" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
    </div>
  );
}
