import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import formatTimestamp from "../../services/TimeService";
import Button from "react-bootstrap/Button";
import DeleteAgentModal from "./DeleteAgentModal/DeleteAgentModal";
import { useState } from "react";
import { CreateAgentModal } from "./CreateAgentModal/CreateAgentModal";

import EmptyState from "../EmptyState/EmptyState";
import AddIconLight from "../../assets/ActionIcons/AddIconLight.png";
import "./AgentTable.scss";

const AgentTable = ({ agents, objective, title }) => {
  const filteredAgents = agents.filter(
    (agent) => agent.objective === objective
  );

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const [showCreateAgent, setShowCreateAgent] = useState(false);
  const showDeleteModal = (id) => {
    setDeleteId(id);
    handleDeleteShow(true);
  };

  const handleCreateClose = () => setShowCreateAgent(false);
  const handleCreateShow = () => setShowCreateAgent(true);
  return (
    <>
      <div className="agent-table-title">
        <h3>{title}</h3>
        <Button onClick={handleCreateShow} className="primary-button">
          <img src={AddIconLight} alt="add icon" />
          <span>Create Bot</span>
        </Button>
      </div>
      {filteredAgents.length === 0 ? (
        <EmptyState type={title} onClick={handleCreateShow} />
      ) : (
        <Table hover striped bordered size="lg" className="base-table">
          <thead>
            <tr>
              <th>Name</th>
              {objective === "Support Guide (External)" ? null : (
                <th>Created</th>
              )}
              <th>Last Modified</th>
              {objective === "Support Guide (External)" ? (
                <th>Analytics</th>
              ) : null}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAgents.map((agent, index) => (
              <tr key={agent.bot_id} className="base-row">
                <td>{agent.agent_name}</td>
                {objective === "Support Guide (External)" ? null : (
                  <td>{formatTimestamp(agent.created)}</td>
                )}
                <td>{formatTimestamp(agent.last_modified)}</td>
                {objective === "Support Guide (External)" ? (
                  <td>
                    <div className="action-panel">
                      <Link to={`/conversations/${agent.bot_id}`}>
                        <Button className="open-button settings-button">
                          Interactions
                        </Button>
                      </Link>
                      <Link to={`/insights/${agent.bot_id}`}>
                        <Button className="open-button settings-button">
                          Insights
                        </Button>
                      </Link>
                    </div>
                  </td>
                ) : null}
                <td>
                  <div className="action-panel">
                    <Link to={`/agents/${agent.bot_id}`}>
                      <Button className="open-button settings-button">
                        Settings
                      </Button>
                    </Link>
                    <Button
                      className="open-button"
                      onClick={() => showDeleteModal(agent.bot_id)}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <CreateAgentModal
        show={showCreateAgent}
        onHide={handleCreateClose}
        objective={objective}
      />
      <DeleteAgentModal
        bot_id={deleteId}
        show={deleteShow}
        onHide={handleDeleteClose}
      />
    </>
  );
};

export default AgentTable;
