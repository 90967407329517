import React from "react";
import { Button } from "react-bootstrap";
import "./EmptyState.scss";

import EmptyDocumentsIcon from "../../assets/ActionIcons/EmptyDocumentsIcon.png";

const EmptyState = ({ Icon, type, onClick }) => (
  <div className="empty-state-container">
    <div className="icon-container">
      <img src={Icon ? Icon : EmptyDocumentsIcon} alt={type} />
    </div>
    <h2 className="headline">No {type} Yet</h2>
    <p className="explanatory-text">You haven't created any {type} yet.</p>
    {onClick ? (
      <Button onClick={onClick} className="empty-open-button open-button">
        Create {type}
      </Button>
    ) : null}
  </div>
);

export default EmptyState;
