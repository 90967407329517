import axios from "axios";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useEffect, useState } from "react";
import { useInterval } from "../../../hooks/useInterval";
import { useNavigate } from "react-router-dom";
import Userfront from "@userfront/react";

import "./DocCard.scss";
import DeleteIcon from "../../../assets/ActionIcons/DeleteIcon.png";
import { formatString } from "../../../services/FormatService.js";
export default function DocCard({ doc }) {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const pollInterval = 1500;

  const [progressNum, setProgressNum] = useState(20);

  useEffect(() => {
    if (doc.status === "Extracting Text") {
      setProgressNum(40);
    } else if (doc.status === "Analyzing Text") {
      setProgressNum(60);
    } else if (doc.status === "Converting to HTML") {
      setProgressNum(80);
    } else if (doc.status === "Available") {
      setProgressNum(100);
    } else {
      setProgressNum(-20);
    }
    // console.log(progressNum);
  }, [doc, progressNum]);

  useInterval(async () => {
    if (doc.status !== "Available" && doc.status !== "Failed") {
      // console.log("Polling...");
      await axios
        .get(BASE_API_URL + `/documents/doc/status/${doc.doc_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const new_status = response.data.trim();
          if (new_status === "Extracting Text") {
            setProgressNum(40);
          } else if (new_status === "Analyzing Text") {
            setProgressNum(60);
          } else if (new_status === "Converting to HTML") {
            setProgressNum(80);
          } else if (new_status === "Available") {
            setProgressNum(100);
          } else {
            setProgressNum(-20);
          }
          doc.status = new_status;
          // console.log("New Status: ", response.data);
        });
    }
  }, pollInterval);

  const onClick = () => {
    navigate(`/doc/${doc.doc_id}`);
  };

  const handleDelete = async () => {
    await axios({
      method: "delete",
      url: BASE_API_URL + `/documents/doc/${doc.doc_id}`,
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
    }).then((response) => {
      // console.log(response);
      window.location.reload();
    });
  };

  return (
    <tr className="doc-table-row">
      <td className="row-text doc-title">{doc.title}</td>
      <td className="row-text">{formatString(doc.type)}</td>
      <td className="row-text">{doc.status}</td>
      <td className="action-button-panel">
        {doc.status === "Available" ? (
          <Button className="open-button" onClick={onClick}>
            {"Open"}
          </Button>
        ) : (
          <></>
        )}
        &nbsp;
        <OverlayTrigger
          key="delete-top"
          placement="top"
          overlay={<Tooltip>Delete Document</Tooltip>}
        >
          <img
            alt="Delete icon"
            className="delete-icon"
            src={DeleteIcon}
            onClick={handleDelete}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
}
