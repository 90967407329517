import axios from "axios";
import Button from "react-bootstrap/Button";
// import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Userfront from "@userfront/react";
import { useState, useEffect } from "react";
// import Table from "react-bootstrap/Table";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
// import LogOutButton from "../../components/LogOutButton/LogOutButton";

import "./UserProfile.scss";

export default function UserProfile() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  // const [limits, setLimits] = useState({});
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState("");
  // const [userUsage, setUserUsage] = useState({});

  useEffect(() => {
    setUserData(Userfront.user);
    // if (userData !== "" && BASE_API_URL === "http://127.0.0.1:8000") {
    //   console.log(Userfront.tokens.accessToken);
    // }
    if (userData !== "") {
      console.log(Userfront.tokens.accessToken);
    }
    (async () => {
      const response = await axios.get(BASE_API_URL + "/users/profile", {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      });
      setUser(response.data.user);
      // setUserUsage(response.data.counts);
      // setLimits(response.data.limits);
      setLoading(false);
    })();
  }, [userData, BASE_API_URL]);

  const onResetClick = async () => {
    const payload = {
      email: userData.email,
      tenantId: userData.tenantId,
      options: {},
    };
    const options = {
      headers: {
        Accept: "*/*",
      },
    };
    axios
      .post("https://api.userfront.com/v0/auth/reset/link", payload, options)
      .then(() => toast.success("Reset password email sent!"))
      .catch((err) => console.error(err));
  };

  const onVerifyResendClick = async () => {
    const payload = {
      email: userData.email,
      userId: userData.userId,
      userUuid: userData.userUuid,
      tenantId: userData.tenantId,
      options: {},
    };
    const options = {
      headers: {
        Accept: "*/*",
      },
    };
    axios
      .post("https://api.userfront.com/v0/auth/verify/email", payload, options)
      .then(() => toast.success("Verification email sent!"))
      .catch((err) => console.error(err));
  };

  // const onManageClick = async () => {
  //   if (user.plan === "trial") {
  //     window.location.href = "/pricing";
  //     return;
  //   }
  //   const response = await axios.post(
  //     BASE_API_URL + "/users/create-portal-session",
  //     {},
  //     {
  //       headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
  //     }
  //   );
  //   window.location.href = response.data.url;
  // };

  const onLogoutClick = async () => {
    Userfront.logout();
  };

  return (
    <div className="profile-container">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <h2>{userData.name + "'s Profile"}</h2>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <p>{"Account Email: " + userData.email}</p>
            </ListGroup.Item>
            <ListGroup.Item className="logout-row">
              <p>
                {"Reset Password: "}
                <Button onClick={onResetClick} className="profile-button">
                  Send Reset Email
                </Button>
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="profile-setting-row">
              {userData.isConfirmed ? (
                <p>Account is verified.</p>
              ) : (
                <div className="logout-row">
                  <p>
                    <span>{"Account pending verification: "}</span>
                    <Button
                      onClick={onVerifyResendClick}
                      className="profile-button"
                    >
                      Resend Verification Email
                    </Button>{" "}
                    <InfoBadge
                      info="If your verification status looks incorrect, try logging out and logging back in."
                      style={{ marginLeft: "1rem" }}
                    />
                  </p>
                </div>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <p>
                Current Plan: {user.plan.toUpperCase()}{" "}
                {/* <Link to="/pricing">
                  <Button className="primary-button">View Plans</Button>
                </Link> */}
              </p>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <p>
                {"Manage Subscription: "}
                <Button
                  onClick={() => onManageClick()}
                  className="primary-button"
                >
                  Manage Subscription
                </Button>
              </p>
            </ListGroup.Item> */}
            <ListGroup.Item>
              <p className="logout-row">
                <span>{"Logout: "}</span>
                <Button onClick={onLogoutClick} className="secondary-button">
                  Logout
                </Button>
              </p>
            </ListGroup.Item>
          </ListGroup>
          <></>
          {/* <h2>{userData.name + "'s Usage"}</h2>

          {user.period_start.valueOf() === "N/A".valueOf() ? (
            <p>Free Trial</p>
          ) : (
            <p>
              Current Period: {user.period_start} - {user.period_end}
            </p>
          )} */}

          {/* <Table hover size="lg">
            <thead>
              <tr>
                <th></th>
                <th>Usage</th>
                <th>Limit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Messages (this month)</td>
                <td>{userUsage.messages_count}</td>
                <td>{limits.messages_limit}</td>
              </tr>
              <tr>
                <td>Number of Agents</td>
                <td>{userUsage.agent_count}</td>
                <td>
                  {limits.agent_limit === 1000000
                    ? "unlimited"
                    : limits.agent_limit}
                </td>
              </tr>
             <tr>
                <td>Document Count</td>
                <td>{userUsage.document_count}</td>
                <td>{limits.document_limit}</td>
              </tr>
              <tr>
                <td>Document Pages</td>
                <td>{userUsage.document_page_count}</td>
                <td>{limits.document_page_limit}</td>
              </tr>
              <tr>
                <td>Webpages Count</td>
                <td>{userUsage.webpage_count}</td>
                <td>{limits.webpage_limit}</td>
              </tr>
             <tr>
                <td>Audio File Count</td>
                <td>{userUsage.audio_count}</td>
                <td>{limits.audio_limit}</td>
              </tr> 
              <tr>
                <td>Audio Collection Size (megabytes)</td>
                <td>{userUsage.audio_size_count}</td>
                <td>{limits.audio_size_limit}</td>
              </tr>
            </tbody>
          </Table> */}
          {/* <p>Note: Message limits reset on at the start of each period.</p> */}
        </div>
      )}
      <Toaster />
    </div>
  );
}
