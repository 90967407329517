import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import SaveBar from "../../components/SaveBar/SaveBar";

import "./Relevance.scss";

export default function Relevance() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const { bot_id } = useParams();
  const [relevance, setRelevance] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSaveBar, setShowSaveBar] = useState(false);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/relevance/${bot_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setRelevance({
            relevance_description: response.data.relevance_description,
            irrelevant_message: response.data.irrelevant_message,
            relevance_examples: response.data.relevance_examples,
            use_relevance: response.data.use_relevance,
          });
          //   console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = async () => {
    (async () => {
      const data = {
        relevance: {
          relevance_description: relevance.relevance_description,
          irrelevant_message: relevance.irrelevant_message,
          // relevance_examples: JSON.stringify(relevance.relevance_examples),
          relevance_examples: relevance.relevance_examples,
          use_relevance: relevance.use_relevance,
        },
      };
      await axios
        .put(BASE_API_URL + `/bots/relevance/${bot_id}`, data, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          //   console.log(response.data);
          window.location.reload();
        });
    })();
  };

  const handleAddItem = () => {
    // setNewExample({ ...newExample, assistant: String(newExample.assistant) });
    let newRelevance = {
      ...relevance,
      relevance_examples: [...relevance.relevance_examples, newExample],
    };
    setRelevance(newRelevance);
    setShowAdd(false);
    setShowSaveBar(true);
    setNewExample({
      user: "",
      assistant: { relevant: "Relevant" },
    });
  };

  const handleDescriptionChange = (event) => {
    let newRelevance = {
      ...relevance,
      relevance_description: event.target.value,
    };
    setRelevance(newRelevance);
    setShowSaveBar(true);
  };

  const handleDiscard = () => {
    window.location.reload();
  };

  const handleIrrelevantChange = (event) => {
    let newRelevance = { ...relevance, irrelevant_message: event.target.value };
    setRelevance(newRelevance);
    setShowSaveBar(true);
  };

  const handleDeleteExample = (index) => {
    let newRelevance = {
      ...relevance,
      relevance_examples: relevance.relevance_examples.filter(
        (item, i) => i !== index
      ),
    };
    setRelevance(newRelevance);
    setShowSaveBar(true);
  };

  const [showAdd, setShowAdd] = useState(false);

  const handleShowAdd = () => setShowAdd(true);
  const handleCloseShowAdd = () => {
    setShowAdd(false);
    setNewExample({
      user: "",
      assistant: { relevant: "Relevant" },
    });
  };

  const [newExample, setNewExample] = useState({
    user: "",
    assistant: { relevant: "Relevant" },
  });

  const handleUpdateExample = (event) => {
    setNewExample({ ...newExample, user: event.target.value });
  };

  const handleUpdateLabel = (status) => {
    console.log(status);
    setNewExample({
      ...newExample,
      assistant: { relevant: status },
    });
  };

  return (
    <div className="cache">
      {showSaveBar ? (
        <SaveBar onDiscard={handleDiscard} onSave={handleUpdate} />
      ) : null}
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div className="relevance-view">
          <Breadcrumb>
            <Breadcrumb.Item href="/webwidgets">Web Widgets</Breadcrumb.Item>
            <Breadcrumb.Item active>Bot</Breadcrumb.Item>
            <Breadcrumb.Item active>Relevance Filter</Breadcrumb.Item>
          </Breadcrumb>
          <div className="title-row">
            <h2>Relevancy Model Configuration</h2>
          </div>
          <div className="scripted-item-fields">
            <div>
              <div className="form-context">
                <h5>Relevance Instructions</h5>
                <InfoBadge info="Describe what types of messages should be considered relevant. Often this involes, providing a two sentence description of your company's products and solutions."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                as="textarea"
                className="scripted-item-answer"
                value={relevance.relevance_description}
                onChange={handleDescriptionChange}
              />
            </div>
            <div>
              <div className="form-context">
                <h5>Irrelevant Message Explanation</h5>
                <InfoBadge info="What you would like the assistant to say when it receives a message determined to be irrelevant."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                as="textarea"
                className="scripted-item-answer"
                value={relevance.irrelevant_message}
                onChange={handleIrrelevantChange}
              />
            </div>
          </div>
          <div className="relevance-title-row">
            <h3>Training Examples</h3>
            <Button className="primary-button" onClick={handleShowAdd}>
              Add Example
            </Button>
          </div>
          <Table hover striped bordered size="lg" className="base-table">
            <thead>
              <tr>
                <th>Message</th>
                <th>Correct Classification</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {relevance.relevance_examples.map((example, count) => (
                <tr key={count}>
                  <td>{example.user}</td>
                  <td>{example.assistant.relevant}</td>
                  <td>
                    <div className="action-panel">
                      <div className="cache-buttons">
                        <Button
                          className="open-button script-item-button"
                          onClick={() => handleDeleteExample(count)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <Modal show={showAdd} centered onHide={handleCloseShowAdd}>
        <Modal.Header closeButton>
          <Modal.Title>Add Question Variation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="relevance-form"
              controlId="exampleForm.ControlInput1"
            >
              <div className="form-context">
                <Form.Label>Question Variation</Form.Label>
                <InfoBadge info="Including alternative phrasings of a question help match your scripted answer to a wider variety of question phrasings."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                onChange={handleUpdateExample}
                value={newExample.user}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="relevance-form">
              <div className="form-context">
                <Form.Label>Correct Classification:</Form.Label>
                <InfoBadge info="What class should be assigned to this message"></InfoBadge>
              </div>
              <Form.Select
                value={newExample.assistant.relevant}
                onChange={(e) => {
                  handleUpdateLabel(e.target.value);
                  setShowSaveBar(true);
                }}
              >
                <option key="1" value="Relevant">
                  Relevant
                </option>
                <option key="2" value="Irrelevant">
                  Irrelevant
                </option>
                <option key="3" value="Agent">
                  Agent Handoff
                </option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleCloseShowAdd}>
            Cancel
          </Button>
          <Button className="primary-button" onClick={handleAddItem}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
