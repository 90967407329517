import axios from "axios";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Userfront from "@userfront/react";

import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./DocPage.scss";

export default function DocPage() {
  let { doc_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [doc, setDoc] = useState();
  const [docList, setDocList] = useState();

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/documents/doc/${doc_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          if (response.data.message === "Unauthorized") navigate("/bases");
          else {
            setDoc(response.data);
            // console.log(response.data.questions[0]);
          }
          // console.log(doc);
        });
      await axios
        .get(BASE_API_URL + `/documents/library`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          if (response.data.message === "Unauthorized") navigate("/bases");
          else {
            const temp_doclist = response.data;
            temp_doclist.forEach((doc, i) => {
              if (doc.doc_id === doc_id) {
                temp_doclist.splice(i, 1);
                temp_doclist.unshift(doc);
              }
            });
            // console.log(temp_doclist);
            setDocList(temp_doclist);
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc_id, BASE_API_URL, navigate]);

  const on_doc_change = (e) => {
    navigate("/doc/view/" + e.target.value);
    window.location.reload();
    // doc_id = e.target.value;
  };

  return (
    <Container className="docpage">
      {doc === undefined || docList === undefined ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <Container className="docpage">
          <Row>
            {/* <Col xs={12} md={4} className="sticky-top chat-panel-column">
              <ChatPanel
                id_constraint={doc_id}
                session_type="document"
                show_links={showProcessedHTML}
                setHighlight={on_source_click}
                questions={doc.questions}
              />
            </Col> */}
            <Col xs={12} md={8} className="doccolumn">
              <div className="doctitlebar">
                <Form className="doc-selector">
                  <div className="form-context">
                    <Form.Text>Current Document:</Form.Text>
                    <InfoBadge info="Change the active document by selecting from the dropdown menu." />
                  </div>
                  <Form.Select
                    size="md"
                    className="option"
                    onChange={on_doc_change}
                  >
                    {docList.map((docItem, index) => {
                      return (
                        <option key={index} value={docItem.doc_id}>
                          {docItem.title}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form>
              </div>
              {true ? (
                <div className="html-text">
                  {doc.sections.map((section, index) => {
                    return (
                      <span
                        key={index}
                        id={doc_id + index.toString()}
                        dangerouslySetInnerHTML={{
                          __html: (
                            section.section_raw_content + "<br/><br/>"
                          ).replaceAll("/n", "<br/><br/>"),
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: doc.raw_html }}
                  className="html-file"
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
}
