import React, { useState } from "react";
import { Form, FormControl, FormCheck } from "react-bootstrap";
import InfoBadge from "../InfoBadge/InfoBadge";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import Scheduler from "../Scheduler/Scheduler";

import "./GuardrailsOptions.scss";

function GuardrailsOptions({ bot, handleUpdate }) {
  const [errors, setErrors] = useState({
    exclusion_phrases: false,
    exclusion_explanation: false,
  });

  const validate = (name, value) => {
    let errorMessage = "";

    if (name === "exclusion_phrases") {
      if (value.length > 2000) {
        // assuming max 2000 chars
        errorMessage = "Enter less than 2000 characters.";
      }
    }

    if (name === "exclusion_explanation") {
      if (value.length > 600) {
        errorMessage = "Enter less than 600 characters.";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    return errorMessage === "";
  };

  const handleChange = (name, value) => {
    if (validate(name, value)) {
      handleUpdate(name, value);
    }
  };

  const handleSwitchChange = (name, value) => {
    handleUpdate(name, !bot[name]);
  };

  const validateExclusionPhrases = (value) => {
    if (value === "") {
      return true;
    } else {
      const regex = /^"[^"]*"(,\s*"[^"]*")*$/;
      return regex.test(value);
    }
  };

  const handleExclusionPhrasesChange = (temp) => {
    const value = temp;
    const valid = validateExclusionPhrases(value);
    console.log(valid);
    if (!valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        exclusion_phrases: true,
      }));
      handleUpdate("exclusion_phrases", value);
    } else {
      handleUpdate("exclusion_phrases", value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        exclusion_phrases: false,
      }));
    }
  };

  const handleChatToggle = (value) => {
    console.log(value);
    const temp = {
      ...bot.handoff_settings,
      active: value,
    };
    console.log("temp", temp);
    handleUpdate("handoff_settings", temp);
  };

  const handle_handoff_message_change = (name, value) => {
    const temp = {
      ...bot.handoff_settings,
      [name]: value,
    };
    handleUpdate("handoff_settings", temp);
  };

  return (
    <Form className="guardrails-section">
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Message Relevancy Model
          </Form.Label>
          <Form.Text className="section-explantion">
            RightPage's relevancy model can be configured to keep your agent on
            topic. Enabling the relevancy filter will allow you to configure
            relevancy settings for this bot so that questions unrelated to the
            topics you want the bot to answer will be politely declined.
          </Form.Text>
        </div>
        <Form.Group controlId="use_relevance" className="relevancy-panel">
          <div className="form-context">
            <FormCheck
              type="switch"
              id="custom-switch"
              label="Message Relevance Filter"
              className="access-switch"
              checked={
                bot.use_relevance === "True" || bot.use_relevance === true
                  ? true
                  : false
              }
              onChange={() => handleSwitchChange("use_relevance")}
            />
            <InfoBadge info="Configure RightPage's relevancy model to keep your agent on topic." />
          </div>
          {bot.use_relevance ? (
            <Link to={`/agents/relevance/${bot.bot_id}`} target="_blank">
              <Button className="open-button">Configure</Button>
            </Link>
          ) : null}
        </Form.Group>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Live Agent Handoff
          </Form.Label>
          <Form.Text className="section-explantion">
            Live agent handoff allows the RightPage web widget to escalate
            interactions to a live agent when appropriate. You can toggle this
            feature on or off and configure the schedule for when you want live
            agent handoffs to be available.
          </Form.Text>
        </div>
        <Form.Group
          controlId="use_handoff"
          className="relevancy-panel handoff_toggle"
        >
          <div className="form-context">
            <FormCheck
              type="switch"
              id="custom-switch"
              label="Live Agent Handoff On"
              className="access-switch"
              checked={bot.handoff_settings?.active === true ? true : false}
              onChange={(e) => handleChatToggle(e.target.checked)}
            />
            <InfoBadge info="Turn on to allow the RightPage web widget to attempt a live agent handoff when requested." />
          </div>
        </Form.Group>
        {bot.handoff_settings?.active === true ? (
          <div>
            <Scheduler bot={bot} handleUpdate={handleUpdate} />
            <Form.Group
              controlId="exclusion_explanation"
              className="full-width-input"
            >
              <div className="form-context">
                <Form.Label className="form-section-title">
                  Transition Message:
                </Form.Label>
                <InfoBadge info="Write the message you would like to display to end users when offering them option to transition to a live agent chat." />
              </div>
              <FormControl
                as="textarea"
                value={bot.handoff_settings?.transition_message || ""}
                onChange={(e) =>
                  handle_handoff_message_change(
                    "transition_message",
                    e.target.value
                  )
                }
                isInvalid={!!errors.exclusion_explanation}
              />
              <Form.Control.Feedback type="invalid">
                Please leave blank or enter an transition message for this agent
                that is less than 600 characters.
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        ) : null}
        <Form.Group
          controlId="exclusion_explanation"
          className="full-width-input"
        >
          <div className="form-context">
            <Form.Label className="form-section-title">
              Offline Explanation:
            </Form.Label>
            <InfoBadge info="Write the response you would like to provide when a live agent chat session is requested, but not available." />
          </div>
          <FormControl
            as="textarea"
            value={
              bot.handoff_settings?.offline_explanation
                ? bot.handoff_settings?.offline_explanation
                : ""
            }
            onChange={(e) =>
              handle_handoff_message_change(
                "offline_explanation",
                e.target.value
              )
            }
            isInvalid={!!errors.exclusion_explanation}
          />
          <Form.Control.Feedback type="invalid">
            Please leave blank or enter an offline explanation for this agent
            that is less than 600 characters.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Topic Avoidance
          </Form.Label>
          <Form.Text className="section-explantion">
            RightPage's excluded phrases feature can be configured to keep your
            agent on topic. Enabling the topic avoidance filter will allow you
            to configure topic avoidance settings for this bot so that questions
            unrelated to the topics you want the bot to answer will be politely
            declined with the explanation you provide. The Excluded phrases is a
            list of phrases that, if included in a question, you do not want
            your agent to answer.
          </Form.Text>
        </div>
        <Form.Group controlId="exclusion_phrases" className="full-width-input">
          <div className="form-context">
            <Form.Label className="form-section-title">
              Exclusion Phrases:
            </Form.Label>
            <InfoBadge info="List phrases that, if included in a question, you do not want your agent to answer" />
          </div>
          <Form.Text>
            <p className="explanatory-text">
              Wrap each phrase in quotes and separate with a comma (e.g.,
              "salary", "pay" )
            </p>
          </Form.Text>
          <FormControl
            as="textarea"
            value={bot.exclusion_phrases}
            onChange={(e) => handleExclusionPhrasesChange(e.target.value)}
            isInvalid={!!errors.exclusion_phrases}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a list of exclusion phrases. Each phrase must be
            wrapped with a pair of quotes and separated by a comma.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          controlId="exclusion_explanation"
          className="full-width-input"
        >
          <div className="form-context">
            <Form.Label className="form-section-title">
              Exclusion Explanation:
            </Form.Label>
            <InfoBadge info="Write the response you would like the agent to provide when a question includes a phrase in the exclusion phrase list." />
          </div>
          <FormControl
            as="textarea"
            value={bot.exclusion_explanation}
            onChange={(e) =>
              handleChange("exclusion_explanation", e.target.value)
            }
            isInvalid={!!errors.exclusion_explanation}
          />
          <Form.Control.Feedback type="invalid">
            Please leave blank or enter an exclusion phrase explanation for this
            agent that is less than 600 characters.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </Form>
  );
}

export default GuardrailsOptions;
