import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import Spinner from "react-bootstrap/Spinner";

import DocumentRow from "../../components/DocumentRow/DocumentRow";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import PageHandler from "../../components/PageHandler/PageHandler";
import formatTimestamp from "../../services/TimeService";

import "./Crawl.scss";

export default function Crawl() {
  const { crawl_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [crawl, setCrawl] = useState();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/crawls/${crawl_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
          params: { page, per_page: perPage },
        })
        .then((response) => {
          setCrawl(response.data);
          setTotalPages(Math.ceil(response.data.doc_count / perPage));
          // console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handlePerPageChange = (selectedPerPage) => {
    setPerPage(selectedPerPage);
    setPage(1); // Reset to the first page when perPage changes
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/bases">All Knowledge Bases</Breadcrumb.Item>
            <Breadcrumb.Item href={`/bases/${crawl.base_id}`}>
              {crawl.base_name ? crawl.base_name : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{crawl.crawl_name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="title-area">
            <div>
              <h6>Website Import Name:</h6>
              <h2>{crawl.crawl_name}</h2>
            </div>
            <div>
              <Button
                className="primary-button"
                onClick={() => handleShow(true)}
              >
                Website Import Settings
              </Button>
            </div>
          </div>
          <div className="crawl-status-row">
            <div className="crawl-status-column">
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Start URL(s):</Form.Label>
                  <InfoBadge info="The URLs that the crawl began on. Non-editable."></InfoBadge>
                </div>
                <Form.Control placeholder={crawl.seeds} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Status:</Form.Label>
                  <InfoBadge info="The current status of the website import. Non-editable."></InfoBadge>
                </div>
                <Form.Control placeholder={crawl.status} disabled />
              </Form.Group>
            </div>
            <div className="crawl-status-column">
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Created At:</Form.Label>
                  <InfoBadge info="When the crawl was created. Non-editable."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={formatTimestamp(crawl.created_at)}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Repeat Frequency:</Form.Label>
                  <InfoBadge info="How frequently the crawl is ran. Non-editable."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={
                    crawl.repeat === 0 ? "Never" : crawl.repeat + " days"
                  }
                  disabled
                />
              </Form.Group>
            </div>
          </div>
          <div>
            {crawl.status !== "Crawl Completed." ? (
              <p className="crawl-status-message">
                Current Status of Import: {crawl.status}
              </p>
            ) : (
              <>
                <h2 className="base-section-title">
                  Documents from last crawl ({crawl.doc_count}):
                </h2>
                <DocumentRow documents={crawl.documents} />
                <PageHandler
                  perPage={perPage}
                  handlePerPageChange={handlePerPageChange}
                  page={page}
                  handlePageChange={handlePageChange}
                  totalPages={totalPages}
                />
              </>
            )}
          </div>
          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header
              className="doc-support-overlay"
              closeButton
            ></Offcanvas.Header>
            <Offcanvas.Body>
              <h3>Website Import Settings</h3>
              <p>Settings cannot be modified after a crawl has been ran.</p>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Crawl Delay:</Form.Label>
                  <InfoBadge info="Length of pause between page requests."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={crawl.crawlDelay + " seconds"}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Max Hops:</Form.Label>
                  <InfoBadge info="Levels of descendent links that will be crawled."></InfoBadge>
                </div>
                <Form.Control placeholder={crawl.maxHops} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Max to Crawl:</Form.Label>
                  <InfoBadge info="The maximum number of pages that will be crawled"></InfoBadge>
                </div>
                <Form.Control placeholder={crawl.maxToCrawl} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Max to Process:</Form.Label>
                  <InfoBadge info="The maximum number of pages that will be processed"></InfoBadge>
                </div>
                <Form.Control placeholder={crawl.maxToProcess} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Do Not Crawl Pattern:</Form.Label>
                  <InfoBadge info="Avoid crawling certain pages that match the provided string (e.g., '/category' will avoid any URLs containing '/category')"></InfoBadge>
                </div>
                <Form.Control
                  placeholder={crawl.do_not_crawl_pattern}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Cookie:</Form.Label>
                  <InfoBadge info="Optionally include a valid authentication cookie string to allow the crawl to access pages that require authentication"></InfoBadge>
                </div>
                <Form.Control placeholder={crawl.cookie} disabled />
              </Form.Group>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      )}
    </div>
  );
}
