import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import App from "./App";

ReactGA.initialize("G-Q4WYBVDYTY");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
