import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

import "./SuggestedTopics.scss";

function SuggestedTopics({ bot, handleUpdate }) {
  const botQuestions = bot.default_questions;
  const verifiedAnswers = bot.verified_answers;
  // Updates a question at a specific index
  const handleQuestionChange = (e, index) => {
    let newQuestions = [...botQuestions];
    newQuestions[index] = e.target.value;

    handleUpdate("default_questions", newQuestions);
  };

  // Updates a verified answer at a specific index
  const handleVerifiedAnswerChange = (e, index) => {
    let newVerifiedAnswers = [...verifiedAnswers];
    newVerifiedAnswers[index] = e.target.checked;

    handleUpdate("verified_answers", newVerifiedAnswers);
  };

  // Adds a new question to the list
  const addQuestion = () => {
    let newQuestions = [...botQuestions, ""];
    let newVerifiedAnswers = [...verifiedAnswers, false];

    handleUpdate("default_questions", newQuestions);
    handleUpdate("verified_answers", newVerifiedAnswers);
  };

  // Removes a question at a specific index
  const removeQuestion = (index) => {
    let newQuestions = botQuestions.filter((_, i) => i !== index);
    let newVerifiedAnswers = verifiedAnswers.filter((_, i) => i !== index);

    handleUpdate("default_questions", newQuestions);
    handleUpdate("verified_answers", newVerifiedAnswers);
  };

  return (
    <Form>
      <div className="topics-form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Default Suggested Topics:
          </Form.Label>
          <Form.Text className="section-explanation">
            The topics or questions that the agent will suggest at the start of
            an interaction (if turned on). Check the box next to a question to
            mark it as verified.
          </Form.Text>
        </div>
        <Row className="suggested-q-row">
          <Col md={8}>Topic</Col>
          <Col md={1}>Verified</Col>
          <Col md={3}>Remove</Col>
        </Row>
        {botQuestions.map((question, index) => (
          <Row key={index} className="suggested-q-row">
            <Col md={8}>
              <Form.Control
                type="text"
                value={question}
                onChange={(e) => handleQuestionChange(e, index)}
                placeholder={`Question ${index + 1}`}
              />
            </Col>
            <Col md={1}>
              <Form.Check
                inline
                type="checkbox"
                label=""
                checked={verifiedAnswers[index]}
                className="verified-box"
                onChange={(e) => handleVerifiedAnswerChange(e, index)}
              />
            </Col>
            <Col md={3}>
              <Button
                className="open-button"
                onClick={() => removeQuestion(index)}
              >
                Remove
              </Button>
            </Col>
          </Row>
        ))}
        {botQuestions.length < 3 ? (
          <Button class-name="primary-button" onClick={addQuestion}>
            Add question
          </Button>
        ) : null}
      </div>
    </Form>
  );
}

export default SuggestedTopics;
