import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Userfront from "@userfront/react";
import toast, { Toaster } from "react-hot-toast";
import EmptyState from "../../components/EmptyState/EmptyState";
import "./RoutesTable.scss";
import { Spinner } from "react-bootstrap";
import AddIconLight from "../../assets/ActionIcons/AddIconLight.png";
import { Link } from "react-router-dom";

function RoutesTable({
  route_type = "agent_assist",
  table_title = "Ticket to Bot Routes",
}) {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [routes, setRoutes] = useState([]);
  const [bots, setBots] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState({
    title: "",
    identifier: "",
    bot_id: "",
  });
  const [loading, setLoading] = useState(true);
  const [botIdToAgentName, setBotIdToAgentName] = useState(new Map());

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/integrations/routes/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const filterRoutes = response.data.filter(
            (route) => route.route_type === route_type
          );
          setRoutes(filterRoutes);
          if (response.data.length > 0) {
            setSelectedRoute(response.data[0]);
          }
        });
      if (route_type === "agent_assist") {
        await axios
          .get(BASE_API_URL + `/bots/`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            let filterType = "Support Representative (Internal)";
            if (route_type === "web_widget") {
              filterType = "Support Guide (External)";
            }
            const filteredAgents = response.data.filter(
              (agent) => agent.objective === filterType
            );
            setBots(filteredAgents);
            //   setBots(response.data);
            let temp = new Map();
            filteredAgents.forEach((bot) =>
              temp.set(bot.bot_id, bot.agent_name)
            );
            setBotIdToAgentName(temp);
            setLoading(false);
            // Set default api key to first bot when bots is available
          });
      } else if (route_type === "web_widget") {
        await axios
          .get(BASE_API_URL + `/widgets/`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            let tempWidgets = response.data;
            tempWidgets.forEach((widget) => {
              widget.agent_name = widget.widget_title;
              widget.bot_id = widget.widget_id;
            });
            setBots(tempWidgets);

            let temp = new Map();
            response.data.forEach((bot) =>
              temp.set(bot.widget_id, bot.widget_title)
            );
            console.log(temp);
            setBotIdToAgentName(temp);
            setLoading(false);
          });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [newRoute, setNewRoute] = useState(false);

  const handleShowModal = (routeId) => {
    if (!bots || bots.length === 0) {
      toast.error(
        "You must create an agent assist bot before creating a route."
      );
      return;
    }
    if (routeId === null) {
      setSelectedRoute({
        title: "",
        identifier: "",
        bot_id: bots.length > 0 ? bots[0].bot_id : "",
      });
      setNewRoute(true);
    } else {
      setSelectedRoute(
        routes.find((route) => route.route_id === routeId) || {}
      );
    }
    setShowModal(true);
  };

  const handleChange = (e) => {
    setSelectedRoute({ ...selectedRoute, [e.target.name]: e.target.value });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewRoute(false);
  };

  const handleUpdateRoute = () => {
    let data = new FormData();
    data.append("title", selectedRoute.title);
    data.append("identifier", selectedRoute.identifier);
    data.append("bot_id", selectedRoute.bot_id);
    if (newRoute) {
      handleNewRoute();
      return;
    }
    axios
      .put(
        BASE_API_URL + `/integrations/routes/${selectedRoute["route_id"]}`,
        data,
        {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        }
      )
      .then((response) => {
        window.location.reload();
      });
  };

  const handleNewRoute = () => {
    const data = new FormData();
    data.append("title", selectedRoute.title);
    data.append("identifier", selectedRoute.identifier);
    data.append("bot_id", selectedRoute.bot_id);
    data.append("route_type", route_type);
    console.log("new route data", route_type);
    axios
      .post(BASE_API_URL + `/integrations/routes/`, data, {
        headers: {
          authorization: `Bearer ${Userfront.tokens.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error(
            "Error creating route - identifier must be globally unique with no spaces."
          );
        }
      });
  };

  const handleDeleteRoute = async (index) => {
    if (routes && Array.isArray(routes) && routes.length > index) {
      const id = routes[index].route_id;
      await axios
        .delete(BASE_API_URL + `/integrations/routes/${id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          // On successful deletion, remove the route from local state as well
          const newRoutes = [...routes];
          newRoutes.splice(index, 1);
          setRoutes(newRoutes);
        })
        .catch((error) => {
          console.error("Failed to delete the route", error);
        });
    } else {
      console.error("Invalid index. Could not delete the route.");
    }
  };

  // Here is your reusable routes table component
  return (
    <div>
      {loading ? (
        <Spinner animation="border" role="status"></Spinner>
      ) : (
        <>
          <div className="route-title-row">
            <h3>{table_title}</h3>
            <Button
              className="primary-button"
              onClick={() => handleShowModal(null)}
            >
              <img src={AddIconLight} alt="add route icon" />
              <span>Add Route</span>
            </Button>
          </div>
          {routes && routes.length !== 0 ? (
            <>
              <Table hover striped bordered size="lg" className="base-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>
                      {route_type === "web_widget"
                        ? "Web Widget to Route to"
                        : "Bot to Route to"}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {routes.map((route, index) => (
                    <tr key={index}>
                      <td>{route.title}</td>
                      <td>{botIdToAgentName.get(route.bot_id)}</td>
                      <td>
                        <div className="action-panel">
                          <Link to={`/route/${route.route_id}`}>
                            <Button className="open-button">Configure</Button>
                          </Link>
                          <Button
                            className="open-button"
                            onClick={() => handleDeleteRoute(index)}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <EmptyState type="routes" onClick={() => handleShowModal(null)} />
          )}
          {bots && bots.length !== 0 ? (
            <Modal show={showModal} centered onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Route Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group
                    controlId="formRouteTitle"
                    className="route-form-field"
                  >
                    <Form.Label>Route Display Name</Form.Label>
                    <InfoBadge info="Give your route a recognizable name that indicates to your team what type of questions this route should receive." />
                    <Form.Control
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      value={selectedRoute.title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formRouteBotId"
                    className="route-form-field"
                  >
                    <Form.Label>
                      {route_type === "agent_assist"
                        ? "Route to Bot"
                        : "Route to Web Widget"}
                    </Form.Label>
                    <InfoBadge info="Select the bot or web widget that should handle inquiries that match this route's rules." />
                    <Form.Select
                      defaultValue={selectedRoute.bot_id}
                      onChange={handleChange}
                      name="bot_id"
                    >
                      {bots.map((bot) => (
                        <option key={bot.bot_id} value={bot.bot_id}>
                          {bot.agent_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleUpdateRoute}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </>
      )}
      <Toaster />
    </div>
  );
}

export default RoutesTable;
