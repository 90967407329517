import React from "react";
import { Form, FormCheck } from "react-bootstrap";
import InfoBadge from "../../InfoBadge/InfoBadge";

import "./SuggestedOptions.scss";

function SuggestedOptions({ bot, handleUpdate }) {
  const toggleField = (name) => {
    handleUpdate(name, !bot[name]);
  };

  return (
    <Form>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">Suggest Macros</Form.Label>
          <Form.Text className="section-explantion">
            In addition, to supplying a draft response, the bot can also suggest
            macros that can be used to answer the request. If a user invokes a
            Macro that the bot suggests it will be exactly the same as if they
            invoked the Macro through the built-in options.
          </Form.Text>
        </div>
        <Form.Group controlId="suggest_macros" className="access-option">
          <div className="form-context">
            <FormCheck
              type="switch"
              className="access-switch"
              label="Suggest Macros"
              checked={
                bot.suggest_macros === "True" || bot.suggest_macros === true
                  ? true
                  : false
              }
              onChange={() => toggleField("suggest_macros")}
            />
            <InfoBadge info="Turn on if you would like the bot to suggest macros." />
          </div>
        </Form.Group>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">Suggest Tags</Form.Label>
          <Form.Text className="section-explantion">
            In addition, to supplying a draft response, the bot can also suggest
            tags that would be relevant to add to the current ticket. Suggested
            tags are based on the most common tags used in the past and the tags
            associated with the top suggested macro.
          </Form.Text>
        </div>
        <Form.Group controlId="suggest_tags" className="access-option">
          <div className="form-context">
            <FormCheck
              type="switch"
              className="access-switch"
              label="Suggest Tags"
              checked={
                bot.suggest_tags === "True" || bot.suggest_tags === true
                  ? true
                  : false
              }
              onChange={() => toggleField("suggest_tags")}
            />
            <InfoBadge info="Turn on if you would like the bot to suggest tags." />
          </div>
        </Form.Group>
        <div className="info-context">
          <hr />
          <p>
            <strong>Note:</strong> You must specify at least one knowledge base
            that contains Macros on the "Templates" settings tab in order for
            suggest macros and suggest tags to work.
          </p>
        </div>
      </div>
    </Form>
  );
}

export default SuggestedOptions;
