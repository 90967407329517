import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Button, Table, Modal } from "react-bootstrap";
import Userfront from "@userfront/react";
import AddWidgetModal from "../AddWidgetModal/AddWidgetModal";
import AddIconLight from "../../assets/ActionIcons/AddIconLight.png";

import EmptyState from "../EmptyState/EmptyState";

import "./WidgetTable.scss";

const WidgetTable = ({ widgets, fetchWidgets, setWidgets }) => {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [selectedWidget, setSelectedWidget] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);

  const handleToggleAddModal = () => {
    setShowAddWidgetModal(!showAddWidgetModal);
  };

  const deleteWidget = async () => {
    try {
      await axios.delete(`${BASE_API_URL}/widgets/${selectedWidget}`, {
        headers: {
          authorization: `Bearer ${Userfront.tokens.accessToken}`,
        },
      });
      fetchWidgets(); // Refresh widgets after deletion
      setShowDeleteModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="agent-table-title widget-title">
        <h3>Web Widgets (Appearance and Branding)</h3>
        <Button
          onClick={() => setShowAddWidgetModal(true)}
          className="primary-button"
        >
          <img src={AddIconLight} alt="add icon" />
          <span>Add Widget</span>
        </Button>
      </div>
      {widgets.length === 0 ? (
        <EmptyState
          type="Web Widgets"
          onClick={() => setShowAddWidgetModal(true)}
        />
      ) : (
        <Table hover striped bordered size="lg" className="base-table">
          <thead>
            <tr>
              <th>Widget Name</th>
              <th>Linked Bot</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {widgets.map((widget) => (
              <tr key={widget.widget_id}>
                <td>{widget.widget_title}</td>
                <td>{widget.bot_title}</td>
                <td className="action-panel">
                  <NavLink
                    to={`/widget/${widget.widget_id}`}
                    className="open-button"
                  >
                    Edit
                  </NavLink>
                  <Button
                    onClick={() => {
                      setSelectedWidget(widget.widget_id);
                      setShowDeleteModal(true);
                    }}
                    className="open-button"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Widget</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this widget?</Modal.Body>
        <Modal.Footer>
          <Button
            className="open-button"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="primary-button" onClick={deleteWidget}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showAddWidgetModal && (
        <AddWidgetModal
          toggleModal={handleToggleAddModal}
          show={showAddWidgetModal}
          widgets={widgets}
        />
      )}
    </>
  );
};

export default WidgetTable;
