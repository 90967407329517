import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Userfront from "@userfront/react";

import AgentChatPanel from "../../components/AgentChatPanel/AgentChatPanel";

import "./AgentChat.scss";

export default function AgentChat() {
  let { bot_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [answerLength, setAnswerLength] = useState(150);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState();
  const [showLinks, setShowLinks] = useState(true);
  const [showAnswers, setShowAnswers] = useState(true);
  const [speed, setSpeed] = useState("fast");
  const [title, setTitle] = useState();

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/${bot_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setTitle(response.data.bot_title);
          setQuestions(JSON.parse(response.data.default_questions));
          setShowLinks(response.data.show_links);
          setShowAnswers(response.data.show_answers);
          setSpeed(response.data.speed);
          setAnswerLength(response.data.answer_length);
          setLoading(false);
        });
    })();
  }, [bot_id, BASE_API_URL]);

  // console.log("Bot ID:");
  // console.log(bot_id);
  // console.log("Questions:", questions);
  return (
    <div className="collection-chat-container">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <h6>Agent Chat:</h6>
          <h2>{title} Chat</h2>
          <AgentChatPanel
            session_type={"agent"}
            id={bot_id}
            default_questions={questions}
            show_links={showLinks}
            show_answers={showAnswers}
            speed={speed}
            answer_length={answerLength}
          />
        </>
      )}
    </div>
  );
}
