import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import "./Sidebar.scss";
import Userfront from "@userfront/react";

import SidebarLink from "./SidebarLink/SidebarLink";

import AgentIconLight from "../../assets/SideBarIcons/AgentIconLight.png";
import AgentIconDark from "../../assets/SideBarIcons/AgentIconDark.png";
import KnowledeBaseIconLight from "../../assets/SideBarIcons/KnowledgeBaseIconLight.png";
import KnowledeBaseIconDark from "../../assets/SideBarIcons/KnowledgeBaseIconDark.png";
import WorkflowIconDark from "../../assets/SideBarIcons/WorkflowIconDark.png";
import WorkflowIconLight from "../../assets/SideBarIcons/WorkflowIconLight.png";
import WebWidgetIconDark from "../../assets/SideBarIcons/WebWidgetIconDark.png";
import WebWidgetIconLight from "../../assets/SideBarIcons/WebWidgetIconLight.png";
import IntegrationsIconDark from "../../assets/SideBarIcons/IntegrationsIconDark.png";
import IntegrationsIconLight from "../../assets/SideBarIcons/IntegrationsIconLight.png";
import InternalSupportIconDark from "../../assets/SideBarIcons/InternalSupportIconDark.png";
import InternalSupportIconLight from "../../assets/SideBarIcons/InternalSupportIconLight.png";

const SidebarMenu = () => {
  const location = useLocation();

  function isActive(path) {
    return location.pathname.startsWith(path);
  }

  return (
    <div className="flex-column sidebar-menu">
      <Navbar.Brand href="/">
        <div className="logo">
          <img
            src={require("../../assets/RightPage-Logo-Light.png")}
            alt="RightPage Logo"
            className="sidebar-logo"
          ></img>
        </div>
      </Navbar.Brand>
      <div className="inner-sidebar">
        <Nav className="" defaultActiveKey="/bases">
          {/* <SidebarLink
            to="/conversations"
            label="Analytics"
            iconGrey={AnalyticsIconDark}
            iconWhite={AnalyticsIconLight}
            active={location.pathname === "/conversations"}
          /> */}
          <SidebarLink
            to="/bases"
            label="Knowledge Bases"
            iconGrey={KnowledeBaseIconDark}
            iconWhite={KnowledeBaseIconLight}
            active={isActive("/bases")}
          />
          <SidebarLink
            to="/assist"
            label="Agent Assist"
            iconGrey={AgentIconDark}
            iconWhite={AgentIconLight}
            active={location.pathname === "/assist"}
          />
          <SidebarLink
            to="/webwidgets"
            label="Web Widgets"
            iconGrey={WebWidgetIconDark}
            iconWhite={WebWidgetIconLight}
            active={location.pathname === "/webwidgets"}
          />
          <SidebarLink
            to="/slack"
            label="Slack Assist"
            iconGrey={InternalSupportIconDark}
            iconWhite={InternalSupportIconLight}
            active={location.pathname === "/slack"}
          />
          <SidebarLink
            to="/tags"
            label="Automated Tagging"
            iconGrey={WorkflowIconDark}
            iconWhite={WorkflowIconLight}
            active={location.pathname === "/tags"}
          />
          <SidebarLink
            to="/integrations"
            label="Integrations"
            iconGrey={IntegrationsIconDark}
            iconWhite={IntegrationsIconLight}
            active={location.pathname === "/integrations"}
          />
        </Nav>
        <div className="user-profile">
          <div className="avatar">
            {Userfront.tokens.accessToken ? (
              <NavLink to="/profile">
                {Userfront.user.name.charAt(0).toUpperCase()}
              </NavLink>
            ) : (
              <NavLink to="/login"></NavLink>
            )}
          </div>

          <div className="user-name">
            {Userfront.tokens.accessToken ? (
              <NavLink to="/profile">{Userfront.user.name}</NavLink>
            ) : (
              <NavLink to="/login">Login</NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
