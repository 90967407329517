import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";

import "./ModalSaveBar.scss";

export default function ModalSaveBar({ onDiscard = null, onSave }) {
  return (
    <Navbar
      expand="lg"
      variant="light"
      bg="light"
      fixed="bottom"
      className="modal-save-bar"
    >
      <div className="save-row">
        {onDiscard !== null ? (
          <Button onClick={onDiscard} className="secondary-button save-buttons">
            Discard Changes
          </Button>
        ) : null}
        <Button className="primary-button save-buttons" onClick={onSave}>
          Save Changes
        </Button>
      </div>
    </Navbar>
  );
}
