import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Userfront from "@userfront/react";
import { Breadcrumb, Spinner } from "react-bootstrap";
import "./ConfluenceImport.scss";

export default function ConfigureConfluenceImport() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [spaceKey, setSpaceKey] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [confluenceIntegration, setConfluenceIntegration] = useState({});
  const [syncInterval, setSyncInterval] = useState("weekly");
  const [activeBase, setActiveBase] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (!location.state) {
        toast.error("No base ID found");
        navigate("/bases");
      }
      const { base_id } = location.state;

      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          const temp_bases = response.data;
          temp_bases.forEach((base, i) => {
            if (base.base_id === base_id) {
              temp_bases.splice(i, 1);
              temp_bases.unshift(base);
            }
          });
          // setBases(temp_bases);
          setActiveBase(temp_bases[0]);
          // console.log(response);
        });
      await axios
        .get(BASE_API_URL + `/integrations/`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          //   console.log(response.data[0]);
          let integration = response.data.filter(
            (integration) => integration.type === "integrations_confluence"
            // Break out of the function
          );
          if (integration) {
            // console.log(integration);
            setConfluenceIntegration(integration[0]);
          } else {
            setConfluenceIntegration({});
            toast.error("No Confluence Integration Found");
          }
        });
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const startSyncOperation = async () => {
    if (spaceKey === "") {
      toast.error("Please enter a space key");
      return;
    }
    setLoading(true);
    const { base_id } = location.state;
    let frequencyMinutes;
    switch (syncInterval) {
      case "Daily":
        frequencyMinutes = 1440;
        break;
      case "Weekly":
        frequencyMinutes = 10080;
        break;
      case "Monthly":
        frequencyMinutes = 43200;
        break;
      default:
        frequencyMinutes = 10080;
    }
    // console.log("Confluence ID", confluenceIntegration.id);
    const schedulingPayload = {
      enabled: true,
      frequency_minutes: frequencyMinutes,
      schedule_metadata: {
        integration_id: confluenceIntegration.id,
        base_id: base_id,
        type: "confluence_document_crawl",
        space_key: spaceKey,
      },
    };

    const importPayload = {
      integration_id: confluenceIntegration.id,
      knowledge_base_id: base_id,
      space_key: spaceKey,
      type: "confluence_document_crawl",
    };

    const auth = {
      headers: {
        authorization: `Bearer ${Userfront.tokens.accessToken}`,
      },
    };

    try {
      await axios.post(BASE_API_URL + "/scheduling/", schedulingPayload, auth);
      await axios.post(
        BASE_API_URL + "/confluence_document_import/",
        importPayload,
        auth
      );
      setLoading(false);
      toast.success("Scheduled the sync operation successfully");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to schedule the sync operation");
    }
  };

  const useSyncInterval = ["Daily", "Weekly", "Monthly"];

  return (
    <div className="config-confluence-import">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <div>
            <Breadcrumb className="">
              <Breadcrumb.Item key={0} href="/bases">
                All Knowledge Bases
              </Breadcrumb.Item>
              <Breadcrumb.Item key={1} href={`/bases/${activeBase.base_id}`}>
                {activeBase && activeBase.base_name
                  ? activeBase.base_name
                  : "Knowledge Base"}
              </Breadcrumb.Item>
              <Breadcrumb.Item active key={2}>
                Import Macros
              </Breadcrumb.Item>
            </Breadcrumb>
            <h2>Confluence Sync</h2>
          </div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Sync Frequency:</Form.Label>
              <Form.Select
                value={syncInterval}
                onChange={(e) => {
                  setSyncInterval(e.target.value);
                }}
              >
                {useSyncInterval.map((option, index) => {
                  return <option key={index}>{option}</option>;
                })}
                ;
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confluence Space to Import:</Form.Label>
              <Form.Control
                type="text"
                value={spaceKey}
                onChange={(e) => setSpaceKey(e.target.value)}
              />
            </Form.Group>
            <Button
              onClick={startSyncOperation}
              disabled={loading}
              className="primary-button"
              type="submit"
            >
              {loading ? "Starting..." : "Start Sync"}
            </Button>
          </Form>
        </>
      )}
      <Toaster />
    </div>
  );
}
