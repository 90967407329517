import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      <p>
        Questions? Call <strong>617-682-0525</strong> or{" "}
        <a className="email-link" href="mailto:hello@rightpage.com">
          email us
        </a>
        .
      </p>
    </div>
  );
}
