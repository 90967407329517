import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import InfoBadge from "../../../components/InfoBadge/InfoBadge";

import "./IntegrationConfig.js";

const IntegrationConfig = ({ integration, onConfigSubmit }) => {
  const [tokenField, setTokenField] = useState("");
  const [emailField, setEmailField] = useState("");
  const [subdomainField, setSubdomainField] = useState("");
  const [tokenFieldError, setTokenFieldError] = useState(null);
  const [subDomainInputError, setSubDomainInputError] = useState(null);
  const [emailFieldError, setEmailFieldError] = useState(null);

  function getPrettifiedString(origStr) {
    let str = origStr.split("_")[1]; // Split the string by underscores and take the second item
    str = str.charAt(0).toUpperCase() + str.slice(1); // Capitalize the first letter
    return str;
  }
  const integrationName = getPrettifiedString(integration["type"]);

  const handleSubDomainChange = (e) => {
    const re = /^[0-9a-zA-Z]+$/;
    setSubdomainField(e.target.value);
    if (re.test(String(e.target.value))) {
      setSubDomainInputError(null);
    } else {
      setSubDomainInputError("Subdomain must be alphanumeric");
    }
  };

  const handleEmailChange = (e) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailField(e.target.value);
    if (re.test(String(e.target.value).toLowerCase())) {
      setEmailFieldError(null);
    } else {
      setEmailFieldError("Invalid email address.");
    }
  };

  const handleTokenChange = (e) => {
    setTokenField(e.target.value);
    if (
      integration.type === "integrations_zendesk" &&
      e.target.value.length !== 40
    ) {
      setTokenFieldError(
        "Invalid token length. Please copy and paste the token from your account."
      );
    } else if (
      integration.type === "integrations_confluence" &&
      e.target.value.length !== 192
    ) {
      setTokenFieldError(
        "Invalid token length. Please copy and paste the token from your account."
      );
    } else {
      setTokenFieldError(null);
    }
  };

  return (
    <Modal show={integration["showConfig"]} onHide={integration["handleClose"]}>
      <Modal.Header closeButton>
        <Modal.Title>Configure {integrationName} Integration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onConfigSubmit(integration["id"], {
              token: tokenField,
              system: integration["type"],
              email: emailField,
              domain: subdomainField,
            });
          }}
        >
          <Form.Group className="mb-3">
            <div className="form-context">
              <Form.Label>Integration Status:</Form.Label>
              <InfoBadge
                info={`Indicates whether the ${integrationName} integration is current active or not.`}
              />
            </div>
            <Form.Control
              placeholder={
                integration["enabled"] ? "Connected" : "Not Connected"
              }
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="form-context">
              <Form.Label>Set API Token</Form.Label>
              <InfoBadge
                info={`Provide your ${integrationName} API token to allow integration. We store and encrypt your API token.`}
              />
            </div>
            <Form.Control
              type="input"
              value={tokenField}
              onChange={handleTokenChange}
              placeholder={
                integration["enabled"]
                  ? "Previously provided"
                  : `Enter ${integrationName} API Token`
              }
              autoFocus
              isInvalid={tokenFieldError}
            />
            <Form.Control.Feedback type="invalid">
              {tokenFieldError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="form-context">
              <Form.Label>Set Default Email</Form.Label>
              <InfoBadge info="Required to properly authenticate API requests. You can enter any email address that is associated with an active user in your account." />
            </div>
            <Form.Control
              type="input"
              value={emailField}
              onChange={(e) => handleEmailChange(e)}
              placeholder={
                integration["enabled"]
                  ? "Previously provided"
                  : "Enter email address"
              }
              isInvalid={emailFieldError}
            />
            <Form.Control.Feedback type="invalid">
              {emailFieldError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="form-context">
              <Form.Label>{integrationName} subdomain</Form.Label>
              <InfoBadge
                info={`Enter the ${integrationName} subdomain associated with your instance`}
              />
            </div>
            <Form.Control
              type="input"
              value={subdomainField}
              onChange={(e) => handleSubDomainChange(e)}
              placeholder={
                integration["enabled"]
                  ? "Previously provided"
                  : `Enter ${integrationName} subdomain`
              }
              isInvalid={subDomainInputError}
            />
            <Form.Control.Feedback type="invalid">
              {subDomainInputError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={integration["handleClose"]}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            onConfigSubmit(integration["id"], {
              token: tokenField,
              system: integration["type"],
              email: emailField,
              domain: subdomainField,
            });
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IntegrationConfig;
