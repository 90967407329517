import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Userfront from "@userfront/react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";

import DocumentRow from "../../components/DocumentRow/DocumentRow";
import PageHandler from "../../components/PageHandler/PageHandler";
import { formatSyncType } from "../../services/FormatService.js";
import { convertToLocalTime } from "../../services/TimeService.js";
import "./KnowledgeBase.scss";
import ImportModal from "../../components/ImportModal/ImportModal";
import AddIconLight from "../../assets/ActionIcons/AddIconDark.png";
import ImportIconLight from "../../assets/ActionIcons/ImportIconLight.png";

export default function KnowledgeBase() {
  const { base_id } = useParams();
  const baseNameField = useRef();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [importShow, setImportShow] = useState(false);
  const handleImportClose = () => setImportShow(false);
  const [base, setBase] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isPrivate, setPrivate] = useState("Private");
  const [loading, setLoading] = useState(true);
  const [nameField, setNameField] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [show, setShow] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [schedules, setSchedules] = useState([{}]);
  const [scheduleDeleteShow, setScheduleDeleteShow] = useState(false);
  const [quickAddShow, setQuickAddShow] = useState(false);
  const [docText, setDocText] = useState("");
  const [titleField, setTitleField] = useState("");
  const quickAddTitle = useRef();

  const docTitleChange = (e) => {
    setTitleField(e.target.value);
  };

  const handleDocTextChange = (e) => {
    setDocText(e.target.value);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bases/${base_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
          params: { page, per_page: perPage },
        })
        .then((response) => {
          setTotalPages(Math.ceil(response.data.doc_count / perPage));
          setBase(response.data);
          setNameField(response.data.base_name);
          setPrivate(response.data.private ? "Private" : "Public");
        });
      await axios
        .get(BASE_API_URL + `/scheduling/${base_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setSchedules(response.data);
        });
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteScheduleClose = () => setScheduleDeleteShow(false);
  const handleScheduleDeleteShow = () => setScheduleDeleteShow(true);
  const handleQuickAddShow = () => setQuickAddShow(true);
  const handleQuickAddClose = () => setQuickAddShow(false);

  const inputChange = (e) => {
    setNameField(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (nameField === "") {
      setFormErrors({ text: "Please name your knowledge base" });
    } else {
      const base_name = nameField;
      let private_field = true;
      if (isPrivate === "public") {
        private_field = false;
      }
      const data = {
        base_name: base_name,
        private: private_field,
        base_id: base_id,
      };

      await axios
        .put(BASE_API_URL + `/bases/${base_id}`, data, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setShow(false);
          setNameField("");
          setPrivate("private");
          window.location.reload();
        });
    }
  };

  const showDeleteModal = (id) => {
    setDeleteId(id);
    handleDeleteShow(true);
  };

  const showDeleteScheduleModal = (id) => {
    setDeleteId(id);
    handleScheduleDeleteShow(true);
  };

  const handleDelete = async () => {
    handleDeleteClose();
    setLoading(true);
    await axios
      .delete(BASE_API_URL + `/crawls/${deleteId}`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        window.location.reload();
      });
    setLoading(false);
  };

  const handleRestart = async (crawl_id) => {
    setLoading(true);
    await axios
      .put(
        BASE_API_URL + `/crawls/rerun/${crawl_id}`,
        {},
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        window.location.reload();
      });
    setLoading(false);
  };

  const handleDeleteSchedule = async () => {
    handleDeleteScheduleClose();
    setLoading(true);
    await axios
      .delete(BASE_API_URL + `/scheduling/${deleteId}`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        window.location.reload();
      });
    setLoading(false);
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handlePerPageChange = (selectedPerPage) => {
    setPerPage(selectedPerPage);
    setPage(1); // Reset to the first page when perPage changes
  };

  const handleQuickAddSubmit = async () => {
    handleQuickAddClose();
    setLoading(true);
    var body = new FormData();
    body.append("title", titleField);
    body.append("base_id", base_id);
    body.append("doc_type", "text");
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/documents",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    });
    let doc_status = response.data.status;
    let doc_id = response.data.doc_id;
    let message = response.data.message;
    if (doc_status === false) {
      toast.error(message);
      setLoading(false);
      return;
    } else {
      toast.success(message);
    }
    body = new FormData();
    body.append("doc_id", doc_id);
    body.append("content", docText);
    await axios({
      method: "post",
      url: BASE_API_URL + "/documents/process",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    });
    window.location.reload();
  };

  const HandleShowImportModal = () => {
    setImportShow(true);
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/bases">All Knowledge Bases</Breadcrumb.Item>
            <Breadcrumb.Item active>{base.base_name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="title-row">
            <div>
              <div>
                <h6>Knowledge Base:</h6>
                <h2>{base["base_name"]}</h2>
                {/* {console.log(base["access"])} */}
                {/* <p>Access: {base["private"] ? "Private" : "Public"}</p> */}
                <Button
                  className="secondary-button base-settings"
                  onClick={handleShow}
                >
                  Edit Settings
                </Button>
              </div>
            </div>
            <div className="base-buttons">
              <Button className="secondary-button" onClick={handleQuickAddShow}>
                <img src={AddIconLight} alt="quick add document icon" />
                <span className="quick-text">Quick Add</span>
              </Button>
              <Button
                className="primary-button"
                onClick={HandleShowImportModal}
              >
                <img src={ImportIconLight} alt="import document icon" />
                <span>Import</span>
              </Button>
            </div>
          </div>
          {base["crawls"] && base["crawls"].length > 0 ? (
            <div className="crawls">
              <h3 className="base-section-title">
                Website Imports ({base["crawls"].length})
              </h3>
              <div className="base-table-container">
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Start URL(s)</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {base["crawls"].map((crawl, index) => (
                      <tr key={index} className="base-row">
                        <td>{crawl.crawl_name}</td>
                        <td>{crawl.seeds}</td>
                        <td>{crawl.status}</td>
                        <td>
                          <div className="action-panel">
                            <Link to={`/crawls/${crawl.crawl_id}`}>
                              <Button className="open-button">Open</Button>
                            </Link>
                            <Button
                              className="open-button"
                              onClick={() => handleRestart(crawl.crawl_id)}
                            >
                              Re-run
                            </Button>
                            <Button
                              className="open-button"
                              onClick={() => showDeleteModal(crawl.crawl_id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : null}
          {schedules && schedules.length > 0 ? (
            <div className="schedules">
              <h3 className="base-section-title">
                Sync Schedules ({schedules.length})
              </h3>
              <div className="base-table-container">
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Days Between Syncs</th>
                      <th>Last Sync</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {schedules.map((schedule, index) => (
                      <tr key={index} className="base-row">
                        <td>
                          {schedule.schedule_metadata.type
                            ? formatSyncType(schedule.schedule_metadata.type)
                            : ""}
                        </td>

                        <td>
                          {schedule.frequency_minutes
                            ? (schedule.frequency_minutes / 60 / 24).toFixed(0)
                            : "None"}
                        </td>
                        <td>
                          {schedule.last_run_timestamp
                            ? convertToLocalTime(schedule.last_run_timestamp)
                            : "None"}
                        </td>
                        <td>
                          <div>
                            <Button
                              className="open-button"
                              onClick={() =>
                                showDeleteScheduleModal(schedule.id)
                              }
                            >
                              Delete Sync Schedule
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : null}
          <h3 className="base-section-title">
            Documents ({base["doc_count"]})
          </h3>
          <DocumentRow documents={base.documents} />
          <PageHandler
            perPage={perPage}
            handlePerPageChange={handlePerPageChange}
            page={page}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Knowledge Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Knowledge Base Name</Form.Label>
                <InfoBadge info="Give your knowledge base a name that will help you remember what it's for."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                fieldRef={baseNameField}
                onChange={inputChange}
                value={nameField}
                autoFocus
                error={formErrors.text}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleClose}>
            Close
          </Button>
          <Button className="primary-button" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteShow} centered onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Crawl</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to permanently delete this crawl and all
            documents associated with it?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleDeleteClose}>
            Close
          </Button>
          <Button className="delete-button" onClick={handleDelete}>
            Delete Crawl
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={scheduleDeleteShow}
        centered
        onHide={handleDeleteScheduleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Import Sync</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to permanently delete this import sync
            schedule?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="secondary-button"
            onClick={handleDeleteScheduleClose}
          >
            Close
          </Button>
          <Button className="delete-button" onClick={handleDeleteSchedule}>
            Delete Import Sync
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={quickAddShow} centered onHide={handleQuickAddClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add To Knowledge Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Title/Question</Form.Label>
                <InfoBadge info="What is the heading or question that the text below relates to?"></InfoBadge>
              </div>
              <Form.Control
                type="input"
                fieldRef={quickAddTitle}
                onChange={docTitleChange}
                value={titleField}
                autoFocus
                error={formErrors.text}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="form-context">
                <Form.Label>Text:</Form.Label>
                <InfoBadge info="Add the content you would like to add to this knowledge base."></InfoBadge>
              </div>
              <Form.Control
                as="textarea"
                aria-label="With textarea"
                className="quick-add-text"
                value={docText}
                onChange={handleDocTextChange}
                onBlur={handleDocTextChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleQuickAddClose}>
            Cancel
          </Button>
          <Button className="primary-button" onClick={handleQuickAddSubmit}>
            Add to Knowledge Base
          </Button>
        </Modal.Footer>
      </Modal>
      <ImportModal
        show={importShow}
        handleClose={handleImportClose}
        linkState={{ base_id: base_id, base_name: base.base_name }}
      />
      <Toaster />
    </div>
  );
}
