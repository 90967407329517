import Pagination from "react-bootstrap/Pagination";
import Dropdown from "react-bootstrap/Dropdown";

import "./PageHandler.scss";

export default function PageHandler({
  perPage,
  handlePerPageChange,
  page,
  handlePageChange,
  totalPages,
}) {
  const renderPerPageDropdown = () => {
    const perPageOptions = [10, 20, 50, 100];

    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Per Page: {perPage}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {perPageOptions.map((option) => (
            <Dropdown.Item
              key={option}
              onClick={() => handlePerPageChange(option)}
            >
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderPagination = () => {
    const pageRangeToShow = 5; // Customize the number of pages to display around the current page

    // Calculate the start and end pages for pagination
    const startPage = Math.max(page - pageRangeToShow, 1);
    const endPage = Math.min(page + pageRangeToShow, totalPages);

    let items = [];
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Pagination>
        <Pagination.First
          disabled={page === 1}
          onClick={() => handlePageChange(1)}
        />
        <Pagination.Prev
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
        />
        {items}
        <Pagination.Next
          disabled={page === totalPages}
          onClick={() => handlePageChange(page + 1)}
        />
        <Pagination.Last
          disabled={page === totalPages}
          onClick={() => handlePageChange(totalPages)}
        />
      </Pagination>
    );
  };

  // Render the Pagination component with the appropriate number of pages
  // const renderPagination = () => {
  //   let items = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     items.push(
  //       <Pagination.Item
  //         key={i}
  //         active={i === page}
  //         onClick={() => handlePageChange(i)}
  //       >
  //         {i}
  //       </Pagination.Item>
  //     );
  //   }

  //   return (
  //     <Pagination>
  //       <Pagination.Prev
  //         disabled={page === 1}
  //         onClick={() => handlePageChange(page - 1)}
  //       />
  //       {items}
  //       <Pagination.Next
  //         disabled={page === totalPages}
  //         onClick={() => handlePageChange(page + 1)}
  //       />
  //     </Pagination>
  //   );
  // };

  return (
    <div className="pageHandler">
      {renderPagination()}
      {renderPerPageDropdown()}
    </div>
  );
}
