import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { Breadcrumb, Form } from "react-bootstrap";
import debounce from "lodash.debounce";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Userfront from "@userfront/react";
import { useWindowWidth } from "@react-hook/window-size";

import SaveBar from "../../components/SaveBar/SaveBar";
import Select from "../../components/Select/Select";
import Switch from "../../components/SwitchComponent/SwitchComponent";
import WidgetEmbedCode from "../../components/WidgetEmbedCode/WidgetEmbedCode";
import WidgetPreview from "../../components/WidgetPreview/WidgetPreview";

import "./WidgetConfiguration.scss";

// Current sent to load for logged in users on protected route "/widget/:widget_id"
export default function WidgetConfiguration() {
  const { widget_id } = useParams();
  // const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [currentWidget, setCurrentWidget] = useState("");
  const [widgetLoading, setWidgetLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isWidgetSaved, setIsWidgetSaved] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSaveBar, setShowSaveBar] = useState(false);

  // configurations values
  const [type, setType] = useState("");
  const [startState, setStartState] = useState("");
  const [font, setFont] = useState("");
  const [fontUrl, setFontUrl] = useState("");
  const [inputBackgroundColor, setInputBackgroundColor] = useState("");
  const [messagePanelBackgroundColor, setMessagePanelBackgroundColor] =
    useState("");
  const [answerBackgroundColor, setAnswerBackgroundColor] = useState("");
  const [answerTextColor, setAnswerTextColor] = useState("");
  const [suggestedQuestions, setSuggestedQuestions] = useState("");
  const [inputPlaceholderText, setInputPlaceholderText] = useState("");
  const [widgetHeight, setWidgetHeight] = useState("");
  const [widgetPosition, setWidgetPosition] = useState("");
  const [offsetX, setOffsetX] = useState("");
  const [offsetY, setOffsetY] = useState("");
  const [customCSSClasses, setCustomCSSClasses] = useState("");
  const [headEmbedCode, setHeadEmbedCode] = useState("");
  const [bodyEmbedCode, setBodyEmbedCode] = useState("");
  const [triggerBtnColor, setTriggerBtnColor] = useState("");
  const [btnIcon, setBtnIcon] = useState("");
  const [footerText, setFooterText] = useState("");
  const [infoText, setInfoText] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [headerTextColor, setHeaderTextColor] = useState("");
  const [headerColor, setHeaderColor] = useState("");
  const [linkBtnColor, setLinkBtnColor] = useState("");
  const [linkTextColor, setLinkTextColor] = useState("");
  const [placeholderTextColor, setPlaceholderTextColor] = useState("");
  const [inputTextColor, setInputTextColor] = useState("");
  const [questionTextColor, setQuestionTextColor] = useState("");
  const [headerIconColor, setHeaderIconColor] = useState("");
  const [inputIconColor, setInputIconColor] = useState("");
  const [showSnippets, setShowSnippets] = useState("");
  const [suggestedBgColor, setSuggestedBgColor] = useState("");
  const [suggestedTextColor, setSuggestedTextColor] = useState("");
  const [useBanner, setUseBanner] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [useTicket, setUseTicket] = useState("");
  const [explainerText, setExplainerText] = useState({});
  const [feedbackMessages, setFeedbackMessages] = useState({});
  const [iconHoverText, setIconHoverText] = useState({});
  const [ticketText, setTicketText] = useState({});
  const [handoffInfo, setHandoffInfo] = useState({});

  const windowWidth = useWindowWidth();

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/widgets/`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          const currWidget = response.data.find(
            (i) => i.widget_id === widget_id
          );

          if (currWidget) {
            setCurrentWidget(currWidget);
          }

          // setUserData(Userfront.user);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentWidget) {
      setWidgetLoading(true);
      (async () => {
        await axios
          .get(BASE_API_URL + `/widgets/${currentWidget.widget_id}`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setExplainerText(JSON.parse(response.data.explainer_text));
            setFeedbackMessages(JSON.parse(response.data.feedback_messages));
            setIconHoverText(JSON.parse(response.data.icon_hover_text));
            setTicketText(JSON.parse(response.data.ticket_text));
            setAnswerBackgroundColor(response.data.answer_bg_color);
            setAnswerTextColor(response.data.answer_text_color);
            setCustomCSSClasses(response.data.custom_css_classes);
            setFont(response.data.font);
            setInputBackgroundColor(response.data.input_bg_color);
            setInputPlaceholderText(response.data.input_placeholder_text);
            setMessagePanelBackgroundColor(
              response.data.message_panel_bg_color
            );
            setStartState(response.data.start_state);
            setSuggestedQuestions(
              response.data.suggested_questions_on.toLowerCase() === "true"
                ? "Yes"
                : "No"
            );
            setType(response.data.widget_type);
            setFontUrl(response.data.font_url);
            setHeadEmbedCode(
              `<script id="rightpage-widget-script" data-rightpage-widget-id='${currentWidget.widget_id}' async src='https://www.widget.rightpage.com/widget/index.js'></script>`
            );
            setBodyEmbedCode(
              !response.data.body_embed_code ||
                response.data.body_embed_code === "None" ||
                response.data.body_embed_code === ""
                ? `<div id='rightpage-widget' data-widget-id="${currentWidget.widget_id}"></div>
                data-type="floating" data-start-state="expanded" data-font="Inter"
                data-font-url="Inter:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,500;0,900;1,100;1,500;1,900&display=swap"
                data-height="700px" data-position="right" data-offsetX="20px" data-offsetY="20px" data-input-bg=""
                data-question-panel-bg="" data-answer-panel-bg="#F7F8FA" data-answer-text-color="#000" data-suggested-questions="yes"
                data-input-placeholder="Question? Get an instant answer here..." data-trigger-btn-color="#6649fc"
                data-trigger-btn-icon="message_light" data-info-text="Ask a question and a personalized answer will be generated."
                data-header-text="NewMouth Assistant" data-header-text-color="#F7F8FA" data-header-color="#6649fc" data-link-btn-color="#6649fc"
                data-link-text-color="#fff" data-placeholder-text-color="" data-input-text-color="" data-question-text-color="#000"
                data-footer-text='Instant answers powered by <a target="_blank" href="http://127.0.0.1:4000" rel="noreferrer">RightPage.</a>'
                data-header-icon-color="light" data-input-icon-color="dark" data-only-links="yes" data-suggested-bg-color="" data-suggested-text-color="" data-avatar-url="" data-custom-css=""/>`
                : response.data.body_embed_code
            );
            setWidgetHeight(response.data.expanded_height);
            setWidgetPosition(response.data.position);
            setOffsetX(response.data.offset_x);
            setOffsetY(response.data.offset_y);
            setTriggerBtnColor(response.data.trigger_button_color);
            setBtnIcon(response.data.button_icon);
            setFooterText(response.data.footer_text);
            setInfoText(response.data.info_text);
            setHeaderText(response.data.header_text);
            setHeaderTextColor(response.data.header_text_color);
            setHeaderColor(response.data.header_color);
            setLinkBtnColor(response.data.link_button_color);
            setLinkTextColor(response.data.link_text_color);
            setPlaceholderTextColor(response.data.placeholder_text_color);
            setInputTextColor(response.data.input_text_color);
            setQuestionTextColor(response.data.question_text_color);
            setHeaderIconColor(response.data.header_icon_color);
            setInputIconColor(response.data.input_icon_color);
            setShowSnippets(response.data.show_snippets ? "Yes" : "No");
            setSuggestedBgColor(response.data.suggested_bg_color);
            setSuggestedTextColor(response.data.suggested_text_color);
            setBannerText(response.data.banner_text);
            setUseBanner(response.data.use_banner === "yes" ? "Yes" : "No");
            setAvatarUrl(response.data.avatar_url);
            setWidgetLoading(false);
            setUseTicket(response.data.use_ticket === "no" ? "No" : "Yes");
            setHandoffInfo(JSON.parse(response.data.handoff_info));
          });
      })();
    }
  }, [BASE_API_URL, currentWidget]);

  const types = ["Standard", "Floating"];
  // const startStates = ["Expanded", "Compact"];
  const suggestedQuestionOptions = ["Yes", "No"];
  const positions = ["Left", "Right"];
  // const showSnippetOptions = ["Yes", "No"];
  const buttonIcons = [
    "Brain Dark",
    "Brain Light",
    "Message Dark",
    "Message Light",
  ];
  const useBannerOptions = ["Yes", "No"];
  const useTicketOptions = ["Yes", "No"];
  const useHandOffOptions = ["Yes", "No"];
  const updateWidget = (_widgetId, _data, copyToClip) => {
    setUpdateLoading(true);
    let data = {
      head_embed_code: headEmbedCode,
      body_embed_code: bodyEmbedCode,
      widget_title: currentWidget.widget_title,
      settings: {
        widget_type: type,
        start_state: startState,
        font: font,
        font_url: fontUrl,
        input_bg_color: inputBackgroundColor,
        message_panel_bg_color: messagePanelBackgroundColor,
        answer_bg_color: answerBackgroundColor,
        answer_text_color: answerTextColor,
        suggested_questions_on:
          suggestedQuestions.toLowerCase() === "yes" ? true : false,
        input_placeholder_text: inputPlaceholderText,
        custom_css_classes: customCSSClasses,
        expanded_height: widgetHeight,
        position: widgetPosition,
        offset_x: offsetX,
        offset_y: offsetY,
        trigger_button_color: triggerBtnColor,
        button_icon: btnIcon,
        footer_text: footerText,
        info_text: infoText,
        header_text: headerText,
        header_text_color: headerTextColor,
        header_color: headerColor,
        link_button_color: linkBtnColor,
        link_text_color: linkTextColor,
        placeholder_text_color: placeholderTextColor,
        input_text_color: inputTextColor,
        question_text_color: questionTextColor,
        header_icon_color: headerIconColor,
        input_icon_color: inputIconColor,
        show_snippets: showSnippets === "Yes" ? true : false,
        suggested_bg_color: suggestedBgColor,
        suggested_text_color: suggestedTextColor,
        banner_text: bannerText,
        use_banner: useBanner === "Yes" ? "yes" : "no",
        avatar_url: avatarUrl,
        use_ticket: useTicket === "Yes" ? "yes" : "no",
        explainer_text: JSON.stringify(explainerText),
        feedback_messages: JSON.stringify(feedbackMessages),
        icon_hover_text: JSON.stringify(iconHoverText),
        ticket_text: JSON.stringify(ticketText),
        handoff_info: JSON.stringify(handoffInfo),
      },
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${BASE_API_URL}/widgets/${_widgetId ? _widgetId : widget_id}`,
      headers: {
        Authorization: `Bearer ${Userfront.tokens.accessToken}`,
        "Content-Type": "application/json",
      },
      data: _data ?? data,
    };

    axios
      .request(config)
      .then(() => {
        setIsWidgetSaved(true);
        setUpdateLoading(false);

        if (copyToClip) {
          setShowConfirmModal(false);

          if (copyToClip === "body") {
            navigator.clipboard.writeText(bodyEmbedCode);
            toast.success("Body Embed Code copied to clipboard");
          } else {
            navigator.clipboard.writeText(headEmbedCode);
            toast.success("Head Embed Code copied to clipboard");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setUpdateLoading(false);
      });
    toast.success("Widget saved successfully");
    setShowSaveBar(false);
  };

  const handleDiscardChanges = () => {
    window.location.reload();
  };

  const handleSetTicketText = (value, key) => {
    setTicketText({
      ...ticketText,
      [key]: value,
    });
    setShowSaveBar(true);
  };

  const handleIconHoverTextChange = (value, key) => {
    setIconHoverText({
      ...iconHoverText,
      [key]: value,
    });
    setShowSaveBar(true);
  };

  const handleExplainerTextChange = (value, key) => {
    setExplainerText({
      ...explainerText,
      [key]: value,
    });
    setShowSaveBar(true);
  };

  const handleFeedbackMessagesChange = (value, key) => {
    setFeedbackMessages({
      ...feedbackMessages,
      [key]: value,
    });
    setShowSaveBar(true);
  };

  const handleHandoffInfoChange = (value, key) => {
    setHandoffInfo({
      ...handoffInfo,
      [key]: value,
    });
    setShowSaveBar(true);
  };

  const setUseHandoff = (value) => {
    setHandoffInfo({
      ...handoffInfo,
      active: value.toLowerCase(),
    });
    setShowSaveBar(true);
  };

  return loading ? (
    <div className="Centered-Form">
      <Spinner animation="border" className="loading-spinner" />
    </div>
  ) : (
    <div className="widget-configuration">
      {showSaveBar ? (
        <SaveBar
          onSave={() => updateWidget()}
          onDiscard={handleDiscardChanges}
        />
      ) : null}
      <Breadcrumb>
        <Breadcrumb.Item href="/webwidgets">Web Widgets</Breadcrumb.Item>
        <Breadcrumb.Item active>
          {currentWidget ? currentWidget.widget_title : ""} Configuration
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="widget-configuration-head">
        <h6 className="heading">
          {currentWidget ? currentWidget.widget_title : ""} Configuration
        </h6>
      </div>
      {currentWidget &&
        (widgetLoading ? (
          <div className="widget-loading">
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="type-preview-wrap">
            <div>
              {console.log(ticketText)}
              <Accordion flush={true} alwaysOpen className="accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Display Settings</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Select
                      label="Type"
                      options={types}
                      value={type}
                      setValue={setType}
                      setSave={setShowSaveBar}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Font Settings</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Font</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={font}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setFont(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Font URL</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={fontUrl}
                        onChange={(e) =>
                          debounce(setFontUrl(e.target.value), 4000)
                        }
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Header Settings</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Info Text</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={infoText}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setInfoText(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Avatar Image URL
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={avatarUrl}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setAvatarUrl(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Header Text</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={headerText}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setHeaderText(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Header Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={headerTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setHeaderTextColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Header Color</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={headerColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setHeaderColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <Switch
                      label="Header Icon Color"
                      startLabel="Light"
                      endLabel="Dark"
                      value={headerIconColor}
                      setValue={setHeaderIconColor}
                      setSave={setShowSaveBar}
                    />
                    <Select
                      label="Use Ticket Creation Integration"
                      options={useTicketOptions}
                      value={useTicket}
                      setValue={setUseTicket}
                      setSave={setShowSaveBar}
                    />

                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Refresh Button Hover Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          iconHoverText.refresh ? iconHoverText.refresh : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleIconHoverTextChange(
                              e.target.value,
                              "refresh"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Ticket Button Hover Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={iconHoverText.ticket ? iconHoverText.ticket : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleIconHoverTextChange(e.target.value, "ticket"),
                            4000
                          );
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">
                      Input Field Settings
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Input Background Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={inputBackgroundColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            setInputBackgroundColor(e.target.value),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Input Placeholder Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={inputPlaceholderText}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            setInputPlaceholderText(e.target.value),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Placeholder Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={placeholderTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            setPlaceholderTextColor(e.target.value),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Input Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={inputTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setInputTextColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <Switch
                      label="Input Icon Color"
                      startLabel="Light"
                      endLabel="Dark"
                      value={inputIconColor}
                      setValue={setInputIconColor}
                      setSave={setShowSaveBar}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Question Settings</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Select
                      label="Suggested Questions on?"
                      options={suggestedQuestionOptions}
                      value={suggestedQuestions}
                      setValue={setSuggestedQuestions}
                      setSave={setShowSaveBar}
                    />
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Message Panel Background Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={messagePanelBackgroundColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            setMessagePanelBackgroundColor(e.target.value),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Question Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={questionTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setQuestionTextColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Suggested Question Background Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={suggestedBgColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setSuggestedBgColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Suggested Question Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={suggestedTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setSuggestedTextColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Answer Settings</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Answer Background Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={answerBackgroundColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            setAnswerBackgroundColor(e.target.value),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Answer Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={answerTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setAnswerTextColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Link Button Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={linkBtnColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setLinkBtnColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Link Text Color
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={linkTextColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setLinkTextColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Positive Feedback Message
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          feedbackMessages.positive
                            ? feedbackMessages.positive
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleFeedbackMessagesChange(
                              e.target.value,
                              "positive"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Negative Feedback Message
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          feedbackMessages.negative
                            ? feedbackMessages.negative
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleFeedbackMessagesChange(
                              e.target.value,
                              "negative"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    {/* <Select
                      label="Show Source Snippets"
                      options={showSnippetOptions}
                      value={showSnippets}
                      setValue={setShowSnippets}
                      setSave={setShowSaveBar}
                    /> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">
                      Floating-Specific Settings
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Expanded Height
                      </p>
                      <Form.Control
                        disabled={type !== "Floating"}
                        className="form-input"
                        type="text"
                        value={widgetHeight}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setWidgetHeight(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <Select
                      label="Position"
                      options={positions}
                      value={widgetPosition}
                      setValue={setWidgetPosition}
                      disabled={type !== "Floating"}
                      setSave={setShowSaveBar}
                    />
                    <div className="configuration-item">
                      <p className="configuration-item-label">Offset X</p>
                      <Form.Control
                        disabled={type !== "Floating"}
                        className="form-input"
                        type="text"
                        value={offsetX}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setOffsetX(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Offset Y</p>
                      <Form.Control
                        disabled={type !== "Floating"}
                        className="form-input"
                        type="text"
                        value={offsetY}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setOffsetY(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Trigger Button Color
                      </p>
                      <Form.Control
                        disabled={type !== "Floating"}
                        className="form-input"
                        type="text"
                        value={triggerBtnColor}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setTriggerBtnColor(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <Select
                      label="Button Icon"
                      options={buttonIcons}
                      value={btnIcon}
                      setValue={setBtnIcon}
                      disabled={type !== "Floating"}
                      setSave={setShowSaveBar}
                    />
                    <Select
                      label="Use Banner"
                      options={useBannerOptions}
                      value={useBanner}
                      setValue={setUseBanner}
                      disabled={type !== "Floating"}
                      setSave={setShowSaveBar}
                    />
                    <div className="configuration-item">
                      <p className="configuration-item-label">Banner Text</p>
                      <Form.Control
                        disabled={type !== "Floating"}
                        className="form-input"
                        type="text"
                        value={bannerText}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setBannerText(e.target.value), 4000);
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">
                      Agent Handoff Settings
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      {/* <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          handoffInfo.active && handoffInfo.active === "yes"
                            ? "yes"
                            : "no"
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleHandoffInfoChange(e.target.value, "active"),
                            4000
                          );
                        }}
                      /> */}
                      <Select
                        label="Allow Live Handoff"
                        options={useHandOffOptions}
                        value={
                          handoffInfo.active && handoffInfo.active === "yes"
                            ? "Yes"
                            : "No"
                        }
                        setValue={setUseHandoff}
                        setSave={setShowSaveBar}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Zendesk Web Widget Key
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          handoffInfo.zendeskWebWidgetKey
                            ? handoffInfo.zendeskWebWidgetKey
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleHandoffInfoChange(
                              e.target.value,
                              "zendeskWebWidgetKey"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Live Chat Window Url
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          handoffInfo.newWindowUrl
                            ? handoffInfo.newWindowUrl
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleHandoffInfoChange(
                              e.target.value,
                              "newWindowUrl"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Zendesk Custom Field ID
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          handoffInfo.customFieldId
                            ? handoffInfo.customFieldId
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleHandoffInfoChange(
                              e.target.value,
                              "customFieldId"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Handoff Button Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          handoffInfo.buttonText ? handoffInfo.buttonText : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleHandoffInfoChange(
                              e.target.value,
                              "buttonText"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Ticket Form Text</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Form Title</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.title ? ticketText.title : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "title"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Name Field Placeholder
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.name ? ticketText.name : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "name"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Email Field Placeholder
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.email ? ticketText.email : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "email"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Subject Field Placeholder
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.subject ? ticketText.subject : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "subject"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Message Field Placeholder
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.message ? ticketText.message : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "message"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        File Field Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.file ? ticketText.file : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "file"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Submit Button Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={ticketText.submit ? ticketText.submit : ""}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(e.target.value, "submit"),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Submit Message Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          ticketText.submit_message
                            ? ticketText.submit_message
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleSetTicketText(
                              e.target.value,
                              "submit_message"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header className="accordian-header">
                    <span className="accordian-header">Advanced Settings</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="configuration-item">
                      <p className="configuration-item-label">Footer Text</p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={footerText}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setFooterText(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Custom CSS classes
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={customCSSClasses}
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(setCustomCSSClasses(e.target.value), 4000);
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Header Title Preamble Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          explainerText.title_lead
                            ? explainerText.title_lead
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleExplainerTextChange(
                              e.target.value,
                              "title_lead"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Suggested Topics Preamble Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          explainerText.suggested_topics
                            ? explainerText.suggested_topics
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleExplainerTextChange(
                              e.target.value,
                              "suggested_topics"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Explore Further Preamble Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          explainerText.explore_further
                            ? explainerText.explore_further
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleExplainerTextChange(
                              e.target.value,
                              "explore_further"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                    <div className="configuration-item">
                      <p className="configuration-item-label">
                        Follow Up Input Placeholder Text
                      </p>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          explainerText.follow_up_placeholder
                            ? explainerText.follow_up_placeholder
                            : ""
                        }
                        onChange={(e) => {
                          setShowSaveBar(true);
                          debounce(
                            handleExplainerTextChange(
                              e.target.value,
                              "follow_up_placeholder"
                            ),
                            4000
                          );
                        }}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="embed-mobile">
                <WidgetEmbedCode
                  currentWidget={currentWidget}
                  startState={startState}
                  font={font}
                  fontUrl={fontUrl}
                  inputBackgroundColor={inputBackgroundColor}
                  messagePanelBackgroundColor={messagePanelBackgroundColor}
                  answerBackgroundColor={answerBackgroundColor}
                  answerTextColor={answerTextColor}
                  suggestedQuestions={suggestedQuestions}
                  inputPlaceholderText={inputPlaceholderText}
                  updateWidget={updateWidget}
                  updateLoading={updateLoading}
                  headEmbedCode={headEmbedCode}
                  setHeadEmbedCode={setHeadEmbedCode}
                  bodyEmbedCode={bodyEmbedCode}
                  setBodyEmbedCode={setBodyEmbedCode}
                  isWidgetSaved={isWidgetSaved}
                  setIsWidgetSaved={setIsWidgetSaved}
                  triggerBtnColor={triggerBtnColor}
                  btnIcon={btnIcon}
                  footerText={footerText}
                  infoText={infoText}
                  headerText={headerText}
                  headerTextColor={headerTextColor}
                  headerColor={headerColor}
                  linkBtnColor={linkBtnColor}
                  linkTextColor={linkTextColor}
                  placeholderTextColor={placeholderTextColor}
                  inputTextColor={inputTextColor}
                  questionTextColor={questionTextColor}
                  showConfirmModal={showConfirmModal}
                  setShowConfirmModal={setShowConfirmModal}
                  headerIconColor={headerIconColor}
                  inputIconColor={inputIconColor}
                  showSnippets={showSnippets}
                  suggestedBgColor={suggestedBgColor}
                  suggestedTextColor={suggestedTextColor}
                  customCSSClasses={customCSSClasses}
                  useBanner={useBanner}
                  bannerText={bannerText}
                  avatarUrl={avatarUrl}
                  useTicket={useTicket}
                />
              </div>
            </div>
            {windowWidth > 900 && (
              <div className="preview">
                <WidgetPreview
                  widgetId={widget_id}
                  type={type}
                  startState={startState}
                  font={font}
                  fontUrl={fontUrl}
                  inputBackgroundColor={inputBackgroundColor}
                  messagePanelBackgroundColor={messagePanelBackgroundColor}
                  answerBackgroundColor={answerBackgroundColor}
                  answerTextColor={answerTextColor}
                  suggestedQuestions={suggestedQuestions}
                  inputPlaceholderText={inputPlaceholderText}
                  widgetHeight={widgetHeight}
                  widgetPosition={widgetPosition}
                  offsetX={offsetX}
                  offsetY={offsetY}
                  triggerBtnColor={triggerBtnColor}
                  btnIcon={btnIcon}
                  footerText={footerText}
                  infoText={infoText}
                  headerText={headerText}
                  headerTextColor={headerTextColor}
                  headerColor={headerColor}
                  linkBtnColor={linkBtnColor}
                  linkTextColor={linkTextColor}
                  placeholderTextColor={placeholderTextColor}
                  inputTextColor={inputTextColor}
                  questionTextColor={questionTextColor}
                  headerIconColor={headerIconColor}
                  inputIconColor={inputIconColor}
                  showSnippets={showSnippets}
                  suggestedBgColor={suggestedBgColor}
                  suggestedTextColor={suggestedTextColor}
                  customCSSClasses={customCSSClasses}
                  useBanner={useBanner}
                  bannerText={bannerText}
                  avatarUrl={avatarUrl}
                  useTicket={useTicket}
                />
                <WidgetEmbedCode
                  type={type}
                  currentWidget={currentWidget}
                  startState={startState}
                  font={font}
                  fontUrl={fontUrl}
                  inputBackgroundColor={inputBackgroundColor}
                  messagePanelBackgroundColor={messagePanelBackgroundColor}
                  answerBackgroundColor={answerBackgroundColor}
                  answerTextColor={answerTextColor}
                  suggestedQuestions={suggestedQuestions}
                  inputPlaceholderText={inputPlaceholderText}
                  updateWidget={updateWidget}
                  updateLoading={updateLoading}
                  headEmbedCode={headEmbedCode}
                  setHeadEmbedCode={setHeadEmbedCode}
                  bodyEmbedCode={bodyEmbedCode}
                  setBodyEmbedCode={setBodyEmbedCode}
                  widgetHeight={widgetHeight}
                  widgetPosition={widgetPosition}
                  offsetX={offsetX}
                  offsetY={offsetY}
                  isWidgetSaved={isWidgetSaved}
                  setIsWidgetSaved={setIsWidgetSaved}
                  triggerBtnColor={triggerBtnColor}
                  btnIcon={btnIcon}
                  footerText={footerText}
                  infoText={infoText}
                  headerText={headerText}
                  headerTextColor={headerTextColor}
                  headerColor={headerColor}
                  linkBtnColor={linkBtnColor}
                  linkTextColor={linkTextColor}
                  placeholderTextColor={placeholderTextColor}
                  inputTextColor={inputTextColor}
                  questionTextColor={questionTextColor}
                  showConfirmModal={showConfirmModal}
                  setShowConfirmModal={setShowConfirmModal}
                  headerIconColor={headerIconColor}
                  inputIconColor={inputIconColor}
                  showSnippets={showSnippets}
                  suggestedBgColor={suggestedBgColor}
                  suggestedTextColor={suggestedTextColor}
                  customCSSClasses={customCSSClasses}
                  useBanner={useBanner}
                  bannerText={bannerText}
                  avatarUrl={avatarUrl}
                  useTicket={useTicket}
                />
              </div>
            )}
          </div>
        ))}
      <Toaster />
    </div>
  );
}
