import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Stack,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { useRef, useEffect, useState } from "react";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";

import { get_answer } from "../../services/ChatService";

import "./AgentChatPanel.scss";

const messageType = {
  answer: "answer",
  question: "question",
};

export default function ChatPanel({
  session_type,
  id,
  default_questions,
  show_links = true,
  show_answers = true,
  speed = "fast",
  answer_length = 150,
}) {
  const inputRef = useRef();
  const chatWrapperRef = useRef();

  const [messages, setMessages] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [sessionId, setSessionId] = useState("");
  // const [showSources, setShowSources] = useState(true);
  const [snippet, setSnippet] = useState([105, 105, 105]);

  const getAnswer = async () => {
    if (onRequest) return;

    const newMessages = [
      ...messages,
      {
        type: messageType.question,
        content: question,
        sources: [
          { SectionNum: "None", doc_id: "2023_02_26_ee66069da5e8971d2aeeg" },
        ],
      },
    ];

    setMessages(newMessages);
    setQuestion("");
    setOnRequest(true);
    // console.log("id: " + id);
    // console.log("search_type: " + session_type);
    const { response, err } = await get_answer({
      question: question,
      id_constraint: id,
      session_type: session_type,
      show_answers: show_answers,
      show_links: show_links,
      session_id: sessionId,
      speed: speed,
    });
    // console.log(response);
    if (response) {
      setMessages([
        ...newMessages,
        {
          type: messageType.answer,
          content: response.data.answer,
          sources: response.data.sources,
        },
      ]);
      setSessionId(response.data.session_id);
      // setShowSources(response.data.sources.length > 0 ? true : false);
      setOnRequest(false);
    }
    if (err) {
      toast.error(err.message);
      setOnRequest(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) {
      getAnswer();
    }
  };

  const onSnippetClick = (index) => {
    if (snippet[index] === 1000) {
      const temp = [...snippet];
      temp[index] = 105;
      setSnippet(temp);
    } else {
      const temp = [...snippet];
      temp[index] = 1000;
      setSnippet(temp);
    }
  };

  const handleQuestionClick = (index) => {
    setQuestion(default_questions[index].trim());
  };

  useEffect(() => {
    setTimeout(() => {
      chatWrapperRef.current.addEventListener("DOMNodeInserted", (e) => {
        e.currentTarget.scroll({
          top: e.currentTarget.scrollHeight,
          behavior: "smooth",
        });
      });
    }, 200);
  }, []);

  return (
    <Stack className="collection-chat-panel-container">
      <Box ref={chatWrapperRef} className="message-container">
        <Box className="question-container">
          {messages.length === 0 && Array.isArray(default_questions) ? (
            <div>
              {default_questions.length > 0 ? (
                <h5 className="h5-suggested-questions">Suggested Topics:</h5>
              ) : null}
              {default_questions.map((item, index) => (
                <Box
                  className="question"
                  key={index}
                  padding={1}
                  onClick={() => {
                    handleQuestionClick(index);
                  }}
                >
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeHighlight]}
                  >
                    {item}
                  </ReactMarkdown>
                </Box>
              ))}
            </div>
          ) : (
            <></>
          )}
        </Box>
        <Box className="inner-message-container">
          {/*Displays prior messages in conversation */}
          {messages.map((item, index) => (
            <Box key={index} padding={1}>
              <Box>
                {index === messages.length - 1 ? (
                  item.type === messageType.answer ? (
                    <div className="answer">
                      <ReactMarkdown>{item.content}</ReactMarkdown>
                      {item.sources.map((source, index) => (
                        <div key={index}>
                          <hr></hr>
                          <div className="evidence-section">
                            <div className="evidence-text">
                              <h6>
                                Document Title:{" "}
                                {source.doc_title || source.title}, Section:{" "}
                                {source.SectionNum}
                              </h6>
                              <p>
                                <em>
                                  {source.SectionCleanContent.substr(
                                    0,
                                    snippet[2]
                                  )}
                                  ...{" "}
                                  <span
                                    className="expand-snippet"
                                    onClick={() => onSnippetClick(2)}
                                  >
                                    (
                                    {snippet[2] === 1000
                                      ? "view less"
                                      : "view more"}
                                    )
                                  </span>
                                </em>
                              </p>
                            </div>
                            <Link
                              to={
                                source.url
                                  ? source.url
                                  : "/doc/view/" +
                                    source.doc_id +
                                    "#" +
                                    source.doc_id +
                                    source.SectionNum
                              }
                              target="_blank"
                              key={uuidv4()}
                            >
                              <Button
                                variant="secondary"
                                className="source-button"
                                key={uuidv4()}
                              >
                                Open Source
                              </Button>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="message">{item.content}</div>
                  )
                ) : item.type === messageType.question ? (
                  <div className="message">{item.content}</div>
                ) : (
                  <div className="answer">
                    <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
                      {item.content}
                    </ReactMarkdown>
                    {item.sources.map((source, index) => (
                      <div key={index}>
                        <hr></hr>
                        <div className="evidence-section">
                          <div className="evidence-text">
                            <h6>
                              Document Title: {source.doc_title || source.title}
                              , Section: {source.SectionNum}
                            </h6>
                            <p>
                              <em>
                                {source.SectionCleanContent.substr(
                                  0,
                                  snippet[2]
                                )}
                                ...{" "}
                                <span
                                  className="expand-snippet"
                                  onClick={() => onSnippetClick(2)}
                                >
                                  (
                                  {snippet[2] === 1000
                                    ? "view less"
                                    : "view more"}
                                  )
                                </span>
                              </em>
                            </p>
                          </div>
                          <Link
                            to={
                              source.url
                                ? source.url
                                : "/doc/view/" +
                                  source.doc_id +
                                  "#" +
                                  source.doc_id +
                                  source.SectionNum
                            }
                            target="_blank"
                            key={uuidv4()}
                          >
                            <Button
                              variant="secondary"
                              className="source-button"
                              key={uuidv4()}
                            >
                              Open Source
                            </Button>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Stack
        className="input-container"
        width="100%"
        alignItems="center"
        justifyContent="center"
        borderTop="1px solid #2c2c2c"
        bgcolor="#000"
        zIndex={3}
      >
        {/* Input box */}
        <Box className="input-box">
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              inputRef={inputRef}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              endAdornment={
                onRequest ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <SendOutlinedIcon onClick={getAnswer} className="send-icon" />
                )
              }
              autoFocus
              multiline
              disabled={onRequest}
              onKeyUp={onEnterPress}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask something..."
            />
          </FormControl>
        </Box>
      </Stack>
    </Stack>
  );
}

// function TextComponent({ content }) {
//   let formattedString = content ? content : "";
//   const finalString = formattedString.split("\n").map((line, index) => (
//     <React.Fragment key={index.toString()}>
//       {line[0] === "-" ? (
//         <ul>
//           <li
//             style={{
//               marginLeft: "20px",
//               listStylePosition: "outside",
//               lineHeight: "115%",
//             }}
//           >
//             {line.slice(2)}
//           </li>
//         </ul>
//       ) : (
//         <p>{line}</p>
//       )}
//     </React.Fragment>
//   ));
//   console.log(formattedString);
//   return <div>{finalString}</div>;
// }
