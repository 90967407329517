// ImportModal.js
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ImportOption from "./ImportOption/ImportOption";

import "./ImportModal.scss";

import DocumentImportIcon from "../../assets/ImportIcons/DocumentImportIcon.png";
import NotionImportIcon from "../../assets/ImportIcons/NotionImportIcon.png";
import SlackImportIcon from "../../assets/ImportIcons/SlackImportIcon.png";
import WebsiteImportIcon from "../../assets/ImportIcons/WebsiteImportIcon.png";
import YouTubeIcon from "../../assets/ImportIcons/YouTubeImportIcon.png";
import ZendeskIcon from "../../assets/ImportIcons/ZendeskImportIcon.png";
import ConfluenceImportIcon from "../../assets/ImportIcons/ConfluenceImportIcon.png";

function ImportModal({ show, handleClose, linkState }) {
  const importOptions = [
    {
      link: "/tickets/new",
      linkState: linkState,
      icon: ZendeskIcon,
      name: "Zendesk Tickets",
    },
    {
      link: "/articles/new",
      linkState: linkState,
      icon: ZendeskIcon,
      name: "Zendesk Articles",
    },
    {
      link: "/macros/import",
      linkState: linkState,
      icon: ZendeskIcon,
      name: "Zendesk Macros",
    },
    {
      link: "/crawls/new",
      linkState: linkState,
      icon: WebsiteImportIcon,
      name: "Import Website",
    },
    {
      link: "/confluence/new",
      linkState: linkState,
      icon: ConfluenceImportIcon,
      name: "Confluence",
    },
    {
      link: "/notion/sync",
      linkState: linkState,
      icon: NotionImportIcon,
      name: "Notion",
    },
    {
      link: "/slack/import",
      linkState: linkState,
      icon: SlackImportIcon,
      name: "Slack Messages",
    },
    {
      link: "/youtube/import",
      linkState: linkState,
      icon: YouTubeIcon,
      name: "YouTube",
    },
    {
      link: "/bases/doc/new",
      linkState: linkState,
      icon: DocumentImportIcon,
      name: "Individual Files",
    },
    //...
    // add all the other options similarly
  ];

  return (
    <Modal show={show} onHide={handleClose} className="import-modal">
      <Modal.Header closeButton>
        <Modal.Title>Import Options</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-wrap align-items-center justify-content-center import-options-grid">
        {importOptions.map((option, index) => (
          <div className="import-option-column" key={index}>
            <ImportOption {...option} />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button className="secondary-button" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportModal;
