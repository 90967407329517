import { Link } from "react-router-dom";
import UserFront from "@userfront/react";

UserFront.init("7n87xwyb");

const CreateAccountForm = UserFront.build({
  toolId: "dkkrakd",
});

export default function SignupForm() {
  return (
    <div className="Centered-Form">
      <img
        src={require("../../assets/RightPage-Logo-Dark.png")}
        alt="RightPage Logo"
        className="center-logo"
      />
      <CreateAccountForm />
      <br />
      <Link to="/login" className="signup-link">
        Log in
      </Link>
    </div>
  );
}
