import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DayScheduler from "./DayScheduler/DayScheduler";
import TimeZoneSelector from "./TimeZoneSelector/TimeZoneSelector";

import "./Scheduler.scss";
const Scheduler = ({ bot, handleUpdate }) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState("UTC");
  const [timeData, setTimeData] = useState({});

  useEffect(() => {
    bot.handoff_settings?.timezone
      ? setSelectedTimeZone(bot.handoff_settings.timezone)
      : setSelectedTimeZone("UTC");
    bot.handoff_settings?.schedule
      ? setTimeData(bot.handoff_settings.schedule)
      : setTimeData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
    console.log(e.target.value);
    const temp = {
      ...bot.handoff_settings,
      timezone: e.target.value,
    };
    handleUpdate("handoff_settings", temp);
  };

  const handleTimeChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);
    const temp = {
      ...timeData,
      [e.target.name]: e.target.value,
    };
    setTimeData(temp);
    console.log(e.target.name, e.target.value);
    const tempBot = {
      ...bot.handoff_settings,
      schedule: temp,
    };
    console.log("Handoff settings", tempBot);
    handleUpdate("handoff_settings", tempBot);
  };

  return (
    <Container className="scheduler-container">
      <h4 className="form-section-title">
        Configure Live Agent Handoff Schedule
      </h4>
      <TimeZoneSelector
        selectedTimeZone={selectedTimeZone}
        handleTimeZoneChange={handleTimeZoneChange}
      />
      <div>
        {days.map((day) => (
          <DayScheduler
            key={day}
            day={day}
            timeData={timeData}
            handleTimeChange={handleTimeChange}
          />
        ))}
      </div>
    </Container>
  );
};

export default Scheduler;
