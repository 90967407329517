import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { useLocation, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./Macros.scss";

export default function Macros() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [activeBase, setActiveBase] = useState();
  const [bases, setBases] = useState();
  const [loading, setLoading] = useState(true);
  // const [ticketIntegrationMethod, setTicketIntegrationMethod] =
  //   useState("none");
  const [ticketIntegrationMethods, setTicketIntegrationMethods] = useState([]);
  const [macroSyncInterval, setMacroSyncInterval] = useState("daily");
  let currentDate = new Date();
  let startingDate = new Date();
  startingDate.setDate(currentDate.getDate() - 365);

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [inactive, setInactive] = useState(false);

  const [macroActive, setMacroActive] = useState(true);
  const [inclusionPhrases, setInclusionPhrases] = useState([]);
  const [exclusionPhrases, setExclusionPhrases] = useState([]);

  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      try {
        await axios
          .get(BASE_API_URL + `/bases`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            const temp_bases = response.data;
            temp_bases.forEach((base, i) => {
              if (base.base_id === base_id) {
                temp_bases.splice(i, 1);
                temp_bases.unshift(base);
              }
            });
            setBases(temp_bases);
            setActiveBase(temp_bases[0].base_id);
            // console.log(response);
          });
        await axios
          .get(BASE_API_URL + `/integrations/`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            // console.log(response.data[0]);
            const zendesk_integration = response.data.find(
              (item) => item.type === "integrations_zendesk"
            );
            setTicketIntegrationMethods([zendesk_integration]);
            if (zendesk_integration.enabled === false) {
              toast.error("Zendesk integration is not enabled.");
              setLoading(false);
              // console.log("setting inactive");
              setInactive(true);
              return;
              // Break out of the function
            } else {
              // setTicketIntegrationMethod(
              //   response.data[0].type.split("_")[1]
              //     ? response.data[0].type.split("_")[1]
              //     : "none"
              // );
            }
          });
        await axios
          .get(BASE_API_URL + `/integrations/integrations_zendesk/brands`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            const temp_brands = response.data;
            // console.log("brands:", temp_brands);
            if (temp_brands.error) {
              toast.error("An error occurred while fetching brands.");
              toast.error(temp_brands.error);
              setBrands([]);
              setBrand({ brand: "none" });
              setLoading(false);
            } else {
              // console.log(temp_brands);
              setBrands(temp_brands.brands);
              setBrand(temp_brands.brands[0]);
            }
          });
        await axios
          .get(
            BASE_API_URL +
              `/integrations/integrations_zendesk/macros/categories`,
            {
              headers: {
                authorization: `Bearer ${Userfront.tokens.accessToken}`,
              },
              params: { domain: brand ? brand.subdomain : null },
            }
          )
          .then((response) => {
            let temp_categories = response.data;
            // console.log("categories:", temp_categories);
            temp_categories.unshift("All");
            setCategories(temp_categories);
            setCategory(temp_categories[0]);
            setLoading(false);
          });
      } catch (error) {
        setInactive(true);
        console.log(error);
      }
      // console.log("base id:", base_id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!brand) return;
    (async () => {
      await axios
        .get(
          BASE_API_URL + `/integrations/integrations_zendesk/macros/categories`,
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: { domain: brand.subdomain ? brand.subdomain : null },
          }
        )
        .then((response) => {
          let temp_categories = response.data;
          temp_categories.unshift("All");
          setCategories(temp_categories);
          setCategory(temp_categories[0]);
          setLoading(false);
        });
    })();
  }, [brand, BASE_API_URL]);

  const handleCategoryChange = (index) => {
    setCategory(categories[index]);
  };

  const handleBrandChange = (index) => {
    setBrand(brands[index]);
  };

  const handleInclusionPhrasesChange = (e) => {
    const value = e.target.value;
    setInclusionPhrases(value.split(", "));
  };

  const handleExclusionPhrasesChange = (e) => {
    const value = e.target.value;
    setExclusionPhrases(value.split(", "));
  };

  const syncTickets = async () => {
    setLoading(true);
    // console.log(ticketIntegrationMethods[0].id);
    let unixTimeInMinutes = Math.floor(startingDate.getTime() / 1000 / 60);
    var body = new FormData();
    body.append("integration_id", ticketIntegrationMethods[0].id);
    body.append("base_id", activeBase);
    body.append("category", category === "All" ? null : category);
    body.append("inclusion_phrases", JSON.stringify(inclusionPhrases));
    body.append("exclusion_phrases", JSON.stringify(exclusionPhrases));
    body.append("active", macroActive);
    body.append("domain", brand.subdomain ? brand.subdomain : null);
    body.append("start_time", unixTimeInMinutes);
    // let body = {
    //   integration_id: ticketIntegrationMethods[0].id,
    //   base_id: activeBase,
    //   category: category === "All" ? null : category,
    //   inclusion_phrases: JSON.stringify(inclusionPhrases),
    //   exclusion_phrases: JSON.stringify(exclusionPhrases),
    //   active: macroActive,
    //   domain: brand.subdomain ? brand.subdomain : null,
    //   start_time: unixTimeInMinutes,
    // };
    await axios({
      method: "post",
      url: BASE_API_URL + "/zendesk/macro/import",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    })
      .then((response) => {
        toast.success("Macro import started successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error occurred while starting the macro import.");
      });
    var refresh;
    switch (macroSyncInterval) {
      case "daily":
        refresh = 1440;
        break;
      case "weekly":
        refresh = 10080;
        break;
      case "monthly":
        refresh = 43200;
        break;
      default:
        refresh = 1440;
    }
    if (refresh !== 0) {
      // console.log("Scheduling Refresh should take place");
      body = {
        enabled: true,
        frequency_minutes: refresh,
        schedule_metadata: {
          integration_id: ticketIntegrationMethods[0].id,
          base_id: activeBase,
          type: "zendesk_macro_crawl",
          category: category === "All" ? null : category,
          inclusion_phrases: JSON.stringify(inclusionPhrases),
          exclusion_phrases: JSON.stringify(exclusionPhrases),
          active: macroActive,
          domain: brand.subdomain ? brand.subdomain : null,
        },
      };
      await axios({
        method: "post",
        url: BASE_API_URL + "/scheduling/",
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        data: body,
      })
        .then((response) => {
          toast.success("Macro Sync schedule initiated successfully!");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error("An error occurred while scheduling future macro syncs.");
        });
    }
  };

  const useMacroSyncInterval = ["daily", "weekly", "monthly"];

  return (
    <div className="ticket-sync">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <Breadcrumb className="">
            <Breadcrumb.Item key={0} href="/bases">
              All Knowledge Bases
            </Breadcrumb.Item>
            <Breadcrumb.Item key={1} href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active key={2}>
              Import Macros
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>Import Macros</h1>
          {inactive ? (
            <p className="inactive-message">
              Zendesk integration is not enabled.{" "}
              <Link to="/integrations">
                Use the integrations page to enable it.
              </Link>
            </p>
          ) : (
            <div>
              <div className="new-crawl-settings">
                <div className="crawl-setting-column">
                  {/* <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Ticket Integration System:</Form.Label>
                      <InfoBadge info="Specify the ticketing integration system to sync articles with."></InfoBadge>
                    </div>
                    <Form.Select
                      value={ticketIntegrationMethod}
                      onChange={(e) => {
                        setTicketIntegrationMethod(e.target.value);
                      }}
                    >
                      <option key="1">{ticketIntegrationMethod}</option>
                      {ticketIntegrationMethods.map((method, index) => {
                        if (
                          method["type"].split("_")[1] !==
                            ticketIntegrationMethod &&
                          method["enabled"] === true
                        ) {
                          return (
                            <option key={index}>
                              {method["type"].split("_")[1]}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Brand:</Form.Label>
                      <InfoBadge info="Specify the brand (Zendesk subdomain) you'd like to import articles from."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleBrandChange(e.target.value);
                      }}
                    >
                      {brands.map((brand, index) => {
                        return (
                          <option key={index} value={index}>
                            {brand.name}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Sync Interval:</Form.Label>
                      <InfoBadge info="Frequency that articles will be synced."></InfoBadge>
                    </div>
                    <Form.Select
                      value={macroSyncInterval}
                      onChange={(e) => {
                        setMacroSyncInterval(e.target.value);
                      }}
                    >
                      {useMacroSyncInterval.map((option, index) => {
                        return <option key={index}>{option}</option>;
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Include Only Active Macros:</Form.Label>
                      <InfoBadge info="Do you want to include only macros that are active?"></InfoBadge>
                    </div>
                    <Form.Select
                      value={macroActive ? "Yes" : "No"}
                      onChange={(e) => {
                        setMacroActive(e.target.value === "Yes");
                      }}
                    >
                      <option key="1" value="Yes">
                        Yes
                      </option>
                      <option key="2" value="No">
                        No
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="crawl-setting-column">
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Categories To Import:</Form.Label>
                      <InfoBadge info="Optionally, limit import to articles from a specified category."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleCategoryChange(e.target.value);
                      }}
                    >
                      {categories.map((category, index) => {
                        return (
                          <option key={index} value={index}>
                            {category}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Inclusion Phrases:</Form.Label>
                      <InfoBadge info="List any filter phrases you want all Macro titles to contain. Please separate each phrase with a comma"></InfoBadge>
                    </div>
                    <Form.Control
                      value={inclusionPhrases.join(", ")}
                      onChange={(e) => {
                        handleInclusionPhrasesChange(e);
                      }}
                      onBlur={(e) => handleInclusionPhrasesChange(e)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Exclusion Phrases:</Form.Label>
                      <InfoBadge info="List filter phrases you want all Macro titles to not contain. Please separate each phrase with a comma"></InfoBadge>
                    </div>
                    <Form.Control
                      value={exclusionPhrases.join(", ")}
                      onChange={(e) => {
                        handleExclusionPhrasesChange(e);
                      }}
                      onBlur={(e) => handleExclusionPhrasesChange(e)}
                    />
                  </Form.Group>
                </div>
              </div>
              <Button
                className="primary-button new-crawl-button"
                onClick={() => syncTickets()}
              >
                Save and Start Sync
              </Button>
            </div>
          )}
        </>
      )}
      <Toaster />
    </div>
  );
}
