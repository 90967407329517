import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { Toaster } from "react-hot-toast";

import "./Agents.scss";

import AgentTable from "../../components/AgentTable/AgentTable";
import WidgetTable from "../../components/WidgetTable/WidgetTable";
import RoutesTable from "../../components/RoutesTable/RoutesTable";

export default function Agents() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [dataChanged, setDataChanged] = useState(Date.now());

  useEffect(() => {
    (async () => {
      try {
        const agentsResponse = await axios.get(BASE_API_URL + `/bots/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        });

        const widgetsResponse = await axios.get(BASE_API_URL + `/widgets/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        });

        setAgents(agentsResponse.data);
        setWidgets(widgetsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged]);

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="title-row">
            <h2>Web Widgets</h2>
          </div>
          <AgentTable
            agents={agents}
            objective={"Support Guide (External)"}
            title={"Bots for Web Widgets"}
          />
          <WidgetTable
            widgets={widgets}
            fetchWidgets={() => setDataChanged(Date.now())} // Function to refresh the widgets
          />
          <RoutesTable
            route_type="web_widget"
            table_title="Language to Web Widget Routes"
          />
        </div>
      )}
      <Toaster />
    </div>
  );
}
