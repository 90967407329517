import { Button } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import ConfirmModal from "../ConfirmModal/ConfirmModal";

import "./WidgetEmbedCode.scss";

const WidgetEmbedCode = ({
  startState,
  font,
  fontUrl,
  inputBackgroundColor,
  messagePanelBackgroundColor,
  answerBackgroundColor,
  answerTextColor,
  suggestedQuestions,
  inputPlaceholderText,
  updateLoading,
  updateWidget,
  headEmbedCode,
  setHeadEmbedCode,
  bodyEmbedCode,
  setBodyEmbedCode,
  currentWidget,
  type,
  widgetHeight,
  widgetPosition,
  offsetX,
  offsetY,
  isWidgetSaved,
  setIsWidgetSaved,
  triggerBtnColor,
  btnIcon,
  footerText,
  infoText,
  headerText,
  headerTextColor,
  headerColor,
  linkBtnColor,
  linkTextColor,
  placeholderTextColor,
  inputTextColor,
  questionTextColor,
  headerIconColor,
  inputIconColor,
  showConfirmModal,
  setShowConfirmModal,
  showSnippets,
  suggestedBgColor,
  suggestedTextColor,
  customCSSClasses,
  useBanner,
  bannerText,
  avatarUrl,
  useTicket,
}) => {
  // states
  const [isMounted, setIsMounted] = useState(false);
  const [copyToClipFlag, setCopyToClipFlag] = useState(null);

  const isOnlyDigits = (number) => {
    let isNum = /^\d+$/.test(number);
    return isNum;
  };

  const height = useMemo(
    () => (isOnlyDigits(widgetHeight) ? `${widgetHeight}%` : widgetHeight),
    [widgetHeight]
  );
  const memoOffsetX = useMemo(
    () => (isOnlyDigits(offsetX) ? `${offsetX}%` : offsetX),
    [offsetX]
  );
  const memoOffsetY = useMemo(
    () => (isOnlyDigits(offsetY) ? `${offsetY}%` : offsetY),
    [offsetY]
  );

  useEffect(() => {
    setHeadEmbedCode(`
<script id="rightpage-widget-script" data-rightpage-widget-id="${currentWidget.widget_id}" async src='https://www.widget.rightpage.com/widget/index.js'></script>
`);
    //     setBodyEmbedCode(`<div
    //     id='rightpage-widget' data-widget-id="${currentWidget.widget_id}" ${
    //       type && `data-type="${type.toLowerCase()}"`
    //     } ${startState && `data-start-state="${startState.toLowerCase()}"`}
    //     ${font && `data-font="${font}"`} ${fontUrl && `data-font-url="${fontUrl}"`}
    //     ${inputBackgroundColor && `data-input-bg="${inputBackgroundColor}"`} ${
    //       messagePanelBackgroundColor &&
    //       `data-question-panel-bg="${messagePanelBackgroundColor}"`
    //     } ${
    //       answerBackgroundColor && `data-answer-panel-bg="${answerBackgroundColor}"`
    //     } ${answerTextColor && `data-answer-text-color="${answerTextColor}"`} ${
    //       suggestedQuestions &&
    //       `data-suggested-questions="${suggestedQuestions.toLowerCase()}"`
    //     } ${
    //       inputPlaceholderText && `data-input-placeholder="${inputPlaceholderText}"`
    //     } ${height && type === "Floating" && `data-height="${height}"`} ${
    //       widgetPosition &&
    //       type === "Floating" &&
    //       `data-position="${widgetPosition.toLowerCase()}"`
    //     } ${
    //       memoOffsetX && type === "Floating" && `data-offsetX="${memoOffsetX}"`
    //     } ${
    //       memoOffsetY && type === "Floating" && `data-offsetY="${memoOffsetY}"`
    //     } ${
    //       triggerBtnColor &&
    //       type === "Floating" &&
    //       `data-trigger-btn-color="${triggerBtnColor}"`
    //     } ${
    //       btnIcon &&
    //       type === "Floating" &&
    //       `data-trigger-btn-icon="${btnIcon.replace(" ", "_").toLowerCase()}"`
    //     } ${
    //       footerText &&
    //       `data-footer-text="${(footerText || "")?.replace(/"/g, "'")}"`
    //     } ${infoText && `data-info-text="${infoText}"`} ${
    //       headerText && `data-header-text="${headerText}"`
    //     } ${headerTextColor && `data-header-text-color="${headerTextColor}"`} ${
    //       headerColor && `data-header-color="${headerColor}"`
    //     } ${linkBtnColor && `data-link-btn-color="${linkBtnColor}"`} ${
    //       linkTextColor && `data-link-text-color="${linkTextColor}"`
    //     } ${
    //       placeholderTextColor &&
    //       `data-placeholder-text-color="${placeholderTextColor}"`
    //     } ${inputTextColor && `data-input-text-color="${inputTextColor}"`} ${
    //       questionTextColor && `data-question-text-color="${questionTextColor}"`
    //     } ${
    //       headerIconColor &&
    //       `data-header-icon-color="${headerIconColor.toLowerCase()}"`
    //     } ${
    //       inputIconColor &&
    //       `data-input-icon-color="${inputIconColor.toLowerCase()}"`
    //     } ${
    //       suggestedBgColor &&
    //       `data-suggested-bg-color="${suggestedBgColor.toLowerCase()}"`
    //     } ${
    //       suggestedTextColor &&
    //       `data-suggested-text-color="${suggestedTextColor.toLowerCase()}"`
    //     } ${showSnippets && `data-only-links="${showSnippets.toLowerCase()}"`} ${
    //       customCSSClasses && `data-custom-css="${customCSSClasses}"`
    //     } ${bannerText && `data-banner-text="${bannerText}"`} ${
    //       useTicket && `data-use-ticket="${useTicket.toLowerCase()}"`
    //     } ${avatarUrl && `data-avatar-url="${avatarUrl}"`}
    // />
    // `);

    if (isMounted) {
      setIsWidgetSaved(false);
    }
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    answerBackgroundColor,
    answerTextColor,
    font,
    fontUrl,
    inputBackgroundColor,
    inputPlaceholderText,
    messagePanelBackgroundColor,
    startState,
    suggestedQuestions,
    currentWidget,
    type,
    height,
    widgetPosition,
    memoOffsetX,
    memoOffsetY,
    setIsWidgetSaved,
    triggerBtnColor,
    btnIcon,
    footerText,
    infoText,
    headerText,
    headerTextColor,
    headerColor,
    linkBtnColor,
    linkTextColor,
    placeholderTextColor,
    inputTextColor,
    questionTextColor,
    headerIconColor,
    inputIconColor,
    showSnippets,
    suggestedBgColor,
    suggestedTextColor,
    customCSSClasses,
    useBanner,
    bannerText,
    avatarUrl,
    useTicket,
  ]);

  return (
    <div className="widget-embed-code">
      <div className="widget-embed-btn-wrap">
        {/* <div>
          <h6 className="heading widget-embed-heading">Widget Embed Code</h6>
          <p className="widget-embed-para">
            Insert both the body and head snippets into your webpage to embed
            your widget
          </p>
        </div> */}
        {/* <Button
          disabled={updateLoading}
          className='widget-embed-copy-btn'
          onClick={() => updateWidget()}
        >
          {updateLoading ? (
            <Spinner
              style={{ height: "20px", width: "20px" }}
              animation='border'
            />
          ) : (
            "Save Widget"
          )}
        </Button> */}
      </div>
      <div>
        <div className="heading-wrap">
          <div>
            <h6 className="heading widget-embed-heading">Embed Code</h6>
            <p className="widget-embed-para">
              Copy and paste the below code snippet into the {`"<Head>"`}{" "}
              section of your webpages (place anywhere within the {`"<Head>"`}{" "}
              tags
            </p>
          </div>
          <Button
            onClick={() => {
              if (isWidgetSaved) {
                navigator.clipboard.writeText(headEmbedCode);
                toast.success("Body Embed Code copied to clipboard");
              } else {
                setCopyToClipFlag("head");
                setShowConfirmModal(true);
              }
            }}
            className="embed-button"
          >
            Copy Embed Code
          </Button>
        </div>
        <div className="code-wrap">
          {headEmbedCode}
          <div className="overlay"></div>
        </div>
      </div>
      {/* <div>
        <div className="heading-wrap">
          <div>
            <h6 className="heading widget-embed-heading">Body Embed Code</h6>
            <p className="widget-embed-para">
              Copy and paste the below code snippet into the {`“<Body>”`}{" "}
              section of your webpages (place anywhere within the {`“<Body>”`}{" "}
              tags
            </p>
          </div>
          <Button
            onClick={() => {
              if (isWidgetSaved) {
                navigator.clipboard.writeText(bodyEmbedCode);
                toast.success("Body Embed Code copied to clipboard");
              } else {
                setCopyToClipFlag("body");
                setShowConfirmModal(true);
              }
            }}
            className="embed-button"
          >
            Copy Body Embed Code
          </Button>
        </div>
        <div className="code-wrap">
          {bodyEmbedCode}
          <div className="overlay"></div>
        </div>
      </div> */}
      {showConfirmModal && (
        <ConfirmModal
          loading={updateLoading}
          onClick={() => updateWidget(null, null, copyToClipFlag)}
          toggleModal={setShowConfirmModal}
        />
      )}
    </div>
  );
};

export default WidgetEmbedCode;
