import axios from "axios";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { Toaster } from "react-hot-toast";
import AgentTable from "../../components/AgentTable/AgentTable";
import "./AssistAgents.scss";
import RoutesTable from "../../components/RoutesTable/RoutesTable";

export default function Agents() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [agents, setAgents] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setAgents(response.data);
          // console.log(response.data);
        });
    })();
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/objectives`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="title-row">
            <h2>Agent Assist</h2>
            <Link to="/integrations">
              <Button className="secondary-button">
                Manage Zendesk Integration
              </Button>
            </Link>
          </div>
        </div>
      )}
      <>
        <AgentTable
          agents={agents}
          objective={"Support Representative (Internal)"}
          title={"Agent Assist Bots"}
        />
        <RoutesTable
          route_type={"agent_assist"}
          table_title={"Language and Brand Routes"}
        />
      </>

      <Toaster />
    </div>
  );
}
