import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ to, label, iconGrey, iconWhite, active }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <NavLink
      className={`sidebar-item ${active ? "active" : ""}`}
      to={to}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="wrapper">
        <img
          src={isHovering || active ? iconWhite : iconGrey}
          alt={`${label} icon`}
          className="icon"
        />
        <span className="content-label">{label}</span>
      </div>
    </NavLink>
  );
};

export default SidebarLink;
