import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./WidgetPreview.scss";

const WidgetPreview = ({
  widgetId,
  type,
  startState,
  font,
  fontUrl,
  inputBackgroundColor,
  messagePanelBackgroundColor,
  answerBackgroundColor,
  answerTextColor,
  suggestedQuestions,
  inputPlaceholderText,
  widgetHeight,
  widgetPosition,
  offsetX,
  offsetY,
  triggerBtnColor,
  btnIcon,
  footerText,
  infoText,
  headerText,
  headerTextColor,
  headerColor,
  linkBtnColor,
  linkTextColor,
  placeholderTextColor,
  inputTextColor,
  questionTextColor,
  headerIconColor,
  inputIconColor,
  showSnippets,
  suggestedBgColor,
  suggestedTextColor,
  customCSSClasses,
  useBanner,
  bannerText,
  avatarUrl,
  useTicket,
}) => {
  useEffect(() => {
    var script = document.createElement("script");
    script.async = true;
    script.id = "rightpage-widget-script";
    script.src = "https://www.widget.rightpage.com/widget/index.js";
    script.setAttribute("data-rightpage-widget-id", widgetId);
    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById("rightpage-widget-script");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [
    type,
    startState,
    font,
    inputBackgroundColor,
    messagePanelBackgroundColor,
    answerBackgroundColor,
    answerTextColor,
    suggestedQuestions,
    inputPlaceholderText,
    fontUrl,
    widgetId,
    widgetHeight,
    widgetPosition,
    offsetX,
    offsetY,
    triggerBtnColor,
    btnIcon,
    footerText,
    infoText,
    headerText,
    headerTextColor,
    headerColor,
    linkBtnColor,
    linkTextColor,
    placeholderTextColor,
    inputTextColor,
    questionTextColor,
    headerIconColor,
    inputIconColor,
    showSnippets,
    suggestedBgColor,
    suggestedTextColor,
    customCSSClasses,
    useBanner,
    bannerText,
    avatarUrl,
    useTicket,
  ]);

  return (
    <div className="widget-preview">
      <Helmet>
        <script
          async
          src="https://www.widget.rightpage.com/widget/index.js"
        ></script>
      </Helmet>
    </div>
  );
};

export default WidgetPreview;
