import Alert from "react-bootstrap/Alert";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import toast, { Toaster } from "react-hot-toast";

import DropZone from "../../components/DropZone/DropZone";

import "./NewDoc.scss";

export default function AddDocs() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [activeBase, setActiveBase] = useState();
  const [addFile, setAddFiles] = useState(true);
  const [bases, setBases] = useState();
  const [buttonText, setButtonText] = useState("Begin File Upload");
  const [files, setFiles] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [pageType, setPageType] = useState("Article");
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [status, setStatus] = useState(false);
  const [url, setUrl] = useState("");
  const [webpages, setWebpages] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      // console.log("base id:", base_id);
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const temp_bases = response.data;
          temp_bases.forEach((base, i) => {
            if (base.base_id === base_id) {
              temp_bases.splice(i, 1);
              temp_bases.unshift(base);
            }
          });
          setBases(temp_bases);
          setActiveBase(temp_bases[0].base_id);
          // console.log(response);
          setisLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFiles((files) => [...files, file]);
      setStatus(false);
      // console.log(file);
    });
  };

  const determine_type = (file) => {
    const file_type = file.type;
    // console.log("file type:", file_type);
    if (file_type === "application/pdf") {
      return "pdf";
    } else if (
      file_type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "docx";
    } else if (
      file_type ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return "pptx";
    } else if (file_type === "video/mp4") {
      return "mp4";
    } else if (file_type === "audio/mpeg") {
      return "mp3";
    } else if (file_type === "video/mp4") {
      return "mp4";
    } else if (file_type === "audio/MPA") {
      return "mpa4";
    } else if (file_type === "audio/wav") {
      return "wav";
    } else if (file_type === "video/webm") {
      return "webm";
    } else if (file_type === "video/mpeg") {
      return "mpeg";
    } else if (file_type === "audio/x-m4a") {
      return "m4a";
    } else {
      return "pdf";
    }
  };

  const create_doc = async ({ file, url, base_id, user_id, doc_type }) => {
    setShowSpinner(true);
    let doc_url;
    let doc_id;
    let doc_status;
    let message;
    try {
      var body = new FormData();
      if (
        [
          "pdf",
          "pptx",
          "docx",
          "mp4",
          "mpeg",
          "m4a",
          "webm",
          "mp3",
          "mpga",
          "wav",
        ].includes(doc_type)
      ) {
        body.append("file", file);
        body.append("title", file.name);
      } else {
        body.append("url", url);
        body.append("title", "Webpage");
      }
      body.append("base_id", base_id);
      body.append("user_id", user_id);
      body.append("doc_type", doc_type);
      const response = await axios({
        method: "post",
        url: BASE_API_URL + "/documents",
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        data: body,
      });
      // console.log("response", response);
      doc_status = response.data.status;
      doc_url = response.data.url;
      doc_id = response.data.doc_id;
      message = response.data.message;
      if (doc_status === false) {
        toast.error(message);
      } else {
        toast.success(message);
      }
      // console.log(doc_url);
    } catch (err) {
      toast.error(message);
    }
    if (doc_status === true) {
      try {
        body = new FormData();
        body.append("url", doc_url);
        body.append("doc_id", doc_id);
        await axios({
          method: "post",
          url: BASE_API_URL + "/documents/process",
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
          data: body,
        });
      } catch (err) {
        toast.error(err);
      }
    }
    setShowSpinner(false);
  };

  const handleSetStatus = (status) => {
    setStatus(status);
  };

  const onSubmit = async (e) => {
    // e.preventDefault();
    handleSetStatus(true);
    setButtonText("Uploading...");
    // setShowSpinner(true);
    // console.log("files submitted for upload");
    // console.log(files);
    // setStatus(true);
    if (addFile) {
      // console.log("Sending files");
      files.forEach(async (file) => {
        const doc_type = determine_type(file);
        // console.log("doc_type", doc_type);
        create_doc({
          file: file,
          base_id: activeBase,
          user_id: Userfront.user["userUuid"],
          doc_type: doc_type,
        });
        setFiles([]);
        // setStatus(true);
        setButtonText("Begin Upload");
      });
    } else {
      // console.log("Sending webpages");
      webpages.forEach((webpage) => {
        create_doc({
          url: webpage.url,
          base_id: activeBase,
          user_id: Userfront.user["userUuid"],
          doc_type: "webpage_" + webpage.page_type.toLowerCase(),
        });
        setWebpages([]);
        setUrl("");
        setPageType("Article");
        // setStatus(true);
        setButtonText("Begin Webpage Import");
      });
    }
    // setShowSpinner(false);
    setStatus(true);
    setButtonText("Begin Upload");
    // handleSetStatus(false);
  };
  const on_webpage_add = () => {
    setWebpages([...webpages, { url: url, page_type: pageType }]);
    setUrl("");
  };

  const handle_page_type_change = (e) => {
    setPageType(e.target.value);
  };

  const handle_url_change = (e) => {
    setUrl(e.target.value);
  };

  const on_base_change = (e) => {
    // console.log("active base changed to: " + e.target.value);
    setActiveBase(e.target.value);
  };

  const on_type_change = (e) => {
    // console.log("active type changed to: " + e.target.value);
    if (e.target.value === "File") {
      setAddFiles(true);
    } else {
      setAddFiles(false);
      setButtonText("Begin WebPage Import");
    }
  };

  return (
    <div className="page-container">
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Breadcrumb className="breadcrumbs">
            <Breadcrumb.Item href="/bases">All Knowledge Bases</Breadcrumb.Item>
            <Breadcrumb.Item href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Add Documents</Breadcrumb.Item>
          </Breadcrumb>
          <div className="page-body">
            <div className="Centered-Form">
              <h3>Add Documents</h3>
              <Form className="new-doc-form">
                <Form.Text>
                  Select Knowledge Base to Add Documents to:
                </Form.Text>
                <Form.Select
                  size="md"
                  className="option"
                  onChange={on_base_change}
                >
                  {bases.map((base, index) => {
                    return (
                      <option key={index} value={base.base_id}>
                        {base.base_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Text>Select type of document to add:</Form.Text>
                <Form.Select
                  size="md"
                  className="option"
                  onChange={on_type_change}
                >
                  <option>File</option>
                  <option>Webpage</option>
                </Form.Select>
              </Form>
              {addFile ? (
                <div>
                  <div className="form-context">
                    <Form.Text>Select Documents for upload:</Form.Text>
                    <Badge onClick={handleShow} className="info-badge">
                      View Supported Document Types
                    </Badge>
                  </div>
                  <DropZone onDrop={onDrop} acceptedFileList={files} />
                </div>
              ) : (
                <div className="webpage-add-form">
                  <Form.Text>Select Documents for upload:</Form.Text>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Webpage URL to Import"
                      aria-label="Webpage URL to Import"
                      aria-describedby="basic-addon2"
                      value={url}
                      onChange={handle_url_change}
                    />
                    <Form.Select
                      variant="outline-secondary"
                      title="Page Type"
                      className="page-type-dropdown"
                      id="input-group-dropdown-2"
                      align="end"
                      onChange={(e) => handle_page_type_change(e)}
                    >
                      <option value="Article">Article</option>
                      <option value="Discussion">Discussion</option>
                      <option href="#" value="List">
                        List
                      </option>
                      <option value="Product">Product</option>
                      <Dropdown.Divider />
                      <option href="#" value="Other">
                        Other
                      </option>
                    </Form.Select>
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={on_webpage_add}
                    >
                      Add
                    </Button>
                  </InputGroup>
                  {webpages.length > 0 ? (
                    <div>
                      <Table
                        hover
                        striped
                        bordered
                        size="lg"
                        className="base-table"
                      >
                        <thead>
                          <tr>
                            <th>URL</th>
                            <th>Webpage Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {webpages.map((webpage, index) => (
                            <tr key={index} className="base-row">
                              <td>{webpage.url}</td>
                              <td>{webpage.page_type}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {showSpinner ? (
                <Spinner animation="border" />
              ) : (
                <>
                  <Button
                    className="primary-button upload-button"
                    onClick={onSubmit}
                  >
                    {buttonText}
                  </Button>
                  {status ? (
                    <Alert
                      variant="info"
                      key={"info"}
                      onClose={() => setStatus(false)}
                      dismissible
                      className="status-alert"
                    >
                      <p className="status-message">
                        Add more documents, or view{" "}
                        {
                          <Link to={`/bases/${activeBase}`}>
                            your Knowledge base.
                          </Link>
                        }
                      </p>
                    </Alert>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header
          className="doc-support-overlay"
          closeButton
        ></Offcanvas.Header>
        <Offcanvas.Body>
          <h3>Document Support</h3>
          <p>
            We support integrating a wide variety of types of content into
            knowledge bases. If we don't yet support a file type that is
            important to you, please don't hesistate to reach out to us.
          </p>
          <h5>Office Documents</h5>
          <ul>
            <li>PowerPoint (.PPTX)</li>
            <li>Word (.DOCX)</li>
            <li>PDF (.PDF)</li>
          </ul>
          <h5>Video</h5>
          <ul>
            <li>MP4 (.mp4)</li>
            <li>WEBM (.webm)</li>
          </ul>
          <h5>Audio</h5>
          <ul>
            <li>MP3 (.mp3)</li>
            <li>WAV (.wav)</li>
            <li>M4A (.m4a)</li>
          </ul>
          <h5>Webpages</h5>
          <ul>
            <li>Article Pages</li>
            <li>Discussion Pages</li>
            <li>Product Pages</li>
            <li>List Pages</li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <Toaster />
    </div>
  );
}
