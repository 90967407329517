export function formatString(str) {
  return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}

export function formatSyncType(str) {
  return str
    .split("_")
    .filter((word) => word !== "crawl" && word !== "scrape")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
