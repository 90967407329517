import React from "react";
import { Form, FormCheck } from "react-bootstrap";
import InfoBadge from "../../InfoBadge/InfoBadge";

import "./DataSources.scss";

function DataSources({ bot, bases, handleUpdate }) {
  const handleOnChange = (base_id) => {
    // If the base is already active, then remove it from the active bases.
    // Otherwise add it to active bases
    const updatedBot = { ...bot };
    if (bot.activeBases.includes(base_id)) {
      updatedBot.activeBases = bot.activeBases.filter(
        (item) => item !== base_id
      );
    } else {
      updatedBot.activeBases.push(base_id);
    }
    handleUpdate("activeBases", updatedBot.activeBases);
  };

  return (
    <Form>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Bot Knowledge Sources
          </Form.Label>
          <Form.Text className="section-explantion">
            When a bot prepares a response for a user, it will use the content
            in the knowledge bases you select below to help it determine what to
            say. If you do not select any knowledge bases, the bot will not be
            able to respond. You can add or remove knowledge bases at any time.
          </Form.Text>
        </div>
        <div className="form-context">
          <Form.Label>Linked Knowledge Bases:</Form.Label>
          <InfoBadge info="Connect your agent to knowledge bases in order to provide the agent with the information it will need to perform its objective. You can select multiple knowledge bases." />
        </div>
        <div className="sources-mb-3">
          {bases.map((base) => (
            <FormCheck
              key={base.base_id}
              type="checkbox"
              id={base.base_id}
              label={base.base_name}
              className="base-switch"
              defaultChecked={bot.activeBases.includes(base.base_id)}
              onChange={() => handleOnChange(base.base_id)}
            />
          ))}
        </div>
      </div>
    </Form>
  );
}

export default DataSources;
