import axios from "axios";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { Modal, Form } from "react-bootstrap";

import EmptyState from "../../components/EmptyState/EmptyState";
import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./Tags.scss";

export default function Tag() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/tags/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setTags(response.data.tags);
          console.log(response.data.tags);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (count) => {
    (async () => {
      await axios
        .delete(BASE_API_URL + `/tags/${tags[count].tag_id}`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          const newTags = [...tags];
          newTags.splice(count, 1);
          setTags(newTags);
        });
    })();
  };

  const [showAdd, setShowAdd] = useState(false);

  const handleShowAdd = () => setShowAdd(true);
  const handleCloseShowAdd = () => {
    setShowAdd(false);
    setNewTag({
      title: "",
    });
  };

  const [newTag, setNewTag] = useState({
    title: "",
  });

  const handleUpdateNewTitle = (e) => {
    console.log("Updating title", e.target.value);
    setNewTag({ title: e.target.value });
  };

  const handleAddTag = (event) => {
    event.preventDefault();
    console.log("Adding tag");
    (async () => {
      const data = {
        title: newTag.title,
        system: "",
        enum: JSON.stringify(["None"]),
        examples: JSON.stringify([]),
      };
      console.log("Trying to add tag");
      await axios
        .post(BASE_API_URL + `/tags/`, data, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          tags.push(data);
          setShowAdd(false);
        });
    })();
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="tag-title-row">
            <h2>Ticket Tagging Automations</h2>
            <Button className="primary-button" onClick={handleShowAdd}>
              Add New Workflow
            </Button>
          </div>
          {tags && tags.length === 0 ? (
            <EmptyState type={"Tagging Workflows"} onClick={handleShowAdd} />
          ) : (
            <Table hover striped bordered size="lg" className="base-table">
              <thead>
                <tr>
                  <th>Workflow Title</th>
                  <th>Options (tags to select from)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tags.map((item, count) => (
                  <tr key={count}>
                    <td className="workflow-title">{item.title}</td>
                    <td>{JSON.parse(item.enum).join(", ")}</td>
                    <td>
                      <div className="action-panel">
                        <div className="tag-buttons">
                          <Link to={`view/${tags[count].tag_id}`}>
                            <Button className="open-button">Edit</Button>
                          </Link>
                          <Button
                            className="open-button"
                            onClick={() => handleDelete(count)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}
      <Modal show={showAdd} centered onHide={handleCloseShowAdd}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tagging Workflow</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddTag}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Title</Form.Label>
                <InfoBadge info="A short title that summarizes this tagging workflow (e.g., Sentiment Tagging)."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                onChange={(e) => handleUpdateNewTitle(e)}
                value={newTag.title}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleCloseShowAdd}>
            Cancel
          </Button>
          <Button className="primary-button" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
