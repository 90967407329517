import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./YouTubeImport.scss";

export default function YouTubeImport() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [activeBase, setActiveBase] = useState();
  const [bases, setBases] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();

  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const temp_bases = response.data;
          temp_bases.forEach((base, i) => {
            if (base.base_id === base_id) {
              temp_bases.splice(i, 1);
              temp_bases.unshift(base);
            }
          });
          setBases(temp_bases);
          setActiveBase(temp_bases[0].base_id);
          // console.log(response);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const create_doc = async () => {
    setLoading(true);
    var body = new FormData();
    body.append("file", file);
    body.append("title", "YouTube Import");
    body.append("base_id", activeBase);
    body.append("doc_type", "csv_youtube");

    const response = await axios({
      method: "post",
      url: BASE_API_URL + `/documents`,
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    });
    // console.log("response", response);
    let doc_status = response.data.status;
    let doc_url = response.data.url;
    let doc_id = response.data.doc_id;
    let message = response.data.message;
    if (doc_status === false) {
      toast.error(message);
    } else {
      toast.success(message);
    }
    if (doc_status === true) {
      try {
        body = new FormData();
        body.append("url", doc_url);
        body.append("doc_id", doc_id);
        await axios({
          method: "post",
          url: BASE_API_URL + "/documents/process",
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
          data: body,
        });
      } catch (err) {
        toast.error(err);
      }
    }
    setLoading(false);
  };

  return (
    <div className="ticket-sync">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <Breadcrumb className="">
            <Breadcrumb.Item key={0} href="/bases">
              All Knowledge Bases
            </Breadcrumb.Item>
            <Breadcrumb.Item key={1} href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active key={2}>
              Import YouTube Videos
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>Import YouTube Videos</h1>
          <div className="">
            <div className="">
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>YouTube Import CSV File:</Form.Label>
                  <InfoBadge info="Upload a csv file containing the list of YouTube videos you'd like to import. The first column of the csv should be the url of the video. The second column should be the language you'd like to import the transcript of the video in (use ISO 639-1 2-letter codes)."></InfoBadge>
                </div>
                <Form.Control
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Form.Group>
            </div>
          </div>
          <Button
            className="primary-button new-crawl-button"
            onClick={() => create_doc()}
          >
            Save and Start Import
          </Button>
        </>
      )}
      <Toaster />
    </div>
  );
}
