import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { useLocation, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./Articles.scss";

export default function Articles() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [activeBase, setActiveBase] = useState();
  const [bases, setBases] = useState();
  const [loading, setLoading] = useState(true);
  // const [ticketIntegrationMethod, setTicketIntegrationMethod] =
  //   useState("none");
  const [ticketIntegrationMethods, setTicketIntegrationMethods] = useState([]);
  const [ticketSyncInterval, setTicketSyncInterval] = useState("daily");
  let currentDate = new Date();
  let startingDate = new Date();
  startingDate.setDate(currentDate.getDate() - 365);

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState();
  const [userSegments, setUserSegments] = useState([]);
  const [userSegment, setUserSegment] = useState([]);
  const [sections, setSections] = useState([]);
  const [section, setSection] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [inactive, setInactive] = useState(false);
  const [locales, setLocales] = useState(["en-us"]);
  const [locale, setLocale] = useState("en-us");

  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      try {
        await axios
          .get(BASE_API_URL + `/bases`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            const temp_bases = response.data;
            temp_bases.forEach((base, i) => {
              if (base.base_id === base_id) {
                temp_bases.splice(i, 1);
                temp_bases.unshift(base);
              }
            });
            setBases(temp_bases);
            setActiveBase(temp_bases[0].base_id);
            // console.log(response);
          });
        await axios
          .get(BASE_API_URL + `/integrations/`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            // console.log(response.data[0]);
            const zendesk_integration = response.data.find(
              (item) => item.type === "integrations_zendesk"
            );
            setTicketIntegrationMethods([zendesk_integration]);
            if (zendesk_integration.enabled === false) {
              toast.error("Zendesk integration is not enabled.");
              setLoading(false);
              setInactive(true);
              return;
              // Break out of the function
            } else {
              // setTicketIntegrationMethod(
              //   response.data[0].type.split("_")[1]
              //     ? response.data[0].type.split("_")[1]
              //     : "none"
              // );
            }
          });
        await axios
          .get(BASE_API_URL + `/integrations/integrations_zendesk/brands`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            const temp_brands = response.data;
            // console.log("brands:", temp_brands);
            if (temp_brands.error) {
              toast.error("An error occurred while fetching brands.");
              toast.error(temp_brands.error);
              setBrands([]);
              setBrand({ brand: "none" });
              setLoading(false);
            } else {
              setBrands(temp_brands.brands);
              setBrand(temp_brands.brands[0]);
            }
          });
        await axios
          .get(
            BASE_API_URL + `/integrations/integrations_zendesk/user_segments`,
            {
              headers: {
                authorization: `Bearer ${Userfront.tokens.accessToken}`,
              },
            }
          )
          .then((response) => {
            const temp_segments = response.data;
            if (temp_segments.error) {
              toast.error("An error occurred while fetching user segments.");
              toast.error(temp_segments.error);
              setUserSegments([]);
              setUserSegment([]);
              setLoading(false);
            } else {
              // console.log("user segments:", temp_segments);
              let user_segments = temp_segments.user_segments;
              user_segments.push({ name: "Everyone", id: null });
              setUserSegments(user_segments);
              // console.log("user segments:", user_segments);
              setUserSegment(user_segments[0]);
              // console.log(userSegments[0]);
            }
          })
          .catch((error) => {
            console.log(error);
            setUserSegments([]);
            setUserSegment([]);
            setLoading(false);
          });
        await axios
          .get(BASE_API_URL + `/integrations/integrations_zendesk/sections`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            let temp_sections = response.data;
            if (temp_sections.error) {
              toast.error("An error occurred while fetching sections.");
              toast.error(temp_sections.error);
              setSections([]);
              setSection([]);
              setLoading(false);
            } else {
              // console.log("sections:", temp_sections.sections);
              temp_sections = temp_sections.sections;
              temp_sections.unshift({ name: "All", id: null });
              setSections(temp_sections);
              setSection(temp_sections[0]);
            }
          })
          .catch((error) => {
            console.log(error);
            setSections([]);
            setSection([]);
            setLoading(false);
          });
        await axios
          .get(BASE_API_URL + `/integrations/integrations_zendesk/categories`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          })
          .then((response) => {
            let temp_categories = response.data;
            // console.log("categories:", temp_categories.categories);
            temp_categories = temp_categories.categories;
            temp_categories.unshift({ name: "All", id: null });
            setCategories(temp_categories);
            setCategory(temp_categories[0]);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setCategories({ name: "All", id: null });
            setCategory({ name: "All", id: null });
            setLoading(false);
          });
        await axios
          .get(
            BASE_API_URL + `/integrations/integrations_zendesk/article/locales`,
            {
              headers: {
                authorization: `Bearer ${Userfront.tokens.accessToken}`,
              },
            }
          )
          .then((response) => {
            let locale_obj = response.data;
            const index = locale_obj.locales.indexOf(locale_obj.default_locale);
            if (index > -1) {
              locale_obj.locales.splice(index, 1);
              locale_obj.locales.unshift(locale_obj.default_locale);
            }
            setLocales(locale_obj.locales);
            setLoading(false);
          });
      } catch (error) {
        setInactive(true);
        console.log(error);
      }
      // console.log("base id:", base_id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!brand) return;
    (async () => {
      // await axios
      //   .get(
      //     BASE_API_URL + `/integrations/integrations_zendesk/user_segments`,
      //     {
      //       headers: {
      //         authorization: `Bearer ${Userfront.tokens.accessToken}`,
      //       },
      //       params: { domain: brand.subdomain },
      //     }
      //   )
      //   .then((response) => {
      //     const temp_segments = response.data;
      //     // console.log("user segments:", temp_segments);
      //     let user_segments = temp_segments.user_segments;
      //     user_segments.push({ name: "Everyone", id: null });
      //     setUserSegments(user_segments);
      //     setUserSegment(user_segments[0]);
      //     setLoading(false);
      //   });
      await axios
        .get(BASE_API_URL + `/integrations/integrations_zendesk/sections`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
          params: { domain: brand.subdomain },
        })
        .then((response) => {
          let temp_sections = response.data;
          // console.log("sections:", temp_sections.sections);
          temp_sections = temp_sections.sections;
          temp_sections.unshift({ name: "All", id: null });
          setSections(temp_sections);
          setSection(temp_sections[0]);
          setLoading(false);
        });
      await axios
        .get(BASE_API_URL + `/integrations/integrations_zendesk/categories`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
          params: { domain: brand.subdomain },
        })
        .then((response) => {
          let temp_categories = response.data;
          // console.log("categories:", temp_categories.categories);
          temp_categories = temp_categories.categories;
          temp_categories.unshift({ name: "All", id: null });
          setCategories(temp_categories);
          setCategory(temp_categories[0]);
          setLoading(false);
        });
      await axios
        .get(
          BASE_API_URL + `/integrations/integrations_zendesk/article/locales`,
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          }
        )
        .then((response) => {
          let locale_obj = response.data;
          const index = locale_obj.locales.indexOf(locale_obj.default_locale);
          if (index > -1) {
            locale_obj.locales.splice(index, 1);
            locale_obj.locales.unshift(locale_obj.default_locale);
          }
          setLocales(locale_obj.locales);
          setLoading(false);
        });
    })();
  }, [brand, BASE_API_URL]);
  const [userSegmentIndex, setUserSegmentIndex] = useState(0);

  const handleSectionChange = (index) => {
    // console.log("section:", index);
    setSection(sections[index]);
  };

  const handleCategoryChange = (index) => {
    // console.log("category:", index);
    setCategory(categories[index]);
  };

  const handleBrandChange = (index) => {
    // console.log("brand name:", index);
    setBrand(brands[index]);
  };

  const handleLocaleChange = (index) => {
    setLocale(locales[index]);
  };

  const handleUserSegmentChange = (index) => {
    // console.log("user segment:", index);
    setUserSegment(userSegments[index]);
    setUserSegmentIndex(index);
  };

  const syncArticles = async () => {
    setLoading(true);
    // console.log(ticketIntegrationMethods[0].id);
    let unixTimeInMinutes = Math.floor(startingDate.getTime() / 1000 / 60);
    let body = {
      integration_id: ticketIntegrationMethods[0].id,
      knowledge_base_id: activeBase,
      start_time: unixTimeInMinutes,
      brand: brand.subdomain,
      user_segments: userSegment.id ? [userSegment.id] : null,
      section_ids: section.id ? [section.id] : null,
      category_ids: category.id ? [category.id] : null,
      locale: locale,
    };
    await axios({
      method: "post",
      url: BASE_API_URL + "/articles_scrape/",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    })
      .then((response) => {
        toast.success("Article Sync started successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error occurred while starting the article sync.");
      });
    var refresh;
    switch (ticketSyncInterval) {
      case "daily":
        refresh = 1440;
        break;
      case "weekly":
        refresh = 10080;
        break;
      case "monthly":
        refresh = 43200;
        break;
      default:
        refresh = 1440;
    }
    if (refresh !== 0) {
      body = {
        enabled: true,
        frequency_minutes: refresh,
        schedule_metadata: {
          integration_id: ticketIntegrationMethods[0].id,
          base_id: activeBase,
          type: "zendesk_article_crawl",
          brand: brand.subdomain,
          user_segments: userSegment.id ? [userSegment.id] : null,
          section_ids: section.id ? [section.id] : null,
          category_ids: category.id ? [category.id] : null,
          locale: locale,
        },
      };
      await axios({
        method: "post",
        url: BASE_API_URL + "/scheduling/",
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        data: body,
      })
        .then((response) => {
          toast.success("Article Sync schedule initiated successfully!");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error(
            "An error occurred while scheduling future article syncs."
          );
        });
    }
  };

  const useTicketSyncInterval = ["daily", "weekly", "monthly"];

  return (
    <div className="ticket-sync">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <Breadcrumb className="">
            <Breadcrumb.Item key={0} href="/bases">
              All Knowledge Bases
            </Breadcrumb.Item>
            <Breadcrumb.Item key={1} href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active key={2}>
              Import Zendesk Articles
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>Import Zendesk Articles</h1>
          {inactive ? (
            <p className="inactive-message">
              Zendesk integration is not enabled.{" "}
              <Link to="/integrations">
                Use the integrations page to enable it.
              </Link>
            </p>
          ) : (
            <div>
              <div className="new-crawl-settings">
                <div className="crawl-setting-column">
                  {/* <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Ticket Integration System:</Form.Label>
                      <InfoBadge info="Specify the ticketing integration system to sync articles with."></InfoBadge>
                    </div>
                    <Form.Select
                      value={ticketIntegrationMethod}
                      onChange={(e) => {
                        setTicketIntegrationMethod(e.target.value);
                      }}
                    >
                      <option key="1">{ticketIntegrationMethod}</option>
                      {ticketIntegrationMethods.map((method, index) => {
                        if (
                          method["type"].split("_")[1] !==
                            ticketIntegrationMethod &&
                          method["enabled"] === true
                        ) {
                          return (
                            <option key={index}>
                              {method["type"].split("_")[1]}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Brand:</Form.Label>
                      <InfoBadge info="Specify the brand (Zendesk subdomain) you'd like to import articles from."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleBrandChange(e.target.value);
                      }}
                    >
                      {brands.map((brand, index) => {
                        return (
                          <option key={index} value={index}>
                            {brand.name}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Locale:</Form.Label>
                      <InfoBadge info="Specify the language of the articles you'd like to import."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleLocaleChange(e.target.value);
                      }}
                    >
                      {locales.map((locale, index) => {
                        return (
                          <option key={index} value={index}>
                            {locale}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Sync Interval:</Form.Label>
                      <InfoBadge info="Frequency that articles will be synced."></InfoBadge>
                    </div>
                    <Form.Select
                      value={ticketSyncInterval}
                      onChange={(e) => {
                        setTicketSyncInterval(e.target.value);
                      }}
                    >
                      {useTicketSyncInterval.map((option, index) => {
                        return <option key={index}>{option}</option>;
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="crawl-setting-column">
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>User Segment Group:</Form.Label>
                      <InfoBadge info="Specify the user segment group (impacts level of visbility) to import articles from"></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      value={userSegmentIndex}
                      onChange={(e) => {
                        handleUserSegmentChange(e.target.value);
                      }}
                    >
                      {userSegments.map((segment, index) => {
                        return (
                          <option key={index} value={index}>
                            {segment.name}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Sections To Import:</Form.Label>
                      <InfoBadge info="Optionally, limit import to articles from a specified section."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleSectionChange(e.target.value);
                      }}
                    >
                      {sections.map((section, index) => {
                        return (
                          <option key={index} value={index}>
                            {section.name}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Categories To Import:</Form.Label>
                      <InfoBadge info="Optionally, limit import to articles from a specified category."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleCategoryChange(e.target.value);
                      }}
                    >
                      {categories.map((category, index) => {
                        return (
                          <option key={index} value={index}>
                            {category.name}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <Button
                className="primary-button new-crawl-button"
                onClick={() => syncArticles()}
              >
                Save and Start Sync
              </Button>
            </div>
          )}
        </>
      )}
      <Toaster />
    </div>
  );
}
