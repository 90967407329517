import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import CacheVariant from "../CacheVariant/CacheVariant";
import "./Cache.scss";

export default function Cache() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const { bot_id } = useParams();

  const [cache, setCache] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/${bot_id}/cache`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setCache(response.data);
          // console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (count) => {
    (async () => {
      await axios
        .delete(
          BASE_API_URL + `/bots/${bot_id}/cache/${cache[count].cache_id}`,
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          }
        )
        .then((response) => {
          // After deletion, update the state without the deleted item
          setCache(cache.filter((item, index) => index !== count));
        });
    })();
  };

  // const handleDelete = async (count) => {
  //   (async () => {
  //     await axios
  //       .delete(
  //         BASE_API_URL + `/bots/${bot_id}/cache/${cache[count].cache_id}`,
  //         {
  //           headers: {
  //             authorization: `Bearer ${Userfront.tokens.accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // console.log(response.data);
  //         window.location.reload();
  //       });
  //   })();
  // };

  const [showAdd, setShowAdd] = useState(false);
  const [cacheId, setCacheId] = useState(null);
  const handleShowAdd = () => setShowAdd(true);
  const handleCloseShowAdd = () => setShowAdd(false);

  const [newItem, setNewItem] = useState({
    question: "",
    answer: "",
  });

  const handleQuesitonChange = (e) => {
    setNewItem({ ...newItem, question: e.target.value });
  };

  const handleAnswerChange = (e) => {
    setNewItem({ ...newItem, answer: e.target.value });
  };

  // const handleAddItem = async () => {
  //   (async () => {
  //     await axios
  //       .post(
  //         BASE_API_URL + `/bots/${bot_id}/cache`,
  //         {
  //           question: newItem.question,
  //           answer: newItem.answer,
  //         },
  //         {
  //           headers: {
  //             authorization: `Bearer ${Userfront.tokens.accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // console.log(response.data);
  //         window.location.reload();
  //       });
  //   })();
  // };

  const handleAddItem = async () => {
    (async () => {
      await axios
        .post(
          BASE_API_URL + `/bots/${bot_id}/cache`,
          {
            question: newItem.question,
            answer: newItem.answer,
          },
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          }
        )
        .then((response) => {
          // Update the state with the new item
          setCache((prevCache) => [
            ...prevCache,
            {
              answer: newItem.answer,
              question: newItem.question,
              status: "generating variants",
              variants: [],
              cache_id: "34343",
            },
          ]);
          // Reset the input fields
          setNewItem({ question: "", answer: "" });
          // Close the modal
          setShowAdd(false);
        });
    })();
  };

  const [editingCache, setEditingCache] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const handleEdit = (index) => {
    setEditingCache(cache[index]);
    setCacheId(cache[index].cache_id);
    setShowEdit(true); // This shows your edit modal
  };

  return (
    <div className="bot-cache">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="cache-title">
            <h2>Scripted Answers</h2>
            <Button className="primary-button" onClick={handleShowAdd}>
              Add Item
            </Button>
          </div>
          <p className="cache-explanation">
            Scripted answers are used to provide a consistent response to
            questions that are frequently asked by users. RightPage will
            automatically generate alternative phrasings of the question to
            ensure that the user's question is matched to the correct answer.
            The user does not have to ask the question in the exact same way as
            the scripted question.
          </p>
          <Table hover striped bordered size="lg" className="base-table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Variant Count</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cache.map((item, count) => (
                <tr key={count}>
                  <td>{item.question}</td>
                  <td>{item.answer}</td>
                  <td>{item.variants.length}</td>
                  <td>{item.status}</td>
                  <td>
                    <div className="action-panel">
                      {item.status === "available" ? (
                        <div className="cache-buttons">
                          {/* <Link to={`item/${cache[count].cache_id}`}>
                            <Button className="open-button">Edit</Button>
                          </Link> */}
                          <Button
                            className="open-button"
                            onClick={() => handleEdit(count)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="open-button"
                            onClick={() => handleDelete(count)}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        className="variant-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Scripted Response</Modal.Title>
        </Modal.Header>
        <CacheVariant cacheItem={editingCache} cache_id={cacheId} />
      </Modal>
      <Modal show={showAdd} centered onHide={handleCloseShowAdd}>
        <Modal.Header closeButton>
          <Modal.Title>Add Scripted Question and Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="new-cache-field"
              controlId="exampleForm.ControlInput1"
            >
              <div className="form-context">
                <Form.Label>Scripted Question</Form.Label>
                <InfoBadge info="Specify the question you would like to create a scripted answer for. RightPage will automatically generate alternative phrasings of the question."></InfoBadge>
              </div>
              <Form.Control
                as="textarea"
                className="cache-textarea"
                onChange={handleQuesitonChange}
                value={newItem.question}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="new-cache-field"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="form-context">
                <Form.Label>Scripted Answer:</Form.Label>
                <InfoBadge info="Add the content you would like to display to the user in response to the above question/message."></InfoBadge>
              </div>
              <Form.Control
                as="textarea"
                aria-label="With textarea"
                className="cache-textarea"
                value={newItem.answer}
                onChange={handleAnswerChange}
                onBlur={handleAnswerChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleCloseShowAdd}>
            Cancel
          </Button>
          <Button className="primary-button" onClick={handleAddItem}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
