export default function formatTimestamp(timestamp) {
  // Create a Date object from the timestamp string
  let date = new Date(timestamp);

  // Format the date and time parts
  const datePart = date.toLocaleDateString();

  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  const timePart = date.toLocaleTimeString([], options);

  // Combine the formatted date and time parts
  const formattedTimestamp = `${datePart} ${timePart}`;

  return formattedTimestamp;
}

export function convertToLocalTime(gmtTimestamp) {
  const date = new Date(gmtTimestamp);
  return date.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
}
