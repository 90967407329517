import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./NotionSync.scss";
import { Link } from "react-router-dom";

export default function NotionSync() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const useTicketSyncInterval = ["daily", "weekly", "monthly"];

  const [notionIntegrationId, setNotionIntegrationId] = useState(null);
  const [activeBase, setActiveBase] = useState();
  const [bases, setBases] = useState();
  const [loading, setLoading] = useState(true);
  const [pageUrl, setPageUrl] = useState("");
  const [pageId, setPageId] = useState("");
  const [getChildren, setGetChildren] = useState(true);
  const [validPageUrl, setValidPageUrl] = useState(true);
  const [ticketSyncInterval, setTicketSyncInterval] = useState("daily");
  let currentDate = new Date();
  let startingDate = new Date();
  startingDate.setDate(currentDate.getDate() - 365);

  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const temp_bases = response.data;
          temp_bases.forEach((base, i) => {
            if (base.base_id === base_id) {
              temp_bases.splice(i, 1);
              temp_bases.unshift(base);
            }
          });
          setBases(temp_bases);
          setActiveBase(temp_bases[0].base_id);
          // console.log(response);
          setLoading(false);
        });
      await axios
        .get(BASE_API_URL + `/integrations/`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          // console.log(response.data[0]);
          const notion_integration = response.data.find(
            (item) => item.type === "integrations_notion"
          );
          setNotionIntegrationId(notion_integration.id);
          if (notion_integration.enabled === false) {
            toast.error("Notion integration is not enabled.");
            setLoading(false);
            return;
            // Break out of the function
          } else {
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sync_notion = async () => {
    if (pageId === null) {
      toast.error("Please enter a valid Notion page URL.");
      return;
    }
    setLoading(true);
    // console.log(ticketIntegrationMethods[0].id);
    let unixTimeInMinutes = Math.floor(startingDate.getTime() / 1000 / 60);
    let body = {
      integration_id: notionIntegrationId,
      knowledge_base_id: activeBase,
      start_time: unixTimeInMinutes,
      page_id: pageId,
      crawl_children: getChildren,
    };
    try {
      await axios({
        method: "post",
        url: BASE_API_URL + "/notion_sync/",
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        data: body,
      });
      toast.success("Notion Page Sync initiated successfully!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(
        "An error occured while initiating Notion sync. Please check your Notion integration settings to ensure RightPage has access to provided page."
      );
      return;
    }
    var refresh;
    switch (ticketSyncInterval) {
      case "daily":
        refresh = 1440;
        break;
      case "weekly":
        refresh = 10080;
        break;
      case "monthly":
        refresh = 43200;
        break;
      default:
        refresh = 1440;
    }
    if (refresh !== 0) {
      body = {
        enabled: true,
        frequency_minutes: refresh,
        schedule_metadata: {
          integration_id: notionIntegrationId,
          base_id: activeBase,
          type: "notion_sync",
          page_id: pageId,
          page_url: pageUrl,
          crawl_children: getChildren,
        },
      };
      await axios({
        method: "post",
        url: BASE_API_URL + "/scheduling/",
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        data: body,
      })
        .then((response) => {
          toast.success("Notion Sync schedule initiated successfully!");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error(
            "An error occurred while scheduling future Notion syncs."
          );
        });
    }
    setLoading(false);
  };

  const handleGetChildrenChange = (e) => {
    let value = e.target.value;
    if (value === "yes") {
      setGetChildren(true);
    } else {
      setGetChildren(false);
    }
  };

  const handlePageUrlChange = (e) => {
    let value = e.target.value;
    setPageUrl(value);
    let pageId = extractPageIdFromUrl(value);
    setPageId(pageId);
  };

  const extractPageIdFromUrl = (url) => {
    const regex = /-([a-f0-9]{32})/;
    const match = url.match(regex);

    if (match) {
      setValidPageUrl(true);
      return match[1];
    } else {
      //   toast.error("No valid page ID found in provided Notion URL");
      setValidPageUrl(false);
      return null;
    }
  };

  return (
    <div className="ticket-sync">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <Breadcrumb className="">
            <Breadcrumb.Item key={0} href="/bases">
              All Knowledge Bases
            </Breadcrumb.Item>
            <Breadcrumb.Item key={1} href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active key={2}>
              Sync Notion Pages
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="notion-sync-title-row">
            <h2>Create Notion Sync</h2>
            <Link to="/integrations">
              <Button className="open-button">Manage Notion Integration</Button>
            </Link>
          </div>
          {notionIntegrationId === null ? (
            <p>
              You must establish an integration with Notion before syncing
              Notion Pages.
            </p>
          ) : (
            <div>
              <div className="crawl-setting-column">
                <Form.Group className="mb-3">
                  <div className="form-context">
                    <Form.Label>Notion Start Page URL:</Form.Label>
                    <InfoBadge info="Paste the URL of the starting page you want to sync with RightPage."></InfoBadge>
                  </div>
                  <Form.Control
                    value={pageUrl}
                    onChange={(e) => handlePageUrlChange(e)}
                    onBlur={(e) => handlePageUrlChange(e)}
                    isInvalid={!validPageUrl}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Notion page URL (try clicking the share
                    button and using that link).
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="form-context">
                    <Form.Label>Page ID Extracted from URL:</Form.Label>
                    <InfoBadge info="The Page ID extracted from the provided link above."></InfoBadge>
                  </div>
                  <Form.Control disabled value={pageId} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="form-context">
                    <Form.Label>Sync Interval:</Form.Label>
                    <InfoBadge info="Frequency that notion pages will be synced."></InfoBadge>
                  </div>
                  <Form.Select
                    value={ticketSyncInterval}
                    onChange={(e) => {
                      setTicketSyncInterval(e.target.value);
                    }}
                  >
                    {useTicketSyncInterval.map((option, index) => {
                      return <option key={index}>{option}</option>;
                    })}
                    ;
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="crawl-setting-column">
                <Form.Group className="mb-3">
                  <div className="form-context">
                    <Form.Label>Include Sub-Pages:</Form.Label>
                    <InfoBadge info="Will instruct RightPage to sync content from sub-pages of the provided page"></InfoBadge>
                  </div>
                  <Form.Select
                    size="md"
                    className="option"
                    onChange={(e) => handleGetChildrenChange(e)}
                    value={getChildren ? "yes" : "no"}
                  >
                    <option key={0} value={"no"}>
                      No
                    </option>
                    <option key={1} value={"yes"}>
                      Yes
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <Button
                className="primary-button new-crawl-button"
                onClick={() => sync_notion()}
              >
                Save and Start Sync
              </Button>
            </div>
          )}
        </>
      )}
      <Toaster />
    </div>
  );
}
