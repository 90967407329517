import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Userfront from "@userfront/react";
import { useState, useEffect, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { toast, Toaster } from "react-hot-toast";

import formatTimestamp from "../../services/TimeService";
import EmptyState from "../../components/EmptyState/EmptyState";

import "./KnowledgeBases.scss";
import AddIconLight from "../../assets/ActionIcons/AddIconLight.png";
import DeleteIconDark from "../../assets/ActionIcons/DeleteIcon.png";

export default function KnowledgeBase() {
  const baseNameField = useRef();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [bases, setBases] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isPrivate, setPrivate] = useState("private");
  const [loading, setLoading] = useState(true);
  const [nameField, setNameField] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setBases(response.data);
          // console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  // const onSelectChange = (choice) => {
  //   // console.log(choice.target.value);
  //   setPrivate(choice.target.value);
  // };

  const inputChange = (e) => {
    setNameField(e.target.value);
  };

  const showDeleteModal = (id) => {
    setDeleteId(id);
    handleDeleteShow(true);
  };

  const handleDelete = async () => {
    await axios
      .delete(BASE_API_URL + `/bases/${deleteId}`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        // console.log(response);
        if (response.status === 204) {
          toast.success(
            "Knowledge base deleted process started successfully. It may take a few minutes to complete."
          );
          handleDeleteClose();
        } else {
          toast.error("Knowledge base deletion failed.");
          handleDeleteClose();
        }
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (nameField === "") {
      setFormErrors({ text: "Please name your knowledge base" });
    } else {
      const base_name = nameField;
      let private_field = true;
      if (isPrivate === "public") {
        private_field = false;
      }
      const data = { base_name: base_name, private: private_field };

      await axios
        .post(BASE_API_URL + `/bases`, data, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          // console.log(response);
          setShow(false);
          setNameField("");
          setPrivate("private");
          window.location.reload();
        });
    }
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="title-row">
            <h2>Your Knowledge Bases</h2>
            <Button className="primary-button" onClick={handleShow}>
              <img src={AddIconLight} alt="add knowledge base icon" />
              <span>Create Knowledge Base</span>
            </Button>
            {/* </Link> */}
          </div>
          <div className="knowledge-bases-content">
            {bases.length === 0 ? (
              <EmptyState type={"Knowledge Bases"} onClick={handleShow} />
            ) : (
              <Table hover striped bordered size="lg" className="base-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    {/* <th>Access</th> */}
                    {/* <th>Created</th> */}
                    <th>Last Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {bases.map((base, index) => (
                    <tr key={base.base_id} className="base-row">
                      <td>{base.base_name}</td>
                      {/* <td>{base.access ? "Private" : "Public"}</td> */}
                      {/* <td>{formatTimestamp(base.created)}</td> */}
                      <td>{formatTimestamp(base.last_modified)}</td>
                      <td>
                        <div className="action-panel">
                          <Link to={`/bases/${base.base_id}`}>
                            <Button className="secondary-button">Open</Button>
                          </Link>
                          <span
                            className="delete-icon"
                            onClick={() => showDeleteModal(base.base_id)}
                          >
                            <img src={DeleteIconDark} alt="delete icon" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )}
      <Modal show={deleteShow} centered onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Knowledge Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to permanently delete this knowledge base?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleDeleteClose}>
            Close
          </Button>
          <Button className="delete-button" onClick={handleDelete}>
            Delete Knowledge Base
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Knowledge Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Knowledge Base Name</Form.Label>
                <InfoBadge info="Give your knowledge base a name that will help you remember what it's for."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                fieldRef={baseNameField}
                onChange={inputChange}
                placeholder="Knowledge base name"
                autoFocus
                error={formErrors.text}
              />
            </Form.Group>
            {/* <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="form-context">
                <Form.Label>Knowledge Base Privacy</Form.Label>
                <InfoBadge info="A private collection can only be accessed by you or designated members you specify. A public knowledge base can be accessed by anyone with the relevant link."></InfoBadge>
              </div>
              <Form.Select
                size="md"
                label="Collection Privacy"
                name="privacy"
                onChange={(choice) => onSelectChange(choice)}
              >
                <option key="private" value="private">
                  Private Collection
                </option>
                <option key="public" value="public">
                  Public Collection
                </option>
              </Form.Select>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleClose}>
            Close
          </Button>
          <Button className="delete-button" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
    </div>
  );
}
