import Accordion from "react-bootstrap/Accordion";
import Footer from "../../components/Footer/Footer";

import "./FAQ.scss";

export default function FAQ() {
  return (
    <div>
      <div className="faqContainer">
        <h2>Frequently Asked Questions</h2>
        <p>
          Prefer to speak?{" "}
          <a className="email-link" href="mailto:hello@rightpage.com">
            Email
          </a>
          , call 617-682-0525, or{" "}
          <a
            className="email-link"
            href="https://calendly.com/rightpage/30min"
            target="_blank"
            rel="noreferrer"
          >
            book a meeting
          </a>{" "}
          with us.
        </p>
        <Accordion
          defaultActiveKey={["0"]}
          alwaysOpen={true}
          flush={true}
          className="accordion"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header className="accordian-header">
              <span className="accordian-header">What is RightPage?</span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                RightPage is a platform that enables businesses to transform
                their information into a question answering bot. This bot can be
                customized for specific business objectives, such as product
                sales or lead capture, by leveraging the unique information
                owned by the business.
              </p>
              <p>
                RightPage for PDFs is our first publicly available service which
                democratizes the ability to ask questions of PDF documents. In
                the near-term we plan to expand support for other file types.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="accordian-header">What is a collection?</span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                A collection represents multiple documents. User’s build
                collections of documents that share common themes or purposes.
                This enables users to ask questions across all the documents
                within a collection.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span className="accordian-header">
                What is a featured collection?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Featured collections are designated by the RightPage team as
                high quality, useful, publicly accessible collections. Any
                logged in RightPage user may access and share a public
                collection.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="accordian-header">
                The PDF I added to RightPage didn’t work well?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Is your document a scanned PDF? RightPage does not currently
                support PDF files that have been scanned (Scanned PDF files
                store text as images, thus making the text inaccessible without
                substantial effort).
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <span className="accordian-header">
                I have information I want to add to RightPage that is not a PDF?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Great. We will be expanding self-serve support for myriad file
                types in the near future. For now, please email us to discuss
                adding non-PDF documents to a collection.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <span className="accordian-header">
                What is an embeddable widget?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                With RightPage widget, users can embed a RightPage bot on their
                websites. This feature enables websites to easily provide
                advanced RightPage features to their audiences. For example, a
                content website may utilize a RightPage widget on their article
                pages to enhance content discovery and reduce bounce rates. To
                request access, to a RightPage embeddable widget click here.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <span className="accordian-header">Do you have an API?</span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We will be introducing a self-serve API for RightPage for PDFs
                in the near future. If you would like early access, please let
                us know.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <span className="accordian-header">
                How can I customize the responses generated by RightPage?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Currently RightPage for PDFs is free while we are in a beta
                period. Over time we may need to add usage tiers to account for
                the cost of providing the RightPage for PDF service.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <span className="accordian-header">
                Where can I submit feature requests and/or have bug reports?
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>We’d love to hear from you. Please send us an email.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Footer />
    </div>
  );
}
