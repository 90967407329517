import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import toast from "react-hot-toast";

import formatTimestamp from "../../services/TimeService";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import SaveBar from "../../components/SaveBar/SaveBar";
import { formatString } from "../../services/FormatService.js";
import LinkIconDark from "../../assets/ActionIcons/LinkIconDark.png";
import "./DocDetail.scss";

export default function DocDetail() {
  const { doc_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [doc, setDoc] = useState({});
  const [docName, setDocName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [docUrlValid, setDocUrlValid] = useState(true);
  const [docBoost, setDocBoost] = useState(0);
  const [docBoostValid, setDocBoostValid] = useState(true);
  const [docNameValid, setDocNameValid] = useState(true);
  const [docQuestions, setDocQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSaveBar, setShowSaveBar] = useState(false);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/documents/doc/${doc_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.message) {
            console.log("error");
            toast.error("There was an error loading the document.");
            navigate("/bases");
          }
          setDoc(response.data);
          setDocName(response.data.title);
          setDocUrl(response.data.url);
          setDocBoost(response.data.boost);
          setDocQuestions(
            response.data.questions != null
              ? response.data.questions
              : ["", "", ""]
          );
          // console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateDocName = (value) => {
    return value.trim() !== "" && value.length <= 50;
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setDocName(value);
    const valid = validateDocName(value);
    setDocNameValid(valid);
    if (valid) {
      setShowSaveBar(true);
    } else {
      setShowSaveBar(false);
    }
  };

  const validateDocUrl = (value) => {
    // Example: check if URL is valid or empty (allow empty URL)
    const urlRegex = /^(https?:\/\/)?[\w-]+(\.[\w-]+)+[/#?]?.*$/;
    return value.trim() === "" || urlRegex.test(value);
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setDocUrl(value);
    const valid = validateDocUrl(value);
    setDocUrlValid(valid);
    if (valid) {
      setShowSaveBar(true);
    } else {
      setShowSaveBar(false);
    }
  };

  const handleBoostChange = (e) => {
    const value = e.target.value;
    setDocBoost(value);
    const valid = validateDocBoost(value);
    setDocBoostValid(valid);
    if (valid) {
      setShowSaveBar(true);
    } else {
      setShowSaveBar(false);
    }
  };

  const validateDocBoost = (value) => {
    // Example: check if boost value is between 0 and 50 (inclusive)
    const intValue = parseInt(value, 10);
    return !isNaN(intValue) && intValue >= 0 && intValue <= 50;
  };

  const handleDiscardChanges = () => {
    setDocName(doc.title);
    setDocUrl(doc.url);
    setDocBoost(doc.boost);
    setDocQuestions(doc.questions != null ? doc.questions : ["", "", ""]);
    setShowSaveBar(false);
  };

  const handleUpdateDoc = async () => {
    await axios
      .put(
        BASE_API_URL + `/documents/doc/${doc_id}`,
        {
          title: docName,
          url: docUrl,
          questions: docQuestions,
          boost: docBoost,
        },
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      });
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          {showSaveBar ? (
            <SaveBar
              onDiscard={handleDiscardChanges}
              onSave={handleUpdateDoc}
            />
          ) : null}
          <Breadcrumb>
            <Breadcrumb.Item href="/bases">All Knowledge Bases</Breadcrumb.Item>
            <Breadcrumb.Item href={"/bases/" + doc["base_id"]}>
              Knowledge Base
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{docName}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="doc-title-row">
            <div className="title-area">
              <div>
                <h6>Document Name:</h6>
                <h2>{docName}</h2>
              </div>
            </div>
            <div className="base-buttons"></div>
          </div>
          <div className="doc-fields-container">
            <div className="active-column">
              <div className="form-context">
                <Form.Label>Document Text:</Form.Label>
                <InfoBadge info="The text that has been processed for the corresponding document divided into sections. Note, key context may be duplicated across sections of a document intentionally."></InfoBadge>
              </div>
              <div className="section-text-column">
                <div className="html-text">
                  {doc.sections.map((section, index) => {
                    return (
                      <div className="section-text" key={index}>
                        <span
                          key={index}
                          id={doc_id + index.toString()}
                          dangerouslySetInnerHTML={{
                            __html: (
                              section.section_raw_content + "<br/><br/>"
                            ).replaceAll("/n", "<br/><br/>"),
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="inactive-column">
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Document Name:</Form.Label>
                  <InfoBadge info="Give your document a name that you can identify it by."></InfoBadge>
                </div>
                <Form.Control
                  value={docName}
                  onChange={(e) => handleNameChange(e)}
                  onBlur={(e) => handleNameChange(e)}
                  isInvalid={!docNameValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a, non-blank, name for this document that is less
                  than 50 characters
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Document Destination URL:</Form.Label>
                  <div className="doc-link-separator">
                    <a href={docUrl} target="_blank" rel="noreferrer">
                      <img src={LinkIconDark} alt="Link Icon" />
                    </a>
                    <InfoBadge info="Paste the URL you would like to include whenever this document is listed as a source for an answer. If you leave this field blank, the link will take users to a Q&A session with this document."></InfoBadge>
                  </div>
                </div>
                <Form.Control
                  value={docUrl}
                  onChange={(e) => handleUrlChange(e)}
                  onBlur={(e) => handleUrlChange(e)}
                  isInvalid={!docUrlValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid URL or leave it empty.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Document Boost (0-50):</Form.Label>
                  <InfoBadge info="Boosted documents will appear more frequently in answers and sources. A setting of 0 equates to no boost. The maximum accepted boost value is 50."></InfoBadge>
                </div>
                <Form.Control
                  value={docBoost}
                  onChange={(e) => handleBoostChange(e)}
                  onBlur={(e) => handleBoostChange(e)}
                  isInvalid={!docBoostValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid boost value between 0 and 50.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Document Type:</Form.Label>
                  <InfoBadge info="The file type of the soruce document. Non-editable."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={formatString(doc.doc_type)}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Document Status:</Form.Label>
                  <InfoBadge info="The current status of the document. Non-editable."></InfoBadge>
                </div>
                <Form.Control placeholder={doc.status} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Created At:</Form.Label>
                  <InfoBadge info="When the document was created. Non-editable."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={formatTimestamp(doc.created_at)}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Last Updated:</Form.Label>
                  <InfoBadge info="When the document settings were last updated. Non-editable."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={formatTimestamp(doc.last_modified)}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Last Processed:</Form.Label>
                  <InfoBadge info="When the document's text was last processed. Non-editable."></InfoBadge>
                </div>
                <Form.Control
                  placeholder={formatTimestamp(doc.last_processed)}
                  disabled
                />
              </Form.Group>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
