import axios from "axios";
import Userfront from "@userfront/react";

const BASE_API_URL = process.env.REACT_APP_API_URL;

export const get_answer = async ({
  question,
  id_constraint,
  session_type,
  show_links,
  show_answers,
  session_id,
  speed = "medium",
  answer_length = 150,
}) => {
  try {
    // console.log("Generating get answer request");
    var body = new FormData();
    body.append("question", question);
    body.append("id_constraint", id_constraint);
    body.append("session_type", session_type);
    body.append("show_links", show_links);
    body.append("show_answers", show_answers);
    body.append("session_id", session_id);
    body.append("speed", speed);
    body.append("answer_length", answer_length);
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/questions",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    });
    // console.log("Receieved an answer response");
    // console.log(response);
    return { response };
  } catch (err) {
    return { err: err };
  }
};
