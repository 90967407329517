import React from "react";
import { useDropzone } from "react-dropzone";

import "./DropZone.scss";

export default function DropZone({ onDrop, acceptedFileList }) {
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    maxSize: 26214400,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "video/mp4": [".mp4"],
      "audio/MPA": [".mpa4"],
      "audio/wav": [".wav"],
      "video/webm": [".webm"],
      "video/mpeg": [".mpeg"],
      "audo/mpeg": [".mp3"],
      "audio/x-m4a": [".m4a"],
      "audio/mpeg": [".mpga"],
    },
    onDrop,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {(file.size / 1024 / 1024).toFixed(2)} megabytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className="container">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="drop-container"
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(25MB per file limit)</em>
      </div>
      <aside className="file-tracker">
        {acceptedFileList.length > 0 ? (
          <div>
            <h5>Files ready for upload:</h5>
            {console.log(acceptedFileList)}
            <ul>
              {acceptedFileList.map((file, index) => {
                return <li key={index}>{file.name}</li>;
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
        {fileRejectionItems.length > 0 ? (
          <div>
            <h5>Rejected files:</h5>
            <ul>{fileRejectionItems}</ul>
          </div>
        ) : (
          <></>
        )}
      </aside>
    </section>
  );
}
