import { Button, Spinner } from "react-bootstrap";
import React from "react";

import CloseIcon from "../../assets/close.png";

import "./ConfirmModal.scss";

const ConfirmModal = ({ toggleModal, loading, onClick }) => {
  return (
    <div className="confirm-modal-wrap" onClick={() => toggleModal(false)}>
      <div className="confirm-modal" onClick={(e) => e.stopPropagation()}>
        <div className="confirm-modal-head">
          <h6 className="confirm-modal-heading">Save Widget</h6>
          <img
            onClick={() => toggleModal(false)}
            src={CloseIcon}
            alt="close"
            className="confirm-modal-close-icon"
          />
        </div>
        <div className="confirm-modal-body">
          <p className="confirm-modal-text">
            Do you want to save the widget first?
          </p>
          <div className="btns">
            <Button onClick={onClick} className="primary-button">
              {loading ? <Spinner animation="border" /> : "Save Widget"}
            </Button>
            <Button
              onClick={() => toggleModal(false)}
              className="secondary-button"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
