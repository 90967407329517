import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const DayScheduler = ({ key, day, timeData, handleTimeChange }) => {
  console.log("timedata", timeData);
  return (
    <Row className="mb-3" key={key}>
      <Col>{day}</Col>
      <Col>
        <Form.Control
          type="time"
          value={
            timeData && timeData[`${day}-start`] ? timeData[`${day}-start`] : ""
          }
          name={`${day}-start`}
          onChange={(e) => handleTimeChange(e)}
        />
      </Col>
      <Col>
        <Form.Control
          type="time"
          name={`${day}-end`}
          value={
            timeData && timeData[`${day}-end`] ? timeData[`${day}-end`] : ""
          }
          onChange={(e) => handleTimeChange(e)}
        />
      </Col>
    </Row>
  );
};

export default DayScheduler;
