import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import Userfront from "@userfront/react";
import Spinner from "react-bootstrap/Spinner";
import SaveBar from "../../components/SaveBar/SaveBar";

import DetailedOptions from "./DetailedOptions/DetailedOptions";
import DataSources from "./DataSources/DataSources";
import BehaviorSettings from "./BehaviorSettings/BehaviorSettings";
import SuggestedOptions from "./SuggestedOptions/SuggestedOptions";
import GuardrailsOptions from "../GuardrailsOptions/GuardrailsOptions";
import Cache from "../../pages/Cache/Cache";
import toast, { Toaster } from "react-hot-toast";
import "./BotSettings.scss";
import TemplateSettings from "./TemplateSettings/TemplateSettings";
import SuggestedTopics from "./SuggestedTopics/SuggestedTopics";

const BotSettings = () => {
  const { bot_id } = useParams();
  const [key, setKey] = useState("datasources");
  const [loading, setLoading] = useState(true);
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [bases, setBases] = useState([]);
  const [originalBot, setOriginalBot] = useState({}); // used to compare changes
  const [bot, setBot] = useState({});
  const [showSaveBar, setShowSaveBar] = useState(false);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/${bot_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((res) => {
          let tempBot = res.data;
          tempBot.categories =
            tempBot.categories === "None" ? [] : JSON.parse(tempBot.categories);
          tempBot.activeBases =
            tempBot.base_ids === "None" ? [] : JSON.parse(tempBot.base_ids);
          console.log(tempBot.activeBases);
          tempBot.glossary = JSON.parse(tempBot.glossary.replace(/'/g, '"'));
          tempBot.exclusion_phrases = JSON.parse(tempBot.exclusion_phrases);
          console.log("tempBot", tempBot.macro_kbs);
          tempBot.macro_kbs = tempBot.macro_kbs.replace(/'/g, '"');
          tempBot.macro_kbs = Array.isArray(tempBot.macro_kbs)
            ? tempBot.macro_kbs
            : JSON.parse(tempBot.macro_kbs);
          tempBot.default_questions =
            tempBot.default_questions === "None"
              ? []
              : JSON.parse(tempBot.default_questions);
          tempBot.verified_answers =
            tempBot.verified_answers === "None"
              ? []
              : JSON.parse(
                  tempBot.verified_answers
                    .replace(/False/g, false)
                    .replace(/True/g, true)
                );
          tempBot.handoff_settings = tempBot.handoff_settings
            ? JSON.parse(tempBot.handoff_settings)
            : {};
          setBot(tempBot);
          setOriginalBot(tempBot);
          console.log("start bot", tempBot);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          // console.log(response.data);
          setBases(response.data);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = (field, value) => {
    setBot((prevBot) => ({
      ...prevBot,
      [field]: value,
    }));
    setShowSaveBar(true);
  };

  const handleDiscardChanges = () => {
    setBot(originalBot);
    setShowSaveBar(false);
  };

  const handleUpdateBot = async () => {
    // bot.macro_kbs = JSON.stringify(bot.macro_kbs);
    bot.suggested_questions = bot.default_questions;
    // bot.verified_answers = JSON.stringify(bot.verified_answers);
    bot.base_ids = bot.activeBases;
    bot.handoff_settings = JSON.stringify(bot.handoff_settings);
    console.log("bot", bot);
    await axios
      .put(
        BASE_API_URL + `/bots/${bot_id}`,
        {
          ...bot,
        },
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        bot.handoff_settings = JSON.parse(bot.handoff_settings);
        setOriginalBot(bot); // save the state of our bot as the original bot
        setShowSaveBar(false); // hide the save bar
      });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(bot_id);
    toast.success("Copied to clipboard");
  };

  return (
    <div className="bot-settings-container">
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <div>
          <div className="title-area">
            <div>
              <h6>Bot Name:</h6>
              <h2>{bot.bot_title ? bot.bot_title : ""}</h2>
            </div>
            <Button onClick={copyToClipboard} className="open-button">
              Copy API Key
            </Button>
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="datasources" title="Data Sources">
              <DataSources
                bot={bot}
                bases={bases}
                handleUpdate={handleUpdate}
              />
            </Tab>
            {bot.objective === "Support Representative (Internal)" ? (
              <Tab eventKey="templates" title="Templates">
                <TemplateSettings
                  bot={bot}
                  bases={bases}
                  handleUpdate={handleUpdate}
                />
              </Tab>
            ) : null}
            <Tab eventKey="behavior" title="Behavior">
              <BehaviorSettings bot={bot} handleUpdate={handleUpdate} />
            </Tab>
            {bot.objective === "Support Representative (Internal)" ? (
              <Tab eventKey="suggestions" title="Suggestions">
                <SuggestedOptions bot={bot} handleUpdate={handleUpdate} />
              </Tab>
            ) : null}
            {bot.objective !== "Support Representative (Internal)" ? (
              <Tab eventKey="guardrails" title="Guardrails">
                <GuardrailsOptions bot={bot} handleUpdate={handleUpdate} />
              </Tab>
            ) : null}
            {bot.objective !== "Support Representative (Internal)" ? (
              <Tab eventKey="scriptedresponses" title="Scripted Responses">
                <Cache />
                <SuggestedTopics bot={bot} handleUpdate={handleUpdate} />
              </Tab>
            ) : null}
            <Tab eventKey="detailedoptions" title="Detailed Options">
              <DetailedOptions bot={bot} handleUpdate={handleUpdate} />
            </Tab>
          </Tabs>
          {showSaveBar ? (
            <SaveBar
              onDiscard={handleDiscardChanges}
              onSave={handleUpdateBot}
            />
          ) : null}
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default BotSettings;
