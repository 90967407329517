// ImportOption.js
import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import "./ImportOption.scss";
function ImportOption({ link, icon, name, linkState }) {
  return (
    <Link to={link} state={linkState}>
      <Card className="import-option-card">
        <Card.Img variant="top" src={icon} className="import-option-image" />
        <Card.Body className="import-option-body">
          <Card.Title className="import-option-title">{name}</Card.Title>
        </Card.Body>
      </Card>
    </Link>
  );
}

export default ImportOption;
