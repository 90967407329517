import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import ModalSaveBar from "../../components/ModalSaveBar/ModalSaveBar";

import "./CacheVariant.scss";

export default function Cache({ cache_id }) {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const { bot_id } = useParams();
  // const { cache_id } = useParams();

  const [cacheItem, setCacheItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalSaveBar, setShowSaveBar] = useState(false);
  const [originalCacheItem, setOriginalCacheItem] = useState([]);

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/${bot_id}/cache/${cache_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setCacheItem(response.data);
          setOriginalCacheItem(response.data);
          // console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleDelete = async (count) => {
  //   (async () => {
  //     await axios
  //       .delete(
  //         BASE_API_URL +
  //           `/bots/${bot_id}/cache/${cacheItem.cach_id}/variant/${cacheItem.variants[count].cache_variant_id}`,
  //         {
  //           headers: {
  //             authorization: `Bearer ${Userfront.tokens.accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // console.log(response.data);
  //         window.location.reload();
  //       });
  //   })();
  // };

  const handleDelete = async (index) => {
    const variantToDelete = cacheItem.variants[index];
    await axios
      .delete(
        `${BASE_API_URL}/bots/${bot_id}/cache/${cacheItem.cache_id}/variant/${variantToDelete.cache_variant_id}`,
        {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        }
      )
      .then((response) => {
        const updatedVariants = cacheItem.variants.filter((variant) => {
          return variant.cache_variant_id !== variantToDelete.cache_variant_id;
        });
        setCacheItem({ ...cacheItem, variants: updatedVariants });
      });
  };

  const [showAdd, setShowAdd] = useState(false);

  const handleShowAdd = () => setShowAdd(true);
  const handleCloseShowAdd = () => setShowAdd(false);

  const [newVariant, setNewVariant] = useState({
    question: "",
  });

  const handleQuesitonChange = (e) => {
    setNewVariant({ ...newVariant, question: e.target.value });
  };

  // const handleAddItem = async () => {
  //   (async () => {
  //     await axios
  //       .post(
  //         BASE_API_URL + `/bots/${bot_id}/cache/${cacheItem.cache_id}/variant`,
  //         {
  //           variant_question: newVariant.question,
  //         },
  //         {
  //           headers: {
  //             authorization: `Bearer ${Userfront.tokens.accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // console.log(response.data);
  //         window.location.reload();
  //       });
  //   })();
  // };

  const handleAddItem = async () => {
    await axios
      .post(
        BASE_API_URL + `/bots/${bot_id}/cache/${cacheItem.cache_id}/variant`,
        {
          variant_question: newVariant.question,
        },
        {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        }
      )
      .then((response) => {
        setNewVariant({
          question: "",
        });
        handleCloseShowAdd();
        setCacheItem({
          ...cacheItem,
          variants: [...cacheItem.variants, response.data],
        });
      });
  };

  const handleItemAnswerChange = (e) => {
    setShowSaveBar(true);
    setCacheItem({ ...cacheItem, answer: e.target.value });
  };

  const handleItemQuestionChange = (e) => {
    setShowSaveBar(true);
    setCacheItem({ ...cacheItem, question: e.target.value });
  };

  const handleDiscardChanges = () => {
    setCacheItem(originalCacheItem);
    setShowSaveBar(false);
  };

  const handleUpdateItem = async () => {
    (async () => {
      await axios
        .put(
          BASE_API_URL + `/bots/${bot_id}/cache/${cacheItem.cache_id}`,
          {
            question: cacheItem.question,
            answer: cacheItem.answer,
            status: cacheItem.status,
          },
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          window.location.reload();
        });
    })();
  };

  return (
    <div className="cache">
      {showModalSaveBar ? (
        <ModalSaveBar
          onDiscard={handleDiscardChanges}
          onSave={handleUpdateItem}
        />
      ) : null}
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          {/* <Breadcrumb>
            <Breadcrumb.Item href="/agents">All Agents</Breadcrumb.Item>
            <Breadcrumb.Item active>Agent</Breadcrumb.Item>
            <Breadcrumb.Item active>Scripted Items</Breadcrumb.Item>
            <Breadcrumb.Item active>Item</Breadcrumb.Item>
          </Breadcrumb> */}
          {/* <div className="title-row">
            <h2>Modify Scripted Response</h2>
          </div> */}
          <div className="scripted-item-fields">
            <div>
              <div className="form-context">
                <h5>Primary Question</h5>
                <InfoBadge info="The question that will trigger this scripted answer."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                value={cacheItem.question}
                onChange={handleItemQuestionChange}
              />
            </div>
            <div>
              <div className="form-context">
                <h5>Scripted Answer</h5>
                <InfoBadge info="The answer that will be returned when the question is asked."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                as="textarea"
                className="scripted-item-answer"
                value={cacheItem.answer}
                onChange={handleItemAnswerChange}
              />
            </div>
          </div>
          <div className="title-row">
            <h3>Question Variants</h3>
            <Button className="primary-button" onClick={handleShowAdd}>
              Add Question Variation
            </Button>
          </div>
          <Table hover striped bordered size="lg" className="base-table">
            <thead>
              <tr>
                <th>Question Variations</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cacheItem.variants.map((item, count) => (
                <tr key={count}>
                  <td>{item.variant_question}</td>
                  <td>
                    <div className="action-panel">
                      <div className="cache-buttons">
                        <Button
                          className="open-button script-item-button"
                          onClick={() => handleDelete(count)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <Modal show={showAdd} centered onHide={handleCloseShowAdd}>
        <Modal.Header closeButton>
          <Modal.Title>Add Question Variation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Question Variation</Form.Label>
                <InfoBadge info="Including alternative phrasings of a question help match your scripted answer to a wider variety of question phrasings."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                onChange={handleQuesitonChange}
                value={newVariant.question}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="secondary-button" onClick={handleCloseShowAdd}>
            Cancel
          </Button>
          <Button className="primary-button" onClick={handleAddItem}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
