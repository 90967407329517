import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import SaveBar from "../../components/SaveBar/SaveBar";

import "./Tag.scss";

export default function Tag() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const { tag_id } = useParams();
  const [tag, setTag] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [availableBots, setAvailableBots] = useState([]);
  const [activeBots, setActiveBots] = useState([]);

  const isBotActive = (bot_id) => {
    let isActive = false;
    activeBots.forEach((bot) => {
      if (bot.bot_id === bot_id) {
        isActive = true;
      }
    });
    return isActive;
  };

  const handleTagToBotChange = (bot_id) => {
    if (isBotActive(bot_id)) {
      // remove bot from active bots
      setActiveBots(activeBots.filter((bot) => bot.bot_id !== bot_id));
    } else {
      // add bot to active bots
      setActiveBots([
        ...activeBots,
        availableBots.find((bot) => bot.bot_id === bot_id),
      ]);
    }
    setShowSaveBar(true);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/tags/${tag_id}`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setTag({
            title: response.data.title,
            system: response.data.system ? response.data.system : "",
            options: JSON.parse(response.data.enum)
              ? JSON.parse(response.data.enum)
              : ["None"],
            examples: JSON.parse(response.data.examples)
              ? JSON.parse(response.data.examples)
              : [],
          });
          console.log(JSON.parse(response.data.examples));
        });
    })();
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setAvailableBots(response.data);
          // console.log(response.data);
        });
    })();
    (async () => {
      await axios
        .get(BASE_API_URL + `/tags/${tag_id}/bots`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setActiveBots(response.data.bots);
          // console.log(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tag.options && tag.options.length > 0) {
      setNewExample((prevExample) => ({
        ...prevExample,
        label: { tag: tag.options[0] },
      }));
    }
  }, [tag.options]);

  const handleUpdate = async () => {
    setLoading(true);
    const clean_options = tag.options.map((option) =>
      option.trim().replace(/\s/g, "_")
    );
    console.log("Clean Options", clean_options);
    (async () => {
      const data = {
        title: tag.title,
        system: tag.system,
        enum: JSON.stringify(clean_options),
        examples: JSON.stringify(tag.examples),
      };
      await axios
        .put(BASE_API_URL + `/tags/${tag_id}`, data, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          //   console.log(response.data);
        });
    })();
    (async () => {
      const data = {
        bots: activeBots.map((bot) => bot.bot_id),
      };
      await axios
        .put(BASE_API_URL + `/tags/${tag_id}/bots`, data, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          //   console.log(response.data);
          window.location.reload();
        });
    })();
  };

  const handleAddItem = () => {
    let newTag = {
      ...tag,
      examples: [...tag.examples, newExample],
    };
    setTag(newTag);
    setShowAdd(false);
    setShowSaveBar(true);
    setNewExample({
      example: "",
      label: {
        tag: tag.options && tag.options.length > 0 ? tag.options[0] : "",
      },
    });
  };

  const handleDescriptionChange = (event) => {
    let newTag = {
      ...tag,
      system: event.target.value,
    };
    setTag(newTag);
    setShowSaveBar(true);
  };

  const handleDiscard = () => {
    window.location.reload();
  };

  const handleTitleChange = (event) => {
    let newTag = { ...tag, title: event.target.value };
    setTag(newTag);
    setShowSaveBar(true);
  };

  const handleDeleteExample = (index) => {
    let newTag = {
      ...tag,
      examples: tag.examples.filter((item, i) => i !== index),
    };
    setTag(newTag);
    setShowSaveBar(true);
  };

  const handleOptionsChange = (event) => {
    let newTag = {
      ...tag,
      options: event.target.value.split(", "),
    };
    setTag(newTag);
    setShowSaveBar(true);
  };

  const [showAdd, setShowAdd] = useState(false);

  const handleShowAdd = () => setShowAdd(true);
  const handleCloseShowAdd = () => {
    setShowAdd(false);
    setNewExample({
      example: "",
      label: {
        tag: tag.options && tag.options.length > 0 ? tag.options[0] : "",
      },
    });
  };

  const [newExample, setNewExample] = useState({
    example: "",
    label: { tag: tag.options && tag.options.length > 0 ? tag.options[0] : "" },
  });

  const handleUpdateExample = (event) => {
    setNewExample({ ...newExample, example: event.target.value });
  };

  const handleUpdateLabel = (status) => {
    console.log(status);
    setNewExample({
      ...newExample,
      label: { tag: status },
    });
  };

  return (
    <div className="cache">
      {showSaveBar ? (
        <SaveBar onDiscard={handleDiscard} onSave={handleUpdate} />
      ) : null}
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div className="relevance-view">
          <Breadcrumb>
            <Breadcrumb.Item href="/tags">
              All Taggging Workflows
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="title-row">
            <h2>Tagging Workflow Settings</h2>
          </div>
          <div className="tag-fields-container">
            <div className="tags-active-column">
              <div className="tag-item-fields">
                <div>
                  <div className="form-context">
                    <h5>Workflow Title</h5>
                    <InfoBadge info="A short title that summarizes this tagging workflow (e.g., Sentiment Tagging)."></InfoBadge>
                  </div>
                  <Form.Control
                    type="input"
                    value={tag.title}
                    onChange={handleTitleChange}
                  />
                </div>
                <div>
                  <div className="form-context">
                    <h5>Workflow Description</h5>
                    <InfoBadge info="Describe in a few sentences that objective of the workflow and the factors that should be considered when selecting a tag from the available options"></InfoBadge>
                  </div>
                  <Form.Control
                    type="input"
                    as="textarea"
                    className="tag-description"
                    value={tag.system}
                    onChange={handleDescriptionChange}
                  />
                </div>
                <div>
                  <div className="form-context">
                    <h5>Tag Value Options</h5>
                    <InfoBadge info="List the tag options you want the model to select from when tagging tickets. Separate options with a comma. Zendesk requires that tags contain no spaces."></InfoBadge>
                  </div>
                  <Form.Control
                    type="input"
                    as="textarea"
                    className="tag-categories"
                    value={tag.options ? tag.options.join(", ") : ""}
                    onChange={handleOptionsChange}
                  />
                </div>
              </div>
            </div>
            <div className="inactive-column">
              <div className="form-context">
                <h5>Agents to apply Workflow to:</h5>
                <InfoBadge info="Connect your workflow to specific agents in order to run the tagging workflow whenever a new ticket is passed to the specified agent(s)."></InfoBadge>
              </div>
              <div className="mb-3">
                {availableBots &&
                  availableBots.map((bot) => (
                    <Form.Check
                      type="checkbox"
                      id={bot.bot_id}
                      label={bot.agent_name}
                      key={bot.bot_id}
                      className="base-switch"
                      defaultChecked={isBotActive(bot.bot_id)}
                      onChange={() => handleTagToBotChange(bot.bot_id)}
                    />
                  ))}
              </div>
            </div>
          </div>
          {tag.options && tag.options.length < 2 ? (
            <></>
          ) : (
            <div>
              <div className="tag-title-row">
                <h3>Training Examples</h3>
                <Button className="primary-button" onClick={handleShowAdd}>
                  Add Example
                </Button>
              </div>
              {tag.examples && tag.examples.length === 0 ? (
                <div className="no-tags">
                  <h5>No examples added yet.</h5>
                </div>
              ) : (
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>Example Message</th>
                      <th>Correct Tag</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tag.examples &&
                      tag.examples.map((example, count) => (
                        <tr key={count}>
                          <td>{example.example}</td>
                          <td>{example.label.tag}</td>
                          <td>
                            <div className="action-panel">
                              <div className="cache-buttons">
                                <Button
                                  className="delete-button script-item-button"
                                  onClick={() => handleDeleteExample(count)}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              <Modal show={showAdd} centered onHide={handleCloseShowAdd}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Example</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <div className="form-context">
                        <Form.Label>Example Message</Form.Label>
                        <InfoBadge info="An example message that resembles a ticket request from your users."></InfoBadge>
                      </div>
                      <Form.Control
                        type="input"
                        onChange={handleUpdateExample}
                        value={newExample.example}
                        autoFocus
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <div className="form-context">
                        <Form.Label>Correct Tag:</Form.Label>
                        <InfoBadge info="Specify the correct tag that should be associated with this message."></InfoBadge>
                      </div>
                      <Form.Select
                        value={newExample.label.tag}
                        onChange={(e) => {
                          handleUpdateLabel(e.target.value);
                          setShowSaveBar(true);
                        }}
                      >
                        {tag.options &&
                          tag.options.map((option, count) => (
                            <option key={count} value={option}>
                              {option}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="secondary-button"
                    onClick={handleCloseShowAdd}
                  >
                    Cancel
                  </Button>
                  <Button className="primary-button" onClick={handleAddItem}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
