import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";
import "./NewCrawl.scss";

export default function NewCrawl() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [activeBase, setActiveBase] = useState();
  const [bases, setBases] = useState();
  const [loading, setLoading] = useState(true);

  const [crawlName, setCrawlName] = useState("");
  const [crawlNameValid, setCrawlNameValid] = useState(false);
  const [seeds, setSeeds] = useState("");
  const [seedsValid, setSeedsValid] = useState(false);
  const [maxToCrawl, setMaxToCrawl] = useState(2500);
  const [maxToCrawlValid, setMaxToCrawlValid] = useState(true);
  const [maxToProcess, setMaxToProcess] = useState(2500);
  const [maxToProcessValid, setMaxToProcessValid] = useState(true);
  const [maxHops, setMaxHops] = useState(-1);
  const [maxHopsValid, setMaxHopsValid] = useState(true);
  const [crawlDelay, setCrawlDelay] = useState(0.25);
  const [crawlDelayValid, setCrawlDelayValid] = useState(true);
  const [useJS, setUseJS] = useState("no");
  const [cookie, setCookie] = useState("");
  const [repeatFreq, setRepeatFreq] = useState(0.0);
  const [repeatFreqValid, setRepeatFreqValid] = useState(true);
  const [doNotCrawl, setDoNotCrawl] = useState("");
  const [doNotCrawlValid, setDoNotCrawlValid] = useState(true);
  const [doCrawl, setDoCrawl] = useState("");
  const [doCrawlValid, setDoCrawlValid] = useState(true);

  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      // console.log("base id:", base_id);
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const temp_bases = response.data;
          temp_bases.forEach((base, i) => {
            if (base.base_id === base_id) {
              temp_bases.splice(i, 1);
              temp_bases.unshift(base);
            }
          });
          setBases(temp_bases);
          setActiveBase(temp_bases[0].base_id);
          // console.log(response);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCrawl = async () => {
    if (
      !crawlNameValid ||
      !seedsValid ||
      !maxToCrawlValid ||
      !maxToProcessValid ||
      !maxHopsValid ||
      !crawlDelayValid ||
      !repeatFreqValid ||
      !doNotCrawlValid
    ) {
      toast.error("Please fix the errors in the form before submitting.");
      return;
    }
    setLoading(true);
    var body = new FormData();
    body.append("base_id", activeBase);
    body.append("crawl_name", crawlName);
    body.append("useJS", useJS);
    body.append("seeds", seeds);
    body.append("maxToCrawl", parseInt(maxToCrawl));
    body.append("maxToProcess", parseInt(maxToProcess));
    body.append("maxHops", parseInt(maxHops));
    body.append("crawlDelay", parseFloat(crawlDelay));
    body.append("urlProcessPattern", "");
    body.append("urlCrawlPattern", doCrawl);
    body.append("repeat", 0);
    body.append("repeat_freq", parseFloat(repeatFreq));
    body.append("cookie", cookie);
    body.append("do_not_crawl_pattern", doNotCrawl);

    await axios({
      method: "post",
      url: BASE_API_URL + "/crawls",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    })
      .then((response) => {
        toast.success("Crawl created successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.status === 403) {
          toast.error("An enterprise plan is required to create crawls.");
        } else {
          toast.error("An error occurred while creating the crawl.");
        }
      });
  };

  const validateCrawlName = (name) => {
    return name.trim() !== "" && name.length <= 50;
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setCrawlName(value);
    const valid = validateCrawlName(value);
    setCrawlNameValid(valid);
  };

  const on_js_change = (e) => {
    const value = e.target.value;
    setUseJS(value);
  };

  const validateSeeds = (seeds) => {
    // Example: check if URL is valid or empty (allow empty URL)
    const urlRegex = /^(https?:\/\/)?[\w-]+(\.[\w-]+)+[/#?]?.*$/;
    return seeds.trim() === "" || urlRegex.test(seeds);
  };

  const handleSeedChange = (e) => {
    const value = e.target.value;
    setSeeds(value);
    const valid = validateSeeds(value);
    setSeedsValid(valid);
  };

  const validateDelay = (delay) => {
    return delay >= 0.1 && delay <= 2;
  };

  const handleDelayChange = (e) => {
    const value = e.target.value;
    setCrawlDelay(value);
    const valid = validateDelay(value);
    setCrawlDelayValid(valid);
  };

  const validateMaxToCrawl = (max) => {
    return max >= 10 && max <= 10000;
  };

  const handleMaxToCrawlChange = (e) => {
    const value = e.target.value;
    setMaxToCrawl(value);
    const valid = validateMaxToCrawl(value);
    setMaxToCrawlValid(valid);
  };

  const validateMaxToProcess = (max) => {
    return max >= 10 && max <= 10000;
  };

  const handleMaxToProcessChange = (e) => {
    const value = e.target.value;
    setMaxToProcess(value);
    const valid = validateMaxToProcess(value);
    setMaxToProcessValid(valid);
  };

  const validateMaxHops = (max) => {
    return max === -1 || max >= 0;
  };

  const handleMaxHopsChange = (e) => {
    const value = e.target.value;
    setMaxHops(value);
    const valid = validateMaxHops(value);
    setMaxHopsValid(valid);
  };

  const on_base_change = (e) => {
    // console.log("active base changed to: " + e.target.value);
    setActiveBase(e.target.value);
  };

  const handleCookieChange = (e) => {
    const value = e.target.value;
    setCookie(value);
  };

  const validateRepeatFreq = (freq) => {
    return freq >= 0 && freq < 365;
  };

  const handleRepeatFreqChange = (e) => {
    const value = e.target.value;
    setRepeatFreq(value);
    const valid = validateRepeatFreq(value);
    setRepeatFreqValid(valid);
  };

  const validateDoNotCrawl = (pattern) => {
    return pattern.length <= 100;
  };

  const handleDoNotCrawlChange = (e) => {
    const value = e.target.value;
    setDoNotCrawl(value);
    const valid = validateDoNotCrawl(value);
    setDoNotCrawlValid(valid);
  };

  const validateDoCrawl = (pattern) => {
    return pattern.length <= 100;
  };

  const handleDoCrawlChange = (e) => {
    const value = e.target.value;
    setDoCrawl(value);
    const valid = validateDoCrawl(value);
    setDoCrawlValid(valid);
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <Breadcrumb className="">
            <Breadcrumb.Item href="/bases">All Knowledge Bases</Breadcrumb.Item>
            <Breadcrumb.Item href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Add Crawl</Breadcrumb.Item>
          </Breadcrumb>
          <h1>New Crawl</h1>
          <div className="new-crawl-settings">
            <div className="crawl-setting-column">
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Base Name:</Form.Label>
                  <InfoBadge info="Select the knowledge base that documents from this crawl will be added to."></InfoBadge>
                </div>
                <Form.Select
                  size="md"
                  className="option"
                  onChange={on_base_change}
                >
                  {bases.map((base, index) => {
                    return (
                      <option key={index} value={base.base_id}>
                        {base.base_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Crawl Name:</Form.Label>
                  <InfoBadge info="Give your crawl a name that will identify it clearly."></InfoBadge>
                </div>
                <Form.Control
                  value={crawlName}
                  onChange={(e) => handleNameChange(e)}
                  onBlur={(e) => handleNameChange(e)}
                  isInvalid={!crawlNameValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a, non-blank, name for this crawl that is less
                  than 50 characters
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Start URL:</Form.Label>
                  <InfoBadge info="Specify the URL you want to crawl."></InfoBadge>
                </div>
                <Form.Control
                  value={seeds}
                  onChange={(e) => handleSeedChange(e)}
                  onBlur={(e) => handleSeedChange(e)}
                  isInvalid={!seedsValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a, non-blank, valid start URL for this crawl
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Do Not Crawl Pattern:</Form.Label>
                  <InfoBadge info="Avoid crawling certain pages that match the provided string (e.g., '/category' will avoid any URLs containing '/category')"></InfoBadge>
                </div>
                <Form.Control
                  value={doNotCrawl}
                  onChange={(e) => handleDoNotCrawlChange(e)}
                  onBlur={(e) => handleDoNotCrawlChange(e)}
                  isInvalid={!doNotCrawlValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a string less than 100 characters.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Do Crawl Pattern:</Form.Label>
                  <InfoBadge info="Limit crawling to pages that match the provided string (e.g., '/category' will avoid any URLs containing '/category')"></InfoBadge>
                </div>
                <Form.Control
                  value={doCrawl}
                  onChange={(e) => handleDoCrawlChange(e)}
                  onBlur={(e) => handleDoCrawlChange(e)}
                  isInvalid={!doCrawlValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a string less than 100 characters.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Use Javascript:</Form.Label>
                  <InfoBadge info="Will instruct crawler to process Javscript in addition to HTML (resulting in slower crawl). Javscript should be used in situations in which a non-javascript crawl fails."></InfoBadge>
                </div>
                <Form.Select
                  size="md"
                  className="option"
                  onChange={(e) => on_js_change(e)}
                  value={useJS}
                >
                  <option key={0} value={"no"}>
                    No
                  </option>
                  <option key={1} value={"yes"}>
                    Yes
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="crawl-setting-column">
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Max URLs to Crawl:</Form.Label>
                  <InfoBadge info="Specify the maximum number of URLs for to crawl."></InfoBadge>
                </div>
                <Form.Control
                  value={maxToCrawl}
                  onChange={(e) => handleMaxToCrawlChange(e)}
                  onBlur={(e) => handleMaxToCrawlChange(e)}
                  isInvalid={!maxToCrawlValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a max to crawl value between 10 and 10000.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Max URLs to Process:</Form.Label>
                  <InfoBadge info="Specify the maximum number of URLs for RightPage to process."></InfoBadge>
                </div>
                <Form.Control
                  value={maxToProcess}
                  onChange={(e) => handleMaxToProcessChange(e)}
                  onBlur={(e) => handleMaxToProcessChange(e)}
                  isInvalid={!maxToProcessValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a max to process value between 10 and 10000.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Max Hops:</Form.Label>
                  <InfoBadge info="Specify the maximum number of levels of descendent URLs to crawl (-1 means there is no limit)."></InfoBadge>
                </div>
                <Form.Control
                  value={maxHops}
                  onChange={(e) => handleMaxHopsChange(e)}
                  onBlur={(e) => handleMaxHopsChange(e)}
                  isInvalid={!maxHopsValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a max hops value between greater than 0 or equal
                  to -1.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Crawl Delay:</Form.Label>
                  <InfoBadge info="Specify the length of time between page requests."></InfoBadge>
                </div>
                <Form.Control
                  value={crawlDelay}
                  onChange={(e) => handleDelayChange(e)}
                  onBlur={(e) => handleDelayChange(e)}
                  isInvalid={!crawlDelayValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a crawl delay between 0.1 and 2.0 seconds
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Cookie:</Form.Label>
                  <InfoBadge info="Optionally include a valid authentication cookie string to allow the crawl to access pages that require authentication"></InfoBadge>
                </div>
                <Form.Control
                  value={cookie}
                  onChange={(e) => handleCookieChange(e)}
                  onBlur={(e) => handleCookieChange(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="form-context">
                  <Form.Label>Repeat Frequency:</Form.Label>
                  <InfoBadge info="Specify the number of days to repeat this crawl. A value of 0 means the crawl will not be repeated."></InfoBadge>
                </div>
                <Form.Control
                  value={repeatFreq}
                  onChange={(e) => handleRepeatFreqChange(e)}
                  onBlur={(e) => handleRepeatFreqChange(e)}
                  isInvalid={!repeatFreqValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a number equal to or greater than 0 and less than
                  365
                </Form.Control.Feedback>
              </Form.Group>
            </div>{" "}
          </div>
          <Button
            className="primary-button new-crawl-button"
            onClick={() => createCrawl()}
          >
            Save and Start Crawl
          </Button>
        </>
      )}
      <Toaster />
    </div>
  );
}
