import UserFront from "@userfront/react";

UserFront.init("7n87xwyb");

const PasswordReset = UserFront.build({
  toolId: "raamnan",
});

export default function PasswordResetForm() {
  return (
    <div className="Centered-Form">
      <img
        src={require("../../assets/RightPage-Logo-Dark.png")}
        alt="RightPage Logo"
        className="center-logo"
      />
      <PasswordReset />
    </div>
  );
}
