import { Dropdown } from "react-bootstrap";
import React from "react";

import "./Select.scss";

const Select = ({ label, value, options, setValue, disabled, setSave }) => {
  return (
    <div className="select-wrap">
      <p className="select-label">{label}</p>
      <Dropdown className="select-dd">
        <Dropdown.Toggle
          disabled={disabled}
          className={`select ${disabled ? "select-disabled" : ""}`}
        >
          <span>{value.agent_name ? value.agent_name : value}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="select-menu">
          {options &&
            options.map((item, index) => (
              <Dropdown.Item
                as="div"
                className="select-item-ind"
                key={index}
                onClick={() => {
                  setValue(item);
                  setSave && setSave(true);
                }}
              >
                {item.agent_name ? item.agent_name : item}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Select;
