import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import Modal from "react-bootstrap/Modal";
import Userfront from "@userfront/react";
import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import toast, { Toaster } from "react-hot-toast";
import debounce from "lodash.debounce";
import IntegrationConfig from "./IntegrationConfig/IntegrationConfig";
import "./Integrations.scss";
import AddIconLight from "../../assets/ActionIcons/AddIconLight.png";

import EmptyState from "../../components/EmptyState/EmptyState";

export default function KnowledgeBase() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const NOTION_CLIENT_ID = process.env.REACT_APP_NOTION_CLIENT_ID;
  const WEBHOOK_URL = process.env.REACT_APP_WEBHOOK_URL;

  const [loading, setLoading] = useState(true);
  const [zenDeskField, setZenDeskField] = useState("");
  const [zenDeskShow, setZenDeskShow] = useState(false);
  const [integrations, setIntegrations] = useState([{}]);
  const [zenDeskEmail, setZenDeskEmail] = useState([""]);
  const [zendeskSubdomain, setZendeskSubdomain] = useState([""]);
  const [tokenFieldError, setTokenFieldError] = useState(null);
  const [subDomainInputError, setSubDomainInputError] = useState(null);
  // const [closeModal, setCloseModal] = useState(false);
  const [webhooks, setWebhooks] = useState([{}]);
  const [brands, setBrands] = useState([{}]);
  const [currentWebhook, setCurrentWebhook] = useState({
    api_key: "",
    brand_id: null,
    brand_name: null,
  });
  const [zendeskIntegration, setZendeskIntegration] = useState([
    {
      id: null,
      type: "integrations_zendesk",
      enabled: false,
    },
  ]);

  const [bots, setBots] = useState([{}]);
  const debouncedToastError = debounce(
    (message) => toast.error(message),
    1000,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/integrations/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setIntegrations(response.data);
          setZendeskIntegration(
            response.data.filter(
              (integration) => integration.type === "integrations_zendesk"
            )[0]
          );
        });
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          if (response.data.length === 0) {
            debouncedToastError(
              "No bots found. Please create at least one agent assist bot before creating a setting up a Zendesk integration."
            );
            setBots([]);
          } else {
            const filteredAgents = response.data;
            setBots(filteredAgents);
            setCurrentWebhook({
              ...currentWebhook,
              api_key: response.data[0].bot_id,
            });
          }
        });
      await axios
        .get(BASE_API_URL + `/integrations/webhooks`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          setWebhooks(response.data);
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleZenDeskClose = () => setZenDeskShow(false);
  const handleZenDeskShow = () => {
    if (bots.length === 0) {
      toast.error(
        "No bots found. Please create at least one agent assist bot before configuring a Zendesk integration."
      );
      return;
    } else {
      setZenDeskShow(true);
    }
  };

  const handleSubDomainChange = (e) => {
    const re = /^[0-9a-zA-Z]+$/;
    setZendeskSubdomain(e.target.value);
    if (re.test(String(e.target.value))) {
      setSubDomainInputError(null);
    } else {
      setSubDomainInputError("Subdomain must be alphanumeric");
    }
  };

  const [emailFieldError, setEmailFieldError] = useState(null);

  const handleEmailChange = (e) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setZenDeskEmail(e.target.value);
    if (re.test(String(e.target.value).toLowerCase())) {
      setEmailFieldError(null);
    } else {
      setEmailFieldError("Invalid email address.");
    }
  };

  const handleTokenChange = (e) => {
    setZenDeskField(e.target.value);
    if (e.target.value.length !== 40) {
      setTokenFieldError(
        "Invalid token length. Please copy and paste the token from your Zendesk account."
      );
    } else {
      setTokenFieldError(null);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Zendesk integration id", zendeskIntegration);
    if (zendeskIntegration.id === null) {
      const data = {
        token: zenDeskField,
        system: "integrations_zendesk",
        email: zenDeskEmail,
        domain: zendeskSubdomain,
      };
      await axios
        .post(BASE_API_URL + `/integrations/`, data, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          toast.success("Integration created successfully");
          setZenDeskShow(false);
          setZenDeskField("");
          window.location.reload();
        })
        .catch((error) => {
          toast.error("Error setting integration.");
        });
    } else {
      const data = {
        token: zenDeskField,
        email: zenDeskEmail,
        domain: zendeskSubdomain,
      };
      await axios
        .put(BASE_API_URL + `/integrations/${zendeskIntegration.id}`, data, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          setZenDeskShow(false);
          setZenDeskField("");
          toast.success("Integration updated successfully");
          window.location.reload();
        })
        .catch((error) => {
          toast.error("Error updating integration.");
        });
    }
  };

  const onConfigSubmit = async (integrationId, data) => {
    if (!data.token) {
      // Guard clause to prevent false form submissions
      return;
    }

    // Checking if integration is new or exists
    if (integrationId === null) {
      try {
        // Post new integration
        const response = await axios.post(
          BASE_API_URL + `/integrations/`,
          data,
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Integration created successfully");
          // reload integrations
          window.location.reload();
        } else {
          toast.error("Error creating integration");
        }
      } catch (error) {
        toast.error("Error creating integration");
      }
    } else {
      try {
        // Update existing integration
        const response = await axios.put(
          BASE_API_URL + `/integrations/${integrationId}`,
          data,
          {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Integration updated successfully");
          // reload integrations
          window.location.reload();
        } else {
          toast.error("Error updating integration");
        }
      } catch (error) {
        toast.error("Error updating integration");
      }
    }
  };

  const handleDisableIntegration = async (integration_id) => {
    await axios
      .delete(BASE_API_URL + `/integrations/${integration_id}`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        window.location.reload();
      });
  };

  const onApiKeyChange = (bot_id) => {
    console.log("Setting api key", bot_id);
    setCurrentWebhook({
      ...currentWebhook,
      api_key: bot_id,
    });
  };

  const handleBrandChange = (index) => {
    if (index === -1) {
      setCurrentWebhook({
        ...currentWebhook,
        brand_id: null,
        brand_name: null,
      });
    } else {
      setCurrentWebhook({
        ...currentWebhook,
        brand_id: brands[index].id,
        brand_name: brands[index].name,
      });
    }
  };

  const handleCheckZendesk = async (integration_id) => {
    await axios
      .get(BASE_API_URL + `/integrations/${integration_id}/test`, {
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      });
  };

  const handleCreateWebhook = async () => {
    setWebhookShow(false);
    const data = {
      ...currentWebhook,
    };
    await axios
      .post(
        BASE_API_URL + `/integrations/integrations_zendesk/new_ticket_sync`,
        data,
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Webhook created successfully");
          window.location.reload();
        } else {
          toast.error("Error creating webhook");
        }
      });
  };

  const handleTestWebhook = async (webhook_id) => {
    await axios
      .get(
        BASE_API_URL +
          `/integrations/integrations_zendesk/webhook_test/${webhook_id}`,
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          toast.success("Webhook test successful");
        } else {
          toast.error("Webhook test failed");
        }
      });
  };

  const handledeleteWebhook = async (webhook_id) => {
    await axios
      .delete(
        BASE_API_URL +
          `/integrations/integrations_zendesk/webhook/${webhook_id}`,
        {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Webhook deleted successfully");
          webhooks.splice(
            webhooks.findIndex((webhook) => webhook.webhook_id === webhook_id),
            1
          );
          setWebhooks([...webhooks]);
        } else {
          toast.error("Error deleting webhook");
        }
      });
  };

  const [webhookShow, setWebhookShow] = useState(false);
  const [confluenceShow, setConfluenceShow] = useState(false);

  const handleConfluenceShow = () => setConfluenceShow(true);
  const handleConfluenceClose = () => setConfluenceShow(false);

  const handleNewWebhook = async () => {
    // check if integrations_zendesk is enabled
    const zd_integration = integrations.find(
      (integration) => integration.type === "integrations_zendesk"
    );
    if (!zd_integration || !zd_integration.enabled) {
      toast.error(
        "Please enable Zendesk integration before creating a webhook."
      );
      return;
    }
    setLoading(true);
    await axios
      .get(BASE_API_URL + `/integrations/integrations_zendesk/brands`, {
        headers: {
          authorization: `Bearer ${Userfront.tokens.accessToken}`,
        },
      })
      .then((response) => {
        // console.log(response);
        const temp_brands = response.data;
        // console.log("brands:", temp_brands);
        if (temp_brands.error) {
          toast.error("An error occurred while fetching brands.");
          setBrands([]);
          setLoading(false);
        } else {
          console.log(temp_brands);
          setBrands(temp_brands.brands);
          setLoading(false);
        }
      });
    console.log("New Webhook modal");
    setWebhookShow(true);
  };

  const handleNotionShow = () => {
    console.log("Notion modal");
    window.location.href = `https://api.notion.com/v1/oauth/authorize?client_id=${NOTION_CLIENT_ID}&response_type=code&owner=user&redirect_uri=https%3A%2F%2F${WEBHOOK_URL}%2Fintegrations%2Fintegrations_notion%2Ffinish_install&state=${Userfront.user.userUuid}`;
  };

  return (
    <div className="knowledge-base">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <div>
          <div className="title-row">
            <h2>Integrations</h2>
          </div>
          <div className="knowledge-bases-content">
            <Table hover striped bordered size="lg" className="base-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {integrations.map((integration, index) =>
                  // Add a switch case to handle different type of integration
                  (() => {
                    switch (integration.type) {
                      case "integrations_zendesk":
                        return (
                          <tr className="base-row" key={index}>
                            <td>Zendesk</td>
                            <td>
                              {integration.enabled
                                ? "Connected"
                                : "Not Connected"}
                            </td>
                            <td>
                              <div className="integration-action-panel">
                                {integration.enabled ? null : (
                                  <Button
                                    className="primary-button"
                                    onClick={() => handleZenDeskShow()}
                                  >
                                    Configure
                                  </Button>
                                )}
                                {integration.enabled ? (
                                  <>
                                    <Button
                                      className="secondary-button"
                                      onClick={() =>
                                        handleCheckZendesk(integration["id"])
                                      }
                                    >
                                      Test
                                    </Button>
                                  </>
                                ) : null}
                                {integration.enabled ? (
                                  <Button
                                    className="secondary-button"
                                    onClick={() =>
                                      handleDisableIntegration(integration.id)
                                    }
                                  >
                                    Disable
                                  </Button>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        );
                      case "integrations_confluence":
                        return (
                          <tr className="base-row" key={index}>
                            <td>Confluence</td>
                            <td>
                              {integration.enabled
                                ? "Connected"
                                : "Not Connected"}
                            </td>
                            <td>
                              <div className="integration-action-panel">
                                {integration["enabled"] ? null : (
                                  <Button
                                    className="primary-button"
                                    onClick={() => handleConfluenceShow()}
                                  >
                                    Configure
                                  </Button>
                                )}
                                <IntegrationConfig
                                  integration={{
                                    id: integration["id"],
                                    type: integration["type"],
                                    enabled: integration["enabled"],
                                    showConfig: confluenceShow, // the state variable that controls whether the modal should be shown should come from integration object.
                                    handleClose: handleConfluenceClose,
                                  }}
                                  onConfigSubmit={onConfigSubmit}
                                />
                                {integration["enabled"] ? (
                                  <Button
                                    className="secondary-button"
                                    onClick={() =>
                                      handleDisableIntegration(integration.id)
                                    }
                                  >
                                    Disable
                                  </Button>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        );
                      case "integrations_notion":
                        return (
                          <tr className="base-row" key={index}>
                            <td>Notion</td>
                            <td>
                              {integration.enabled
                                ? "Connected"
                                : "Not Connected"}
                            </td>
                            <td>
                              <div className="integration-action-panel">
                                {false ? null : (
                                  <Button
                                    className="primary-button"
                                    onClick={() => handleNotionShow()}
                                  >
                                    Configure
                                  </Button>
                                )}
                                {integration["enabled"] ? (
                                  <Button
                                    className="secondary-button"
                                    onClick={() =>
                                      handleDisableIntegration(integration.id)
                                    }
                                  >
                                    Disable
                                  </Button>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        );

                      default:
                        return null;
                    }
                  })()
                )}
              </tbody>
            </Table>
          </div>
          {webhooks ? (
            <div>
              <div className="route-title-row">
                <h3>Webhooks</h3>
                <Button
                  className="primary-button"
                  onClick={() => handleNewWebhook()}
                >
                  <img src={AddIconLight} alt="Add Icon" />
                  <span>Create New Webhook</span>
                </Button>
              </div>
              {webhooks.length !== 0 ? (
                <Table hover striped bordered size="lg" className="base-table">
                  <thead>
                    <tr>
                      <th>Webhook Name</th>
                      <th>Brands</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {webhooks.map((webhook, index) => (
                      <tr className="base-row" key={index}>
                        <td>{webhook.name}</td>
                        <td>
                          {webhook.webhook_metadata.brand_name
                            ? webhook.webhook_metadata.brand_name
                            : "All Brands"}
                        </td>
                        <td>
                          <div className="integration-action-panel">
                            <Button
                              className="open-button"
                              onClick={() =>
                                handleTestWebhook(webhook.webhook_id)
                              }
                            >
                              Test
                            </Button>
                            <Button
                              className="open-button"
                              onClick={() =>
                                handledeleteWebhook(webhook.webhook_id)
                              }
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyState type="Webhook" onClick={handleNewWebhook} />
              )}
            </div>
          ) : null}
        </div>
      )}

      <Modal show={zenDeskShow} centered onHide={handleZenDeskClose}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Zendesk Integration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <div className="form-context">
                <Form.Label>Integration Status:</Form.Label>
                <InfoBadge info="Indicates whether the Zendesk integration is current active or not."></InfoBadge>
              </div>
              <Form.Control
                placeholder={
                  zendeskIntegration.id ? "Connected" : "Not Connected"
                }
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Set Zendesk API Token</Form.Label>
                <InfoBadge info="Provide your Zendesk API token to allow RightPage to create tickets directly within your Zendesk environment. We store and encrypt your API token."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                value={zenDeskField}
                onChange={handleTokenChange}
                placeholder={
                  zendeskIntegration.id
                    ? "Previously provided"
                    : "Enter API Token"
                }
                autoFocus
                isInvalid={tokenFieldError}
              />
              <Form.Control.Feedback type="invalid">
                {tokenFieldError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>Set Zendesk Default Email</Form.Label>
                <InfoBadge info="Required to properly authenticate API requests. You can enter any email address that is associated with an active Zendesk user in your account."></InfoBadge>
              </div>
              <Form.Control
                type="input"
                value={zenDeskEmail}
                onChange={(e) => handleEmailChange(e)}
                placeholder={
                  zendeskIntegration.id
                    ? "Previously provided"
                    : "Enter email address"
                }
                isInvalid={emailFieldError}
              />
              <Form.Control.Feedback type="invalid">
                {emailFieldError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="form-context">
                <Form.Label>
                  Zendesk subdomain (do not include ".zendesk.com")
                </Form.Label>
                <InfoBadge info="Enter the Zendesk subdomain associated with your instance (e.g., subdomain.zendesk.com)"></InfoBadge>
              </div>
              <Form.Control
                type="input"
                value={zendeskSubdomain}
                onChange={(e) => handleSubDomainChange(e)}
                placeholder={
                  zendeskIntegration.id
                    ? "Previously provided"
                    : "Enter Zendesk subdomain"
                }
                isInvalid={subDomainInputError}
              />
              <Form.Control.Feedback type="invalid">
                {subDomainInputError}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleZenDeskClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {integrations ? (
        <Modal show={webhookShow} centered onHide={() => setWebhookShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Configure Webhook</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleCreateWebhook}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <div className="form-context">
                  <Form.Label>Default Assistant</Form.Label>
                  <InfoBadge info="Provide the default agent that should be used to prepare responses. Typically this is the agent that has access to the most information about your products and services."></InfoBadge>
                </div>
                <Form.Select
                  size="md"
                  label="Default Assistant"
                  name="apikey"
                  onChange={(e) => onApiKeyChange(e.target.value)}
                >
                  {bots.map((bot, index) => {
                    return (
                      <option key={index} value={bot.bot_id}>
                        {bot.agent_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <div className="form-context">
                  <Form.Label>Webhook Brand</Form.Label>
                  <InfoBadge info="Specify the brand that you want this webhook to apply to. Select 'All' to have webhook apply to all brands."></InfoBadge>
                </div>
                <Form.Select
                  size="md"
                  label="Default Assistant"
                  name="apikey"
                  onChange={(e) => handleBrandChange(e.target.value)}
                >
                  <option value={-1} key={-1}>
                    All Brands
                  </option>
                  {brands.map((brand, index) => {
                    return (
                      <option key={index} value={index}>
                        {brand.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => setWebhookShow(false)}
            >
              Close
            </Button>
            <Button className="primary-button" onClick={handleCreateWebhook}>
              Create Webhook
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <Toaster />
    </div>
  );
}
