import React from "react";
import Table from "react-bootstrap/Table";
import DocCard from "./DocCard/DocCard";

import "./DocumentRow.scss";

export default function DocumentRow({ documents }) {
  return (
    <div className="collection-row">
      {documents.length === 0 ? (
        <p className="no-docs">
          This knowledge base does not have any documents yet.
        </p>
      ) : (
        <Table responsive="sm" className="base-table">
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Document Type</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {documents.map((doc, index) => (
              <DocCard doc={doc} key={index} />
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}
