import React, { useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import InfoBadge from "../../InfoBadge/InfoBadge";
import { Button, Col, Row, Table } from "react-bootstrap";

import "./BehaviorSettings.scss";

function BehaviorSettings({ bot, handleUpdate }) {
  const [newTerm, setNewTerm] = useState("");
  const [newExplanation, setNewExplanation] = useState("");

  const [errors, setErrors] = useState({
    tone: false,
    description: false,
    rules: false,
  });

  const validate = (name, value) => {
    let errorMessage = "";

    if (name === "tone") {
      if (value.length > 200) {
        errorMessage = "Tone description must be less than 200 characters.";
      }
    }

    if (name === "description") {
      if (value.length > 500) {
        errorMessage = "Description must be less than 500 characters.";
      }
    }

    if (name === "rules") {
      if (value.length > 500) {
        errorMessage = "Rules must be less than 500 characters.";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    return errorMessage === "";
  };

  const handleChange = (name, value) => {
    if (validate(name, value)) {
      handleUpdate(name, value);
    }
  };

  const handleNewTermChange = (e) => {
    setNewTerm(e.target.value);
  };

  const handleNewExplanationChange = (e) => {
    setNewExplanation(e.target.value);
  };

  const handleAddTerm = () => {
    const updatedDictionary = bot.glossary;
    updatedDictionary[newTerm] = newExplanation;

    handleUpdate("glossary", updatedDictionary);
    setNewTerm("");
    setNewExplanation("");
  };

  // Remove a term from the dictionary
  const handleDeleteTerm = (term) => {
    // Create a copy of the glossary
    const glossaryCopy = { ...bot.glossary };

    // Delete the term
    delete glossaryCopy[term];

    // Update the state
    handleUpdate("glossary", glossaryCopy);
  };

  return (
    <Form>
      <div className="behavior-form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">Bot Guidelines</Form.Label>
          <Form.Text className="section-explantion">
            Bots can understand and follow a wide variety of instructions.
            However, it is important to provide clear and specific guidelines
            for your bot to follow. This will ensure that your bot is able to
            perform the tasks you want it to.
          </Form.Text>
        </div>
        <Form.Group
          controlId="objective_description"
          className="full-width-input"
        >
          <div className="form-context">
            <Form.Label className="form-section-title">
              Objective Description:
            </Form.Label>
            <InfoBadge info="Describe the role and responsibilities you want your agent to perform. We recommend being specific in describing the objective."></InfoBadge>
          </div>
          <FormControl
            as="textarea"
            className="md-textarea"
            value={bot.objective_description}
            onChange={(e) =>
              handleChange("objective_description", e.target.value)
            }
            isInvalid={!!errors.objective_description}
          />
          <Form.Control.Feedback type="invalid">
            Please leave blank or enter a description for this agent that is
            less than 500 characters.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="tone" className="full-width-input">
          <div className="form-context">
            <Form.Label className="form-section-title">Tone:</Form.Label>
            <InfoBadge info="Describe the overall tone that you would like the agent to respond with"></InfoBadge>
          </div>
          <FormControl
            as="textarea"
            value={bot.tone}
            onChange={(e) => handleChange("tone", e.target.value)}
            isInvalid={!!errors.tone}
          />
          <Form.Control.Feedback type="invalid">
            Please leave blank or enter describe the tone you would like this
            agent to adopt in less than 200 characters.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="rules">
          <div className="form-context">
            <Form.Label className="form-section-title">Rules:</Form.Label>
            <InfoBadge info="Describe in detail the behaviors you do and do not want your agent to exhibit." />
          </div>
          <FormControl
            as="textarea"
            value={bot.rules}
            className="md-textarea"
            onChange={(e) => handleChange("rules", e.target.value)}
            isInvalid={!!errors.rules}
          />
          <Form.Control.Feedback type="invalid">
            Please leave blank or enter rules for this agent that are less than
            500 characters.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="form-section">
        <div className="title-div">
          <Form.Label className="form-section-title">
            Terms Dictionary
          </Form.Label>
          <Form.Text className="section-explantion">
            Add terms and phrases to the dictionary that you want to always be
            formatted/used in a certain manner.
          </Form.Text>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Term to match</th>
              <th>Replacement term</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(bot.glossary).map((term) => (
              <tr key={term}>
                <td>{term}</td>
                <td>{bot.glossary[term]}</td>
                <td>
                  <Button
                    className="open-button"
                    onClick={() => handleDeleteTerm(term)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row className="align-items-center">
          <Col md={5}>
            <Form.Group className="vert-terms">
              <Form.Label>Term to match</Form.Label>
              <Form.Control
                type="text"
                value={newTerm}
                onChange={handleNewTermChange}
              />
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group className="vert-terms">
              <Form.Label>Replacement term</Form.Label>
              <Form.Control
                type="text"
                value={newExplanation}
                onChange={handleNewExplanationChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Button onClick={handleAddTerm} className="secondary-button">
              Add Term
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default BehaviorSettings;
