import axios from "axios";
import Button from "react-bootstrap/Button";
// import Column from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Userfront from "@userfront/react";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import toast, { Toaster } from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import React from "react";
import formatTimestamp from "../../services/TimeService";
import PageHandler from "../../components/PageHandler/PageHandler";

import "./Conversations.scss";

export default function Conversations() {
  const { agent_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [agents, setAgents] = useState([]);
  const [conversations, setConversations] = useState(undefined);
  const [currentAgent, setCurrentAgent] = useState("");
  // const [currentConversationIndex, setCurrentConversationIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingConvo, setLoadingConvo] = useState(true);
  const [show, setShow] = useState(false);
  const [activeConvo, setActiveConvo] = useState({});

  // handle pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);

  const handleAgentChange = (agent) => {
    setLoading(true);
    setCurrentAgent(agent);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data.message) {
            toast.error(response.data.message);
            setLoading(false);
          } else {
            const currAgent = response.data.find((i) => i.bot_id === agent_id);
            const filteredAgents = response.data.filter(
              (agent) => agent.objective === "Support Guide (External)"
            );
            setAgents(filteredAgents);

            if (currAgent) {
              handleAgentChange(currAgent);
            } else {
              setLoading(false);
            }
          }
        });
    })();
  }, [BASE_API_URL, agent_id]);

  useEffect(() => {
    setLoading(true);
    setConversations([]);
    if (currentAgent.bot_id !== "") {
      (async () => {
        await axios
          .get(
            BASE_API_URL +
              `/sessions/sessions/${currentAgent.bot_id}/pages?page_size=${perPage}`,
            {
              headers: {
                authorization: `Bearer ${Userfront.tokens.accessToken}`,
              },
            }
          )
          .then((response) => {
            setTotalPages(response.data.number_of_pages);
          });
      })();
      (async () => {
        await axios
          .get(
            BASE_API_URL +
              `/sessions/sessions/${currentAgent.bot_id}?page_number=${page}&page_size=${perPage}`,
            {
              headers: {
                authorization: `Bearer ${Userfront.tokens.accessToken}`,
              },
            }
          )
          .then((response) => {
            // console.log(response);
            // const sorted_data = response.data.conversations.sort((a, b) =>
            //   a.name.localeCompare(b.name)
            // );
            // console.log(sorted_data);
            setConversations(response.data);
            setLoading(false);
          });
      })();
      if (conversations !== []) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgent, page, perPage, BASE_API_URL]);

  const handleViewClick = async (index) => {
    // setCurrentConversationIndex(index);
    setLoadingConvo(true);
    setShow(true);
    // console.log(conversations.sessions[index].session_id);
    await axios
      .get(
        BASE_API_URL +
          `/sessions/conversation/${conversations.sessions[index].session_id}`,
        {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        if (response.data.message) {
          toast.error(response.data.message);
          setLoadingConvo(false);
          setShow(false);
        } else {
          setActiveConvo(response.data.conversation);
        }
        setLoadingConvo(false);
        // console.log(response);
      });
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handlePerPageChange = (selectedPerPage) => {
    setPerPage(selectedPerPage);
    setPage(1); // Reset to the first page when perPage changes
  };

  return (
    <div className="conversation-history">
      <h2>Web Widget Interations</h2>
      <h5 className="conversation-select-title">
        Select a web widget bot's interactions to view:
      </h5>
      <Dropdown className="convos-select current-convo-select-menu">
        <Dropdown.Toggle className="select current-convo-select">
          <span>{currentAgent ? currentAgent.agent_name : ""}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="select-menu ">
          {agents &&
            agents.map((item) => (
              <Dropdown.Item key={item.bot_id} className="select-item" as="div">
                <NavLink
                  onClick={() => handleAgentChange(item)}
                  to={`/conversations/${item.bot_id}`}
                >
                  {item.agent_name}
                </NavLink>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      {loading ? (
        <div className="Centered-Form">
          <Spinner animation="border" className="loading-spinner" />
        </div>
      ) : (
        <>
          <Container>
            {conversations.sessions ? (
              <div>
                <Table
                  hover
                  striped
                  bordered
                  size="lg"
                  className="conversation-table"
                >
                  <thead>
                    <tr className="table-header">
                      <th className="column-md-1">Date</th>
                      <th className="column-md-1">Time</th>
                      <th className="column-md-1">Category</th>
                      <th className="column-md-1">Topic</th>
                      <th className="column-md-1">Sentiment</th>
                      <th className="column-md-1">Feedback</th>
                      <th className="column-md-1">Ticket Created</th>
                      {/* <th className="column-md-1">Start Page</th> */}
                      <th className="column-md-1">Click Count</th>
                      <th className="column-md-1">Message Count</th>
                      <th className="column-md-1">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conversations.sessions.map((conversation, index) => (
                      <tr key={index} className="base-row">
                        <td className="column-md-1">
                          {
                            formatTimestamp(conversation.created_at).split(
                              " "
                            )[0]
                          }
                        </td>
                        <td className="column-md-1">
                          {formatTimestamp(conversation.created_at).split(
                            " "
                          )[1] +
                            " " +
                            formatTimestamp(conversation.created_at).split(
                              " "
                            )[2]}
                        </td>
                        <td className="column-md-1">
                          {conversation.category || "Pending"}
                        </td>
                        <td className="column-md-2">
                          {conversation.topic || "Pending"}
                        </td>
                        <td className="column-md-1">
                          {conversation.sentiment}
                        </td>
                        <td className="column-md-1">{conversation.feedback}</td>
                        <td className="column-md-1">
                          {conversation.ticket_created.charAt(0).toUpperCase() +
                            conversation.ticket_created.slice(1)}
                        </td>
                        {/* <td className="column-md-1">
                          {conversation.page ? conversation.page : "N/A"}
                        </td> */}
                        <td className="column-md-1">
                          {conversation.click_count}
                        </td>
                        <td className="column-md-1">
                          {conversation.message_count}
                        </td>
                        <td className="column-md-1">
                          {conversation.message_count > 0 ? (
                            <Button
                              className="open-button"
                              onClick={() => handleViewClick(index)}
                            >
                              View
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {currentAgent ? (
                  <PageHandler
                    perPage={perPage}
                    handlePerPageChange={handlePerPageChange}
                    page={page}
                    handlePageChange={handlePageChange}
                    totalPages={totalPages}
                  />
                ) : null}
              </div>
            ) : (
              // <p className="no-conversations">
              //   No conversations have been recorded for the selected agent yet.
              // </p>
              <div className="Centered-Form">
                <Spinner animation="border" className="loading-spinner" />
              </div>
            )}
          </Container>
          <Offcanvas
            show={show}
            onHide={handleClose}
            style={{ width: "550px", maxWidth: "90%" }}
            placement="end"
          >
            <Offcanvas.Header className="conversation-overlay" closeButton>
              <h3>Interaction Messages</h3>
            </Offcanvas.Header>
            <Offcanvas.Body className="conversation-overlay-body">
              {!loadingConvo && activeConvo.user_messages ? (
                <>
                  {activeConvo.user_messages.map((message, index) => {
                    return (
                      <React.Fragment key={`message-${index}`}>
                        <div className="context-info">
                          {activeConvo.pages[index] ? (
                            <span className="context-message">
                              Page: {activeConvo.pages[index]}
                            </span>
                          ) : null}
                          {activeConvo.created_ats[index] ? (
                            <span className="context-message">
                              Time:{" "}
                              {formatTimestamp(activeConvo.created_ats[index])}
                            </span>
                          ) : null}
                        </div>
                        <ReactMarkdown
                          children={message}
                          rehypePlugins={[rehypeHighlight]}
                          key={index}
                          className="user-message"
                          components={{
                            // @ts-ignore
                            a: ({ node }) => node.children[0].value,
                          }}
                        />
                        <div className="assistant-message">
                          <ReactMarkdown
                            children={activeConvo.assistant_messages[index]}
                            rehypePlugins={[rehypeHighlight]}
                            key={index + 1 * -1}
                            components={{
                              // @ts-ignore
                              a: ({ node }) => node.children[0].value,
                            }}
                          />
                          <div className="sources">
                            Explore Further:
                            {activeConvo.sources[index]
                              ? activeConvo.sources[index].map(
                                  (source, index) => (
                                    <a
                                      href={
                                        source.link === "" ? "#" : source.link
                                      }
                                      rel="noreferrer"
                                      key={index}
                                      className="source-button"
                                    >
                                      <span key={index}>
                                        {source.title
                                          ? source.title
                                          : source.doc_title}
                                      </span>
                                    </a>
                                  )
                                )
                              : null}
                          </div>
                        </div>
                        <div className="reaction-info">
                          {activeConvo.feedbacks[index] ? (
                            <span>
                              {activeConvo.feedbacks[index]} feedback received
                            </span>
                          ) : null}
                          {activeConvo.scores[index] ? (
                            <span>
                              Answer Confidence Score:{" "}
                              {activeConvo.scores[index]}
                            </span>
                          ) : null}
                          {activeConvo.clicks.map((click, click_index) => {
                            if (click[0] === index) {
                              return (
                                <span>
                                  Click: <a href={click[1]}>{click[1]}</a>
                                </span>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                        {/* <p className="user-message" key={index}>
                                {message}
                              </p> */}
                        {/* <p
                                className="assistant-message"
                                key={index + 1 * -1}
                              >
                                {
                                  conversations.conversations[
                                    currentConversationIndex
                                  ].assistant_messages[index]
                                }
                              </p> */}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <div className="Centered-Form">
                  <Spinner animation="border" className="loading-spinner" />
                </div>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
      <Toaster />
    </div>
  );
}
