import axios from "axios";
import { Dropdown, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Userfront from "@userfront/react";
import { Spinner, Container, Row, Col, Card } from "react-bootstrap";
import { Pie, Bar, Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  Title,
  BarElement,
  LineElement,
  LineController,
} from "chart.js";

import "./Insights.scss";

export default function Insights() {
  const { agent_id } = useParams();
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  const [agents, setAgents] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Fetch Insights");
  const [data, setData] = useState(null);
  const [sessionCount, setSessionCount] = useState(null);

  let currentDate = new Date();
  let startingDate = new Date();
  startingDate.setDate(currentDate.getDate() - 7);
  const [startDate, setStartDate] = useState(
    startingDate.toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(
    currentDate.toISOString().slice(0, 10)
  );

  const handleAgentChange = (agent) => {
    setCurrentAgent(agent);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(BASE_API_URL + `/bots/`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          const currAgent = response.data.find((i) => i.bot_id === agent_id);
          const filteredAgents = response.data.filter(
            (agent) => agent.objective === "Support Guide (External)"
          );
          setAgents(filteredAgents);

          if (currAgent) {
            handleAgentChange(currAgent);
          }
        });
    })();
    if (agent_id !== undefined) {
      // setLoading(true);
      getInsights();
      // setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const [messageCount, setMessageCount] = useState(null);
  const [categoryCounts, setCategoryCounts] = useState(null);
  const [feedbackCounts, setFeedbackCounts] = useState(null);
  const [activityOverTime, setActivityOverTime] = useState(null);
  const [allSessions, setAllSessions] = useState(null);
  const [sentimentCounts, setSentimentCounts] = useState(null);
  const [mostCommonWords, setMostCommonWords] = useState(null);
  const [conversationCount, setConversationCount] = useState(null);

  const getInsights = async () => {
    // setLoading(true);
    const startTime = Math.floor(new Date(startDate).getTime() / 1000);
    const endTime = Math.floor(new Date(endDate).getTime() / 1000);

    if (agent_id !== "") {
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/session_count`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setSessionCount(response.data.insights.session_count);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/message_count`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setMessageCount(response.data.insights.message_count);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/conversation_count`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setConversationCount(response.data.insights.conversation_count);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/category_counts`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setCategoryCounts(response.data.insights.category_counts);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/feedback_counts`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setFeedbackCounts(response.data.insights.feedback_counts);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/sentiment_counts`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setSentimentCounts(response.data.insights.sentiment_counts);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/activity_over_time`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setActivityOverTime(response.data.insights.activity_over_time);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/most_common_words`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setMostCommonWords(response.data.insights.most_common_words);
          });
      })();
      (async () => {
        await axios
          .get(BASE_API_URL + `/insights/${agent_id}/sessions`, {
            headers: {
              authorization: `Bearer ${Userfront.tokens.accessToken}`,
            },
            params: {
              start_time: startTime,
              end_time: endTime,
            },
          })
          .then((response) => {
            setAllSessions(response.data.insights.sessions);
          });
      })();
      setData(1);
      setLoading(false);
      setText("Fetch Insights");
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setText("Loading...");
    // setLoading(true);
    getInsights();
  };

  return (
    <div className="conversation-history">
      <div className="insight-title-row">
        <h2>Web Widget Bot Insights</h2>
        {allSessions && allSessions.length > 0 ? (
          <DownloadCSV sessions={allSessions} />
        ) : (
          <></>
        )}
      </div>
      <div className="">
        <Form className="insights-filter" onSubmit={handleSubmit}>
          <Form.Group controlId="agent" className="agent-select">
            <Form.Label>Select Agent</Form.Label>
            <Dropdown className="">
              <Dropdown.Toggle className="select">
                <span>{currentAgent ? currentAgent.agent_name : ""}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="select-menu ">
                {agents &&
                  agents.map((item) => (
                    <Dropdown.Item key={item.bot_id} className="select-item">
                      <NavLink
                        onClick={() => handleAgentChange(item)}
                        to={`/insights/${item.bot_id}`}
                      >
                        {item.agent_name}
                      </NavLink>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group controlId="startDate" className="date-select">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </Form.Group>

          <Form.Group controlId="endDate" className="date-select">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </Form.Group>
          <button type="submit" className="primary-button">
            {text}
          </button>
        </Form>
      </div>
      {!loading ? (
        <>
          {data ? (
            <Container className="mt-3">
              <Row className="mt-3">
                <Col md={3}>
                  <Card className="text-center data-panel">
                    <Card.Body className="panel-body">
                      <Card.Title className="panel-title">
                        {sessionCount ? sessionCount : "Not Enough Data"}
                      </Card.Title>
                      <Card.Text>Total Page Loads (Sessions)</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="text-center data-panel">
                    <Card.Body className="panel-body">
                      <Card.Title className="panel-title">
                        {messageCount ? messageCount : "Not Enough Data"}
                      </Card.Title>
                      <Card.Text>Total Unique Conversations</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="text-center data-panel">
                    <Card.Body className="panel-body">
                      <Card.Title className="panel-title">
                        {conversationCount > 0
                          ? (
                              parseInt(messageCount) /
                              parseInt(conversationCount)
                            ).toFixed(2)
                          : "Not Enough Data"}
                      </Card.Title>
                      <Card.Text>Messages per Conversation</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="text-center data-panel">
                    <Card.Body className="panel-body">
                      <Card.Title className="panel-title">
                        {conversationCount > 0 &&
                        feedbackCounts &&
                        feedbackCounts.Negative > 0
                          ? (
                              parseInt(feedbackCounts.Negative) /
                              parseInt(conversationCount)
                            ).toFixed(2) + "%"
                          : "Not Enough Data"}
                      </Card.Title>
                      <Card.Text>
                        Negative Feedback <br />
                        Rate
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} md={12} lg={12} className="mb-4">
                  <div className="p-3 border data-panel activity-over-time-panel">
                    <h5>Activity Over Time</h5>
                    {activityOverTime &&
                    Object.keys(activityOverTime).length > 0 ? (
                      <LineChart activity_over_time={activityOverTime} />
                    ) : (
                      <p className="no-data-message">Not Enough Data</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <div className="p-3 border data-panel">
                    <h5>Conversation Sentiment Breakdown</h5>
                    {sentimentCounts &&
                    Object.keys(sentimentCounts).length > 0 &&
                    Object.values(sentimentCounts).every(
                      (value) => value !== 0
                    ) ? (
                      <PieChart data={sentimentCounts} />
                    ) : (
                      <p className="no-data-message">Not Enough Data</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <div className="p-3 border data-panel">
                    <h5>Category Breakdown</h5>
                    {categoryCounts &&
                    Object.keys(categoryCounts).length > 0 ? (
                      <PieChart data={categoryCounts} />
                    ) : (
                      <p className="no-data-message">Not Enough Data</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4} className="mb-4">
                  <div className="p-3 border data-panel">
                    <h5>Feedback Breakdown</h5>
                    {feedbackCounts &&
                    Object.keys(feedbackCounts).length > 0 &&
                    Object.values(feedbackCounts).every(
                      (value) => value !== 0
                    ) ? (
                      <PieChart data={feedbackCounts} />
                    ) : (
                      <p className="no-data-message">Not Enough Data</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} className="mb-4">
                  <div className="p-3 border data-panel">
                    <h5>Most Frequent Topics</h5>
                    {mostCommonWords && mostCommonWords.length > 0 ? (
                      <HorizontalBarChart
                        data={mostCommonWords}
                        label="Word Count"
                      />
                    ) : (
                      <p className="no-data-message">Not Enough Data</p>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="Centered-Form">
          <Spinner animation="border" className="loading-spinner" />
        </div>
      )}
    </div>
  );
}

Chart.register(ArcElement, CategoryScale, Tooltip, Legend);

const PieChart = ({ data }) => {
  // console.log("data", data);
  const chartRef = useRef(null);

  const colors = [
    "rgba(255, 99, 132, 0.5)", // Color for first category
    "rgba(54, 162, 235, 0.5)", // Color for second category
    "rgba(255, 206, 86, 0.5)", // Color for third category
    "rgba(75, 192, 192, 0.5)", // Color for fourth category
  ];
  // Map the colors to your categories
  const backgroundColors = Object.keys(data).map(
    (_, i) => colors[i % colors.length]
  );
  const borderColors = backgroundColors.map((color) =>
    color.replace("0.5", "1")
  );

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    width: 200,
    height: 200,
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: "300px" }}>
      <Pie data={chartData} ref={chartRef} options={options} />
    </div>
  );
};

Chart.register(LinearScale, BarElement, Title);

const HorizontalBarChart = ({ data, label }) => {
  const chartRef2 = useRef(null);

  const chartData = {
    labels: data.map((item) => item[0]),
    datasets: [
      {
        data: data.map((item) => item[1]),
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        label: label,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: true,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  return <Bar data={chartData} options={options} ref={chartRef2} />;
};

Chart.register(PointElement);

Chart.register(LineElement, LineController);

const LineChart = ({ activity_over_time }) => {
  // console.log("activity_over_time", activity_over_time);
  // Extract labels (dates) from data
  const labels = Object.keys(activity_over_time);

  // Extract datasets from data
  const sessionsData = labels.map(
    (label) => activity_over_time[label].sessions
  );
  const messagesData = labels.map(
    (label) => activity_over_time[label].messages
  );
  const conversationsData = labels.map(
    (label) => activity_over_time[label].conversations
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Sessions",
        data: sessionsData,
        borderColor: "rgba(75,192,192,1)",
        fill: false,
      },
      {
        label: "Messages",
        data: messagesData,
        borderColor: "rgba(192,75,75,1)",
        fill: false,
      },
      {
        label: "Conversations",
        data: conversationsData,
        borderColor: "rgba(75,75,192,1)",
        fill: false,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Activity Over Time",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

const DownloadCSV = ({ sessions }) => {
  const [text, setText] = useState("Download CSV");
  const downloadCSV = () => {
    setText("Downloading...");
    const headers = Object.keys(sessions[0]);

    const csvContent = [
      headers,
      ...sessions.map((session) =>
        headers.map((header) => {
          let value = session[header];

          if (value === null || value === undefined) {
            value = "None"; // Or 'Undefined' or ''
          } else if (typeof value === "string" && Date.parse(value)) {
            value = new Date(value).toISOString();
          }

          return value;
        })
      ),
    ]
      .map((e) => e.join(","))
      .join("\n");

    // Create a blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link to download the blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "rightpageagentdata.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    document.body.removeChild(link);
    setText("Download CSV");
  };

  return (
    <div>
      <button onClick={downloadCSV} className="secondary-button">
        {text}
      </button>
    </div>
  );
};
