import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InfoBadge from "../../../components/InfoBadge/InfoBadge";
import axios from "axios";
import toast from "react-hot-toast";
import Userfront from "@userfront/react";

export const CreateAgentModal = ({ show, onHide, objective }) => {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const baseNameField = useRef();
  const [nameField, setNameField] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const inputChange = (e) => {
    setNameField(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (nameField === "") {
      setFormErrors({ text: "Please name your agent" });
    } else {
      const data = { base_name: nameField, objective: objective };

      await axios
        .post(BASE_API_URL + `/bots/`, data, {
          "Content-Type": "application/json",
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          if (response.data.status === false) {
            toast.error(response.data.message);
            onHide();
            setNameField("");
          } else {
            onHide();
            setNameField("");
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error("Error creating agent");
          console.error(error);
        });
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="form-context">
              <Form.Label>Agent Name</Form.Label>
              <InfoBadge info="Give your agent a name that will identify it clearly." />
            </div>
            <Form.Control
              type="input"
              ref={baseNameField}
              onChange={inputChange}
              placeholder="Agent name"
              autoFocus
              error={formErrors.text}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="secondary-button" onClick={onHide}>
          Close
        </Button>
        <Button className="primary-button" onClick={onSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
