import axios from "axios";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Userfront from "@userfront/react";
import { useLocation, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast, { Toaster } from "react-hot-toast";

import InfoBadge from "../../components/InfoBadge/InfoBadge";

import "./Tickets.scss";

export default function NewCrawl() {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const [activeBase, setActiveBase] = useState();
  const [bases, setBases] = useState();
  const [loading, setLoading] = useState(true);
  // const [ticketIntegrationMethod, setTicketIntegrationMethod] =
  //   useState("none");
  const [ticketIntegrationMethods, setTicketIntegrationMethods] = useState([]);
  const [statusFilter, setStatusFilter] = useState("solved");
  const [ticketSyncInterval, setTicketSyncInterval] = useState("daily");
  let currentDate = new Date();
  let startingDate = new Date();
  startingDate.setDate(currentDate.getDate() - 60);
  const [startDate, setStartDate] = useState(
    startingDate.toISOString().slice(0, 10)
  );
  const [inactive, setInactive] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState();
  useEffect(() => {
    (async () => {
      const { base_id } = location.state;
      // console.log("base id:", base_id);
      await axios
        .get(BASE_API_URL + `/bases`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          const temp_bases = response.data;
          temp_bases.forEach((base, i) => {
            if (base.base_id === base_id) {
              temp_bases.splice(i, 1);
              temp_bases.unshift(base);
            }
          });
          setBases(temp_bases);
          setActiveBase(temp_bases[0].base_id);
          // console.log(response);
          setLoading(false);
        });
      await axios
        .get(BASE_API_URL + `/integrations/`, {
          headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        })
        .then((response) => {
          // console.log(response.data);
          const zendesk_integration = response.data.find(
            (item) => item.type === "integrations_zendesk"
          );
          setTicketIntegrationMethods([zendesk_integration]);
          if (zendesk_integration.enabled === false) {
            toast.error("Zendesk integration is not enabled.");
            setLoading(false);
            setInactive(true);
            return;
            // Break out of the function
          } else {
            // setTicketIntegrationMethod(
            //   response.data[0].type.split("_")[1]
            //     ? response.data[0].type.split("_")[1]
            //     : "none"
            // );
          }
        });
      await axios
        .get(BASE_API_URL + `/integrations/integrations_zendesk/brands`, {
          headers: {
            authorization: `Bearer ${Userfront.tokens.accessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          const temp_brands = response.data;
          // console.log("brands:", temp_brands);
          if (temp_brands.error) {
            toast.error("An error occurred while fetching brands.");
            toast.error(temp_brands.error);
            setBrands([]);
            setBrand({ brand: "none" });
            setLoading(false);
          } else {
            console.log(temp_brands);
            setBrands(temp_brands.brands);
            setBrand(temp_brands.brands[0]);
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const syncTickets = async () => {
    setLoading(true);
    let unixTimeInMinutes = Math.floor(new Date(startDate).getTime() / 1000);
    let body = {
      integration_id: ticketIntegrationMethods[0].id,
      ticket_status: statusFilter === "solved" ? "closed" : statusFilter,
      knowledge_base_id: activeBase,
      start_time: unixTimeInMinutes,
      brand: brand.subdomain ? brand.subdomain : null,
      brand_id: brand.id ? brand.id : null,
    };
    await axios({
      method: "post",
      url: BASE_API_URL + "/tickets_scrape/",
      headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
      data: body,
    })
      .then((response) => {
        toast.success("Ticket Sync started successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error occurred while starting the ticket sync.");
      });
    var refresh;
    switch (ticketSyncInterval) {
      case "daily":
        refresh = 1440;
        break;
      case "weekly":
        refresh = 10080;
        break;
      case "monthly":
        refresh = 43200;
        break;
      default:
        refresh = 0;
    }
    if (refresh !== 0) {
      body = {
        enabled: true,
        frequency_minutes: refresh,
        schedule_metadata: {
          integration_id: ticketIntegrationMethods[0].id,
          base_id: activeBase,
          type: "zendesk_ticket_crawl",
          status: statusFilter === "solved" ? "closed" : statusFilter,
          brand: brand.subdomain ? brand.subdomain : null,
          brand_id: brand.id ? brand.id : null,
        },
      };
      await axios({
        method: "post",
        url: BASE_API_URL + "/scheduling/",
        headers: { authorization: `Bearer ${Userfront.tokens.accessToken}` },
        data: body,
      })
        .then((response) => {
          toast.success("Ticket Sync schedule initiated successfully!");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error(
            "An error occurred while scheduling future ticket syncs."
          );
        });
    }
  };
  const handleStartDateChange = (e) => {
    // const valueAs = e.target.valueAsNumber;
    // const finalValue = valueAs.toISOString().slice(0, 10);
    // setStartDate(finalValue);
    setStartDate(e.target.value);
  };

  const handleBrandChange = (index) => {
    setBrand(brands[index]);
  };

  const useStatusRestrictionOptions = ["solved", "pending", "open"];
  const useTicketSyncInterval = ["daily", "weekly", "monthly", "never"];

  return (
    <div className="ticket-sync">
      {loading ? (
        <Spinner animation="border" className="loading-spinner" />
      ) : (
        <>
          <Breadcrumb className="">
            <Breadcrumb.Item key={0} href="/bases">
              All Knowledge Bases
            </Breadcrumb.Item>
            <Breadcrumb.Item key={1} href={`/bases/${activeBase}`}>
              {bases[0] && bases[0].base_name
                ? bases[0].base_name
                : "Knowledge Base"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active key={2}>
              Import Tickets
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>Import Tickets</h1>
          {inactive ? (
            <p className="inactive-text">
              Zendesk integration is not enabled.{" "}
              <Link to="/integrations">
                Use the integrations page to enable it.
              </Link>
            </p>
          ) : (
            <div>
              <div className="new-crawl-settings">
                <div className="crawl-setting-column">
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Brand:</Form.Label>
                      <InfoBadge info="Specify the brand (Zendesk subdomain) you'd like to import articles from."></InfoBadge>
                    </div>
                    <Form.Select
                      // value={brand ? brand.name : "none"}
                      onChange={(e) => {
                        handleBrandChange(e.target.value);
                      }}
                    >
                      {brands.map((brand, index) => {
                        return (
                          <option key={index} value={index}>
                            {brand.name}
                          </option>
                        );
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="startDate" className="date-select">
                    <Form.Label>Sync Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </Form.Group>
                </div>
                <div className="crawl-setting-column">
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Ticket Status To Sync:</Form.Label>
                      <InfoBadge info="Specify the ticket status that you would like to sync (e.g., solved)"></InfoBadge>
                    </div>
                    <Form.Select
                      value={statusFilter}
                      onChange={(e) => {
                        setStatusFilter(e.target.value);
                      }}
                    >
                      {useStatusRestrictionOptions.map((option, index) => {
                        return <option key={index}>{option}</option>;
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-context">
                      <Form.Label>Refresh Interval:</Form.Label>
                      <InfoBadge info="How frequently you would like to have recent tickets imported"></InfoBadge>
                    </div>
                    <Form.Select
                      value={ticketSyncInterval}
                      onChange={(e) => {
                        setTicketSyncInterval(e.target.value);
                      }}
                    >
                      {useTicketSyncInterval.map((option, index) => {
                        return <option key={index}>{option}</option>;
                      })}
                      ;
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <Button
                className="primary-button new-crawl-button"
                onClick={() => syncTickets()}
              >
                Save and Start Sync
              </Button>
            </div>
          )}
        </>
      )}
      <Toaster />
    </div>
  );
}
